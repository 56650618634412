import {
  Button,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  Input,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import { BsFacebook, BsInstagram, BsYoutube, BsLinkedin } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import axios from "axios";

function Footer() {
  // Link social media - Open new tab
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  // Open and close comming soon modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Mobile application pre registration input
  const [inputValues, setInputValues] = useState({
    email: "",
  });
  // handle change of pre registration modal inputs
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // handle loading animation
  const [preregistration_loading, set_preregistration_loading] =
    useState(false);
  // Toast notifications
  const toast = useToast();
  // Handle preregistration
  const handle_preregistration = async (e) => {
    e.preventDefault();
    set_preregistration_loading(true);
    try {
      // Check if inputs are empty
      if (inputValues.email === "") {
        toast({
          title: "Skriv fejl",
          description: "e-mail-felt er påkrævet",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        set_preregistration_loading(false);
      } else {
        const data = {
          email: inputValues.email,
        };
        const res = await axios.post("saloon/preregister", data);

        if (res.status === 200) {
          set_preregistration_loading(false);
          toast({
            title: "forhåndsregistrering",
            description: "Vores supportteam vil være i kontakt",
            position: "top-right",
            isClosable: true,
            status: "success",
          });
          onClose();
        }
      }
    } catch (err) {
      toast({
        title: "Server Fejl",
        description: "Prøv venligst igen om et øjeblik",
        position: "top-right",
        isClosable: true,
        status: "error",
      });

      set_preregistration_loading(false);
    }
  };

  return (
    <>
      <div className="Footer">
        <div className="Header">Download Bookinghero til iOS og Andriod</div>
        <div className="Buttons">
          <Button className="Btn" variant="outline" onClick={onOpen}>
            App Store
          </Button>
          <Button className="Btn" variant="outline" onClick={onOpen}>
            Google Play
          </Button>
        </div>
        <div className="Links">
          <div className="Logocontainer">
            <Link to="/">
              <img
                src={process.env.PUBLIC_URL + "/assets/Logo/logo_white.webp"}
                alt=""
                className="Logo"
              ></img>
            </Link>
          </div>
          <div className="Linkitems">
            <Link to="/booking-system/login">
              <div className="link">Login B2B</div>
            </Link>
            <Link to="/vilkar">
              <div className="link">Vilkår og Betingelser</div>
            </Link>
            <Link to="/Databehandling">
              <div className="link">Databehandling</div>
            </Link>
          </div>
          <div className="Socialmedia">
            <div>
              <Icon className="Icon" as={BsFacebook} />
            </div>
            <div>
              <Icon className="Icon" as={BsInstagram} />
            </div>
            <div>
              <Icon
                className="Icon"
                as={BsYoutube}
                onClick={() =>
                  openInNewTab("https://www.youtube.com/@Bestilbooking")
                }
              />
            </div>
            <div>
              <Icon
                className="Icon"
                as={BsLinkedin}
                onClick={() =>
                  openInNewTab(
                    "https://www.linkedin.com/company/bestilbooking-i-kbh-aps/"
                  )
                }
              />
            </div>
          </div>
        </div>
        <Modal isOpen={isOpen} onClose={onClose} size="lg">
          <ModalOverlay />
          <ModalContent bg="white" borderRadius="8px" boxShadow="lg">
            <ModalHeader
              textAlign="center"
              borderBottom="1px solid"
              borderColor="gray.200"
              pb="2"
            >
              Kommer snart
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody py="6" textAlign="center">
              <div
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  marginBottom: "4rem",
                }}
              >
                Vores app er på vej! Få en notifikation når den er klar til
                download i App Store eller Google Play
              </div>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={MdEmail} color="gray.300" />}
                />
                <Input
                  name="email"
                  variant="flushed"
                  placeholder="Email"
                  value={inputValues.email}
                  onChange={handleInputChange}
                />
              </InputGroup>
              {preregistration_loading ? (
                <>
                  <Button
                    style={{
                      width: "100%",
                      borderRadius: "12px",
                      color: "#23a994",
                      marginTop: "2rem",
                      fontWeight: "700",
                      fontSize: "1.3rem",
                    }}
                    isLoading
                    loadingText="Indsender"
                  >
                    Skriv mig op!
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    style={{
                      width: "100%",
                      borderRadius: "12px",
                      color: "#23a994",
                      marginTop: "2rem",
                      fontWeight: "700",
                      fontSize: "1.3rem",
                    }}
                    onClick={handle_preregistration}
                  >
                    Skriv mig op!
                  </Button>
                </>
              )}

              <img
                src={process.env.PUBLIC_URL + "/assets/Logo/logo.png"}
                alt=""
                style={{
                  width: "12rem",
                  marginTop: "2rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              ></img>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
}

export default Footer;
