import React, { useState } from "react";
import "./Profile.css";
import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react";
import MarketplaceNavbar from "../components/MarketplaceNavbar.tsx";
import MarketplaceFooter from "../components/MarketplaceFooter.tsx";
import { BsStarFill } from "react-icons/bs";
import { VscEmptyWindow } from "react-icons/vsc";
import { CiCircleRemove } from "react-icons/ci";
import { useEffect } from "react";
import axios from "axios";

function Rating({ rating, numReviews }) {
  return (
    <Box display="flex" alignItems="center">
      <Box
        as="span"
        mr="1"
        mt="0.5"
        color="gray.600"
        fontSize="md"
        fontWeight={"bold"}
      >
        {rating}
      </Box>
      <BsStarFill style={{ marginLeft: "1" }} color={"#ECC94B"} />

      <Box
        as="span"
        ml="2"
        mt="0.5"
        color="gray.600"
        fontSize="md"
        fontWeight={"600"}
      >
        ({numReviews})
      </Box>
    </Box>
  );
}

function Favorites() {
  const [isHovered, setIsHovered] = useState();
  const toast = useToast();
  const [salons, setSalons] = useState([]);

  const handleSalonRemoval = (saloonId, event) => {
    event.preventDefault();
    try {
      const res = axios.delete(`bookmarks/web/external/${saloonId}`);
      if (res["status"] === 200) {
        // Use the filter method to create a new array excluding the saloon with the specified saloonId
        const updatedSalons = salons.filter(
          (saloon) => saloon.saloon_id !== saloonId
        );
        setSalons(updatedSalons);
      }
    } catch (err) {
      toast({
        title: "Server Fejl",
        description: "Prøv venligst igen om et par minutter",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("bookmarks/web/external/");
        if (res["status"] === 200) {
          setSalons(res["data"].bookmarked_saloons);
        }
      } catch (err) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et par minutter",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    };

    fetchData();
  }, [salons]);

  return (
    <>
      <div className="clientFavourites">
        <Box position="sticky" top={0} zIndex={3}>
          <MarketplaceNavbar />
        </Box>

        <Box
          padding={"5%"}
          minHeight={"calc(100vh - 90px)"}
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexWrap={"wrap"}
        >
          {salons.length > 0 ? (
            salons.map((salon, index) => (
              <Box
                key={index}
                bg={"white.800"}
                className="salon_card"
                width={{
                  base: "97%",
                  md: "400px",
                  sm: "400px",
                }} // Use responsive width values
                maxH={{ base: "auto", sm: "400px", md: "350px" }} // Use responsive maxH values
                borderWidth="1px"
                rounded="lg"
                shadow="lg"
                position="relative"
                mb={5} // Add margin bottom to create space between rows
                as={"a"}
                href={
                  salon.saloon_tag === "external"
                    ? `/e/${salon.saloon_slug}`
                    : `/d/${salon.saloon_slug}`
                }
                style={{ textDecoration: "none" }}
                onMouseEnter={() => setIsHovered(index)}
                onMouseLeave={() => setIsHovered(null)}
                marginX={6}
                overflow={"hidden"}
              >
                <Button
                  position={"absolute"}
                  top={"0px"}
                  right={"0px"}
                  zIndex={99}
                  onClick={(e) => handleSalonRemoval(salon.saloon_id, e)}
                >
                  <CiCircleRemove fontSize={18} />
                </Button>
                <Box position="relative" overflow={"hidden"}>
                  <Image
                    src={
                      salon.saloon_tag === "direct"
                        ? "https://api-v3.bookinghero.io" + salon.saloon_logo
                        : salon.saloon_logo
                    }
                    alt={salon.saloon_name}
                    roundedTop="lg"
                    objectFit={"cover"}
                    height={"200px"}
                    width={"100%"}
                    style={{
                      transform: isHovered === index ? "scale(1.2)" : "none",
                      transition: "transform 0.3s ease",
                    }}
                  />
                </Box>

                <Box p="6">
                  <Box display="flex" alignItems="baseline"></Box>
                  <Flex
                    mt="1"
                    justifyContent="space-between"
                    alignContent="center"
                  >
                    <Box
                      fontSize="xl"
                      fontWeight="semibold"
                      as="h4"
                      lineHeight="tight"
                    >
                      <Text
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "280px",
                        }}
                      >
                        {salon.saloon_name}
                      </Text>
                    </Box>
                  </Flex>

                  <Flex justifyContent="space-between" alignContent="center">
                    <Box
                      fontSize="sm"
                      fontWeight="semibold"
                      as="h4"
                      lineHeight="tight"
                      mb={1}
                      mt={2}
                    >
                      <Text
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "300px",
                          color: "teal",
                        }}
                      >
                        {salon.saloon_adress}
                      </Text>
                    </Box>
                  </Flex>

                  <Flex
                    justifyContent="space-between"
                    alignContent="center"
                    fontSize={"lg"}
                  >
                    {salon.saloon_rating ? (
                      <Rating
                        rating={salon.saloon_rating}
                        numReviews={salon.saloon_number_reviews}
                      />
                    ) : (
                      <Box className="newSalon">New Salon</Box>
                    )}
                    {/*  <Box>{salon.saloon_distance} km</Box>*/}
                  </Flex>
                </Box>
              </Box>
            ))
          ) : (
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <VscEmptyWindow
                style={{ fontSize: "28px", marginBlock: "8px" }}
              />
              <Text fontWeight={"600"} fontSize={20} marginY={1}>
                Ingen favoritter endnu
              </Text>
              <Text fontSize={16} marginY={2}>
                Din favoritliste er tom, lad os udfylde den !
              </Text>
              <Link href="/søg?type=all">
                <Button
                  size={"lg"}
                  width={"fit-content"}
                  mt={2}
                  background={"#1a202c"}
                  color={"white"}
                  _hover={{ bg: "#232c3d" }}
                  marginY={1}
                >
                  Find saloner i nærheden af ​​dig
                </Button>
              </Link>
            </Box>
          )}
        </Box>

        <MarketplaceFooter />
      </div>
    </>
  );
}

export default Favorites;
