import React from "react";
import Loginleft from "../components/Loginleft";
import Loginright from "../components/Loginright";
import "./Login.css";
import { Helmet } from "react-helmet-async";

function Login() {
  return (
    <>
      <Helmet>
        <title>Log ind på Bookinghero Business Management</title>
        <meta
          name="description"
          content="Log ind på Bookinghero Business Management for at administrere din skønheds- eller velværevirksomhed og tider."
        />
        <link rel="canonical" href="/vilkar" />
      </Helmet>
      <div className="Login">
        <Loginleft className="Loginleft" />
        <Loginright className="Loginright" />
      </div>
    </>
  );
}

export default Login;
