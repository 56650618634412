import React from "react";
import "./Stats.css";

function Stats() {
  return (
    <>
      <div className="Stats">
        <div className="item">
          <div className="number">+40</div>
          <div className="title">Registered businesses</div>
          <div className="paragraph">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores,
            similique! Nobis.
          </div>
        </div>
        <div className="item">
          <div className="number">#01</div>
          <div className="title">Number 1 in Denmark</div>
          <div className="paragraph">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores,
            similique! Nobis.
          </div>
        </div>{" "}
        <div className="item">
          <div className="number">%25</div>
          <div className="title">Growth percentage</div>
          <div className="paragraph">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores,
            similique! Nobis.
          </div>
        </div>
      </div>
    </>
  );
}

export default Stats;
