import React from "react";
import { Route, Routes } from "react-router-dom";
import "./Settings.css";
import Settingsleftbar from "./Settingsleftbar";
import Generalinfo from "./Generalinfo";
import Contactinfo from "./Contactinfo";
import Worksch from "./Worksch";
import Album from "./Album";
import Location from "./Location";
import Security from "./Security";
import Support from "./Support";
import Cancelaccount from "./Cancelaccount";

function Settings() {
  return (
    <>
      <div className="Settings">
        <div className="Header">Administrer din konto</div>
        <div className="Settingscontainer">
          <div className="Settingsleftbar">
            <Settingsleftbar />
          </div>
          <div className="Settingscontent">
            <Routes>
              <Route exact path="generalinfo" element={<Generalinfo />} />
              <Route path="contactinfo" element={<Contactinfo />} />
              <Route path="workschedule" element={<Worksch />} />
              <Route path="album" element={<Album />} />
              <Route path="location" element={<Location />} />
              <Route path="security" element={<Security />} />
              <Route path="support" element={<Support />} />
              <Route path="cancel" element={<Cancelaccount />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
