import React, { useRef, useState } from "react";
import "./Profile.css";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import "./MarketplaceSupport.css";
import { Link } from "react-router-dom";

import MarketplaceNavbar from "../components/MarketplaceNavbar.tsx";
import MarketplaceFooter from "../components/MarketplaceFooter.tsx";
import {
  BsArrowLeft,
  BsFillInfoSquareFill,
  BsFillPersonFill,
} from "react-icons/bs";
import { MdDateRange, MdPayment } from "react-icons/md";
function Support() {
  const [isActiveSection, setIsActiveSection] = useState("about");
  const [showInfosMobile, setShowInfosMobile] = useState(false);
  const handleClickScroll = (section) => {
    const element = document.getElementById(section);
    setIsActiveSection(section);
    setShowInfosMobile(true);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  return (
    <>
      <div className="clientSupport">
        <Box position="sticky" top={0} zIndex={3}>
          <MarketplaceNavbar />
        </Box>

        <Box
          padding={"5%"}
          height={"100%"}
          width={"100%"}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Box className="menu">
            <Text fontSize={"0.9rem"}>
              <span style={{ opacity: "60%" }}>Kontakt os • </span>Help center
            </Text>
            <Text fontWeight={"600"} fontSize={"2rem"} marginY={"8px"}>
              For kunder
            </Text>
            <ul className="items">
              <Link onClick={() => handleClickScroll("about")}>
                <li className={isActiveSection === "about" ? "active" : ""}>
                  <BsFillInfoSquareFill
                    color="#00a6ed"
                    style={{ marginRight: "6px", fontSize: "18px" }}
                  />
                  Om Bookinghero
                </li>
              </Link>
              <Link onClick={() => handleClickScroll("profile")}>
                {" "}
                <li className={isActiveSection === "profile" ? "active" : ""}>
                  <BsFillPersonFill
                    color="#6b438b"
                    style={{ marginRight: "6px", fontSize: "18px" }}
                  />
                  Konto og profil
                </li>
              </Link>
              <Link onClick={() => handleClickScroll("booking")}>
                <li className={isActiveSection === "booking" ? "active" : ""}>
                  <MdDateRange
                    color="#0084ce"
                    style={{ marginRight: "6px", fontSize: "18px" }}
                  />
                  Tidsbestilling og booking
                </li>
              </Link>
              <Link onClick={() => handleClickScroll("payment")}>
                {" "}
                <li className={isActiveSection === "payment" ? "active" : ""}>
                  <MdPayment
                    color="#f92f60"
                    style={{ marginRight: "6px", fontSize: "18px" }}
                  />
                  Payments
                </li>
              </Link>
            </ul>
          </Box>
          {!showInfosMobile && (
            <Box className="menuMobile">
              <Text fontSize={"0.9rem"}>
                <span style={{ opacity: "60%" }}>Kontakt os • </span>
                Hjælpecenter
              </Text>
              <Text fontWeight={"600"} fontSize={"2rem"} marginY={"8px"}>
                For kunder
              </Text>
              <ul className="items">
                <Link onClick={() => handleClickScroll("about")}>
                  <li className={isActiveSection === "about" ? "active" : ""}>
                    <BsFillInfoSquareFill
                      color="#00a6ed"
                      style={{ marginRight: "6px", fontSize: "18px" }}
                    />
                    Om Bookinghero
                  </li>
                </Link>
                <Link onClick={() => handleClickScroll("profile")}>
                  {" "}
                  <li className={isActiveSection === "profile" ? "active" : ""}>
                    <BsFillPersonFill
                      color="#6b438b"
                      style={{ marginRight: "6px", fontSize: "18px" }}
                    />
                    Konto og profil
                  </li>
                </Link>
                <Link onClick={() => handleClickScroll("booking")}>
                  <li className={isActiveSection === "booking" ? "active" : ""}>
                    <MdDateRange
                      color="#0084ce"
                      style={{ marginRight: "6px", fontSize: "18px" }}
                    />
                    Tidsbestilling og booking
                  </li>
                </Link>
                <Link onClick={() => handleClickScroll("payment")}>
                  {" "}
                  <li className={isActiveSection === "payment" ? "active" : ""}>
                    <MdPayment
                      color="#f92f60"
                      style={{ marginRight: "6px", fontSize: "18px" }}
                    />
                    Betalinger
                  </li>
                </Link>
              </ul>
            </Box>
          )}
          <Box className="infos">
            <AboutSection setIsActiveSection={setIsActiveSection} />
            <hr />
            <ProfileSection setIsActiveSection={setIsActiveSection} />
            <hr />
            <BookingSection setIsActiveSection={setIsActiveSection} />
            <hr />
            <PaymentSection setIsActiveSection={setIsActiveSection} />
            <hr />

            <Flex marginY={8} justifyContent={"space-between"}>
              <Text>Fandt du ikke et svar? kontakt os venligst</Text>
              <Box>
                <Text marginBottom={3}>
                  <strong>Telefon:</strong> +33 44-44-55-66
                </Text>
                <Text>
                  <strong>E-mail:</strong> support@bookinghero.io
                </Text>
              </Box>
            </Flex>
          </Box>
          {showInfosMobile && (
            <Box className="infosMobile">
              <Button
                background={"none"}
                fontSize={28}
                paddingLeft={0}
                onClick={() => setShowInfosMobile(false)}
              >
                <BsArrowLeft />
              </Button>
              {isActiveSection === "about" && <AboutSection />}
              {isActiveSection === "profile" && <ProfileSection />}
              {isActiveSection === "booking" && <BookingSection />}
              {isActiveSection === "payment" && <PaymentSection />}

              <Flex marginY={8} justifyContent={"space-between"}>
                <Text>Fandt du ikke et svar? kontakt os venligst</Text>
                <Text>
                  <strong>Telefon:</strong> +33 44-44-55-66
                </Text>
              </Flex>
            </Box>
          )}
        </Box>

        <MarketplaceFooter />
      </div>
    </>
  );
}

const AboutSection = ({ setIsActiveSection }) => {
  return (
    <Box
      id="about"
      marginY={8}
      onMouseEnter={() => setIsActiveSection("about")}
    >
      <Text
        display={"flex"}
        alignItems={"center"}
        fontSize={"1.5rem"}
        fontWeight={"600"}
        marginBottom={3}
      >
        {" "}
        <BsFillInfoSquareFill
          color="#00a6ed"
          style={{ marginRight: "6px", fontSize: "26px" }}
        />
        Om Bookinghero
      </Text>
      <Text className="question">What is Bookinghero?</Text>
      <Text className="response">
        <Text className="paragraph">
          {" "}
          Bookinghero is Denmark's largest app and marketplace for booking
          primarily Beauty, Health and Wellness.
        </Text>
        <Text className="paragraph">
          With specialists connected all over the country, we make almost all of
          Denmark's salons and receptions bookable in one place. At Bookinghero,
          you as a consumer can search, book and pay for treatments and services
          with just a few clicks.
        </Text>
        <Text className="paragraph">
          Bookinghero makes booking easier than ever before for both your
          existing customers and all your new customers. But Bookinghero is not
          just great for your customers - Bookinghero also makes booking much
          easier for you as the operator.
        </Text>
      </Text>

      <Text className="response"></Text>
      <Text className="question">When should i contact Bookinghero?</Text>
      <Text className="response">
        <Text className="paragraph">
          The Bookinghero support team will be delighted to assist you with any
          questions regarding your Bookinghero account, the Bookinghero app, or
          the booking process.
        </Text>
        <Text className="paragraph">
          Please send us an email at <strong>support@bookinghero.io</strong>,
          and we will respond as quickly as possible.
        </Text>
        <Text className="paragraph">
          For any inquiries related to your bookings or appointments, including
          cancellations, payments, or specific service requests, kindly get in
          touch directly with the relevant establishment.
        </Text>
      </Text>
    </Box>
  );
};

const ProfileSection = ({ setIsActiveSection }) => {
  return (
    <Box
      id="profile"
      marginY={8}
      onMouseEnter={() => setIsActiveSection("profile")}
    >
      <Text
        display={"flex"}
        alignItems={"center"}
        fontSize={"1.5rem"}
        fontWeight={"600"}
        marginBottom={3}
      >
        {" "}
        <BsFillPersonFill
          color="#6b438b"
          style={{ marginRight: "6px", fontSize: "26px" }}
        />
        Konto og profil
      </Text>
      <Text className="question">How to create an account?</Text>
      <Text className="response">
        <Text className="paragraph">To get started on Bookinghero:</Text>
        <Text className="paragraph">
          <ol style={{ paddingLeft: "6%" }}>
            <li>
              Visit the Bookinghero website or download the Bookinghero app
            </li>
            <li>Choose "Sign Up"</li>
            <li>
              Provide your details and click to accept the privacy policy, terms
              of use, and service conditions.
            </li>
            <li>Click on "Sign Up"</li>
          </ol>
        </Text>
        <Text className="paragrahph">
          Once your account is created on Bookinghero, you can begin exploring
          the available services and booking your favorite treatments with local
          professionals.
        </Text>
      </Text>
      <Text className="question">I forget my password, how to reset it?</Text>
      <Text className="response">
        <Text className="paragraph">
          No need to worry. If you forget your password, you can easily regain
          access to your Fresha account:
        </Text>
        <Text className="paragraph">
          <ol style={{ paddingLeft: "6%" }}>
            <li>
              Visit our login page by clicking{" "}
              <Link href="" style={{ color: "#22ab96" }}>
                here
              </Link>
              .
            </li>
            <li>
              Click on the "Forgot Password?" link and enter the email address
              associated with your account.
            </li>
            <li>
              We will send you an email containing a link to reset your
              password.
            </li>
          </ol>
        </Text>
        <Text className="paragraph">
          Remember to check your spam folder if you can't find this email. It
          might have been sent there by mistake.
        </Text>
      </Text>

      {/* <Text className="question">Are my data secure?</Text>
                            <Text className="response">
                                <Text className="paragraph">At Bookinghero, we place a strong emphasis on security. Your data belongs solely to you (and definitely not to us). It will never be shared with anyone. User data is securely backed up every 5 minutes in Bookinghero's encrypted cloud-based database. Our system employs SSL security, and our computer hardware is equipped with sophisticated firewalls.</Text>
                            </Text> */}
    </Box>
  );
};

const BookingSection = ({ setIsActiveSection }) => {
  return (
    <Box
      id="booking"
      marginY={8}
      onMouseEnter={() => setIsActiveSection("booking")}
    >
      <Text
        display={"flex"}
        alignItems={"center"}
        fontSize={"1.5rem"}
        fontWeight={"600"}
        marginBottom={3}
      >
        {" "}
        <MdDateRange
          color="#0084ce"
          style={{ marginRight: "6px", fontSize: "26px" }}
        />
        Tidsbestilling og booking
      </Text>
      <Text className="question">What is Bookinghero?</Text>
      <Text className="response"></Text>
      <Text className="question">When should i contact Bookinghero?</Text>
      <Text className="response">
        <Text className="paragraph">
          The Bookinghero support team will be delighted to assist you with any
          questions regarding your Bookinghero account, the Bookinghero app, or
          the booking process.
        </Text>
        <Text className="paragraph">
          Please send us an email at support@bookinghero.io, and we will respond
          as quickly as possible.
        </Text>
        <Text className="paragraph">
          For any inquiries related to your bookings or appointments, including
          cancellations, payments, or specific service requests, kindly get in
          touch directly with the relevant establishment.
        </Text>
      </Text>
    </Box>
  );
};

const PaymentSection = ({ setIsActiveSection }) => {
  return (
    <Box
      id="payment"
      marginY={8}
      onMouseEnter={() => setIsActiveSection("payment")}
    >
      <Text
        display={"flex"}
        alignItems={"center"}
        fontSize={"1.5rem"}
        fontWeight={"600"}
        marginBottom={3}
      >
        {" "}
        <MdPayment
          color="#f92f60"
          style={{ marginRight: "6px", fontSize: "26px" }}
        />
        Betalinger
      </Text>
      <Text className="question">What is Bookinghero?</Text>
      <Text className="response"></Text>
      <Text className="question">When should i contact Bookinghero?</Text>
      <Text className="response">
        <Text className="paragraph">
          The Bookinghero support team will be delighted to assist you with any
          questions regarding your Bookinghero account, the Bookinghero app, or
          the booking process.
        </Text>
        <Text className="paragraph">
          Please send us an email at support@bookinghero.io, and we will respond
          as quickly as possible.
        </Text>
        <Text className="paragraph">
          For any inquiries related to your bookings or appointments, including
          cancellations, payments, or specific service requests, kindly get in
          touch directly with the relevant establishment.
        </Text>
      </Text>
    </Box>
  );
};
export default Support;
