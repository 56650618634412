import { DragHandleIcon, WarningIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Divider,
  IconButton,
  Menu,
  Button,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import "./Reviews.css";

function Reviews() {
  // store number of 5 star reviews
  const [fivestar, setFivestar] = useState(0);
  // store number of 4 star reviews
  const [fourstar, setFourstar] = useState(0);
  // store number of 3 star reviews
  const [threestar, setThreestar] = useState(0);
  // store number of 2 star reviews
  const [twostar, setTwostar] = useState(0);
  // store number of 1 star reviews
  const [onestar, setOnestar] = useState(0);

  // store the sum of all rating to get percentage
  const [sum, setSum] = useState(0);

  //refresh list everytime Review is Reported
  const [refreshKey, setRefreshKey] = useState(0);

  // get the review of id to report it
  const [reviewid, setReviewid] = useState("");

  // declare toast for notifications
  const toast = useToast();

  // get mean of all ratings
  const [mean, setMean] = useState(0);

  // get all the reviews and store them
  const [reviews, setReviews] = useState([]);

  // get all the reviews on page load
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/review");
        if (res.status === 200) {
          setReviews(res.data);
          calculatemean(res.data);
        }
      } catch (err) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et par minutter",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  // calculate the mean avarage and the sum of rating and also calculate eash star length
  const calculatemean = (reviews) => {
    var sum = 0;
    var fivestartemp = 0;
    var fourstartemp = 0;
    var threestartemp = 0;
    var twostartemp = 0;
    var onestartemp = 0;
    for (let index = 0; index < reviews.length; index++) {
      if (5.0 <= reviews[index].rating && reviews[index].rating <= 5.9) {
        fivestartemp = fivestartemp + 1;
      }
      if (4.0 <= reviews[index].rating && reviews[index].rating <= 4.9) {
        fourstartemp = fourstartemp + 1;
      }
      if (3.0 <= reviews[index].rating && reviews[index].rating <= 3.9) {
        threestartemp = threestartemp + 1;
      }
      if (2.0 <= reviews[index].rating && reviews[index].rating <= 2.9) {
        twostartemp = twostartemp + 1;
      }
      if (1.0 <= reviews[index].rating && reviews[index].rating <= 1.9) {
        onestartemp = onestartemp + 1;
      }
      sum += reviews[index].rating;
    }
    setMean(sum / reviews.length);
    setSum(reviews.length);
    setFivestar(fivestartemp);
    setFourstar(fourstartemp);
    setThreestar(threestartemp);
    setTwostar(twostartemp);
    setOnestar(onestartemp);
  };

  // handle closing and opening up the Review Report alert dialogue
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  // handle reporting a review
  const handleReportReview = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.put(`/review/${reviewid}`);
      if (res.status === 200) {
        onClose();
        setRefreshKey((oldKey) => oldKey + 1);
        toast({
          title: "Anmeldelse Rapporteret",
          description: "Vi vil se nærmere på dette",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (err) {
      toast({
        title: "Server Fejl",
        description: "Prøv venligst igen om et par minutter",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  return (
    <>
      <div className="Reviews">
        <div className="Header">Se, hvad dine kunder siger om dig</div>
        <div className="Ratingcontainer">
          <div className="Reviewsnumbercontainer">
            <div className="Reviewsnumberheading">Samlede anmeldelser</div>
            <div className="Reviewsnumber">{reviews.length}</div>
          </div>
          <div className="Ratingnumbercontainer">
            <div className="Ratingheader">Gennemsnitlig vurdering</div>
            <div className="Ratingnumber">{mean.toFixed(1)}</div>
          </div>
          <div className="Starscontainer">
            <div className="Progresscontainer">
              <div>
                <ReactStars
                  value={5}
                  count={5}
                  size={18}
                  activeColor="#ffd700"
                  edit={false}
                />
              </div>
              <progress
                className="Progress"
                value={(fivestar / sum) * 100}
                max="100"
              />
              <div className="Progressnumber">{fivestar}</div>
            </div>
            <div className="Progresscontainer">
              <div>
                <ReactStars
                  value={4}
                  count={5}
                  size={18}
                  activeColor="#ffd700"
                  edit={false}
                />
              </div>
              <progress
                className="Progress"
                value={(fourstar / sum) * 100}
                max="100"
              />
              <div className="Progressnumber">{fourstar}</div>
            </div>
            <div className="Progresscontainer">
              <div>
                <ReactStars
                  value={3}
                  count={5}
                  size={18}
                  activeColor="#ffd700"
                  edit={false}
                />
              </div>
              <progress
                className="Progress"
                value={(threestar / sum) * 100}
                max="100"
              />
              <div className="Progressnumber">{threestar}</div>
            </div>
            <div className="Progresscontainer">
              <div>
                <ReactStars
                  value={2}
                  count={5}
                  size={18}
                  activeColor="#ffd700"
                  edit={false}
                />
              </div>
              <progress
                className="Progress"
                value={(twostar / sum) * 100}
                max="100"
              />
              <div className="Progressnumber">{twostar}</div>
            </div>
            <div className="Progresscontainer">
              <div>
                <ReactStars
                  value={1}
                  count={5}
                  size={18}
                  activeColor="#ffd700"
                  edit={false}
                />
              </div>
              <progress
                className="Progress"
                value={(onestar / sum) * 100}
                max="100"
              />
              <div className="Progressnumber">{onestar}</div>
            </div>
          </div>
        </div>
        {/*    Reviews Section  */}
        <Divider className="Devider" />
        <div className="Reviewscontainer">
          {reviews.map((review) => (
            <div className="Review" key={reviews.id}>
              <div className="Infosectioncontainer">
                <div className="Infosection">
                  <div className="profileimg">
                    <Avatar src="https://bit.ly/broken-link" />
                  </div>
                  <div className="profileinfo">
                    <div className="profilename">{review.name}</div>
                    <div className="Review_section">
                      <div>{moment(review.time).fromNow()} </div>
                      <ReactStars
                        value={review.rating}
                        count={5}
                        isHalf={true}
                        size={18}
                        activeColor="#ffd700"
                        edit={false}
                      />
                      <div>{parseFloat(review.rating.toFixed(1))}</div>
                    </div>
                  </div>
                </div>
                <div>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<DragHandleIcon />}
                      variant="outline"
                      className="Accordiontbn"
                    />
                    <Portal>
                      <MenuList>
                        {!review.reported ? (
                          <>
                            <MenuItem
                              icon={<WarningIcon />}
                              onClick={() => {
                                setReviewid(review.id);
                                onOpen();
                              }}
                            >
                              Rapport
                            </MenuItem>
                          </>
                        ) : (
                          <>
                            <MenuItem icon={<WarningIcon />}>
                              Rapporteret
                            </MenuItem>
                          </>
                        )}
                      </MenuList>
                    </Portal>
                  </Menu>
                </div>
              </div>
              <div className="Reviewtxt">{review.review}</div>
            </div>
          ))}
        </div>
      </div>
      {/* ALERT DIALOGUE TO REPORT REVIEW */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Rapportere anmeldelser
            </AlertDialogHeader>

            <AlertDialogBody>
              Er du sikker? Vi gennemgår kommentaren.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Annullere
              </Button>
              <Button colorScheme="red" onClick={handleReportReview} ml={3}>
                Rapport
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default Reviews;
