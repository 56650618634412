import {
  Button,
  Input,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useToast,
  Icon,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import "./Statistic.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
function Statistic() {
  const startTimeRef = useRef();
  const endTimeRef = useRef();
  const [staffs, setStaffs] = useState();
  const [currentStaff, setCurrentStaff] = useState(null);
  const [currentStaff_, setCurrentStaff_] = useState(null);
  const [currentClient, setCurrentClient] = useState(null);
  const [currentClient_, setCurrentClient_] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const toast = useToast();
  const [stats, setStats] = useState();
  var clientList = [];

  // Export data as excel file
  function exportToExcel() {
    const header = [
      "KUNDE",
      "DATO",
      "TID",
      "SERVICE",
      "PRIS",
      "PERSONALE",
      "STATUS",
    ];
    const data = stats.map((stat) => [
      stat.clientname,
      `${stat.date.substring(8, 10)}/${stat.date.substring(
        5,
        7
      )}/${stat.date.substring(0, 4)}`,
      `${stat.start.substring(0, 5)}-${stat.end.substring(0, 5)}`,
      stat.name,
      `${stat.price} kr`,
      stat.staffname,
      stat.status,
    ]);
    const worksheet = XLSX.utils.aoa_to_sheet([header, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Table");
    XLSX.writeFile(workbook, "table.xlsx");
  }

  // Export data as pdf file
  function exportToPdf() {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        ["KUNDE", "DATO", "TID", "SERVICE", "PRIS", "PERSONALE", "STATUS"],
      ],
      body: stats.map((stat) => [
        stat.clientname,
        `${stat.date.substring(8, 10)}/${stat.date.substring(
          5,
          7
        )}/${stat.date.substring(0, 4)}`,
        `${stat.start.substring(0, 5)}-${stat.end.substring(0, 5)}`,
        stat.name,
        `${stat.price} kr`,
        stat.staffname,
        stat.status,
      ]),
    });
    doc.save("table.pdf");
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/staff");
        setStaffs(res.data);

        const res2 = await axios.get("/statistics");
        setStats(res2.data);
      } catch (err) {
        toast({
          title: "Server error",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  const applyFilter = () => {
    setCurrentStaff(currentStaff_);
    setCurrentClient(currentClient_);
    setRefreshKey(refreshKey + 1);
  };

  // used in options of client list
  function isIn(list, element) {
    for (let i in list) {
      if (element === list[i]) return true;
    }
    return false;
  }

  const [date, setDate] = useState(new Date().toISOString().substr(0, 10));
  return (
    <>
      <div className="Statistic">
        <div className="Header">Kundehistorik og tidligere aftaler</div>
        <div className="Headcontainer">
          <div className="Headerleft">
            <div className="Inputcontainer">
              <div className="Label">Fra : </div>
              <Input
                className="Input"
                defaultValue={date}
                size="md"
                type="date"
                placeholder="Select Date and Time"
                ref={startTimeRef}
              />
            </div>
            <div className="Inputcontainer">
              <div className="Label">Til : </div>
              <Input
                className="Input"
                placeholder="Select Date and Time"
                defaultValue={date}
                size="md"
                type="date"
                ref={endTimeRef}
              />
            </div>
            <div className="Inputcontainer">
              <Select
                placeholder="Alt personale"
                className="Input"
                onChange={(e) => {
                  setCurrentStaff_(e.target.value);
                }}
              >
                {staffs &&
                  staffs.map((staff) => (
                    <option key={staff.id} value={staff.name}>
                      {staff.name}
                    </option>
                  ))}
              </Select>
            </div>
            <div className="Inputcontainer">
              <Select
                placeholder="Alle kunder"
                className="Input"
                onChange={(e) => {
                  setCurrentClient_(e.target.value);
                }}
              >
                {stats &&
                  stats.map(
                    (stat) =>
                      !isIn(clientList, stat.clientname) &&
                      clientList.push(stat.clientname) && (
                        <option key={stat.clientname} value={stat.clientname}>
                          {stat.clientname}
                        </option>
                      )
                  )}
              </Select>
            </div>
            <div className="Inputcontainer">
              <Button className="filterbtn" onClick={applyFilter}>
                Anvend filter
              </Button>
            </div>
          </div>
          <div className="Headerright">
            <div className="Inputcontainer">
              <Icon
                title={"export to pdf"}
                cursor={"pointer"}
                mr={2}
                fontSize={36}
                as={MdPictureAsPdf}
                onClick={exportToPdf}
              />
            </div>
            <div className="Inputcontainer">
              <Icon
                title={"export to excel"}
                cursor={"pointer"}
                ml={2}
                mr={5}
                fontSize={36}
                as={RiFileExcel2Fill}
                onClick={exportToExcel}
              />
            </div>
          </div>
        </div>
        <div className="Tablecontainer">
          <TableContainer className="Table">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>KUNDE</Th>
                  <Th>DATO</Th>
                  <Th>TID</Th>
                  <Th>SERVICE</Th>
                  <Th>PRIS</Th>
                  <Th>PERSONALE </Th>
                  <Th>STATUS</Th>
                </Tr>
              </Thead>
              <Tbody>
                {stats &&
                  stats.map(
                    (stat) =>
                      (!currentStaff || stat.staffname === currentStaff) &&
                      (!currentClient || stat.clientname === currentClient) &&
                      (!startTimeRef.current.value ||
                        (parseInt(stat.date.substring(8, 10)) >=
                          parseInt(
                            startTimeRef.current.value.substring(8, 10)
                          ) &&
                          parseInt(stat.date.substring(5, 7)) >=
                            parseInt(
                              startTimeRef.current.value.substring(5, 7)
                            ) &&
                          parseInt(stat.date.substring(0, 4)) >=
                            parseInt(
                              startTimeRef.current.value.substring(0, 4)
                            ))) &&
                      (!endTimeRef.current.value ||
                        (parseInt(stat.date.substring(8, 10)) <=
                          parseInt(endTimeRef.current.value.substring(8, 10)) &&
                          parseInt(stat.date.substring(5, 7)) <=
                            parseInt(
                              endTimeRef.current.value.substring(5, 7)
                            ) &&
                          parseInt(stat.date.substring(0, 4)) <=
                            parseInt(
                              endTimeRef.current.value.substring(0, 4)
                            ))) && (
                        <Tr key={stat.id}>
                          <Td>{stat.clientname}</Td>
                          <Td>
                            {stat.date.substring(8, 10)}/
                            {stat.date.substring(5, 7)}/
                            {stat.date.substring(0, 4)}
                          </Td>
                          <Td>
                            {stat.start.substring(0, 5)}-
                            {stat.end.substring(0, 5)}
                          </Td>
                          <Td>{stat.name}</Td>
                          <Td>{stat.price} kr</Td>
                          <Td>{stat.staffname}</Td>
                          <Td>{stat.status}</Td>
                        </Tr>
                      )
                  )}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
}

export default Statistic;
