import {
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import "./Plans.css";
import { BsArrowRight } from "react-icons/bs";
import { CheckCircleIcon } from "@chakra-ui/icons";
import axios from "axios";

function Plans() {
  // declare toast for notifications
  const toast = useToast();

  //check if the register form is submited or not
  const [submit, setSubmit] = useState(false);

  // store data from registration inputs
  const [myinputs, setInputs] = useState({
    email: "",
    name: "",
    phone: "",
    adress: "",
    category: "",
  });

  // handle getting the data from the inputs
  const handlechange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // handle request errors
  const [error, setErr] = useState(null);

  // handle registring a saloon
  const registersaloon = async (e) => {
    e.preventDefault();
    try {
      if (
        myinputs.name === "" ||
        myinputs.email === "" ||
        myinputs.phone === "" ||
        myinputs.adress === "" ||
        myinputs.category === ""
      ) {
        toast({
          title: "Type error",
          description: "Please fill all the required fields.",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else {
        const res = await axios.post("/saloon/register", myinputs);
        if (res.status === 200) {
          setSubmit(true);
        }
      }
    } catch (err) {
      setErr(err.response);
      if (error.data.status === 404) {
        toast({
          title: "Server error",
          description: error.data.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else {
        toast({
          title: "Server error",
          description: "Please try again in a few minuts",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  return (
    <>
      {submit ? (
        <>
          <div className="Submitedcontainer">
            <div className="Submitedheader">Subscription Submited</div>
            <div className="Submitedinfo">
              Our Support team will contact you using the contact information
              you have provided during the next week.
            </div>
            <CheckCircleIcon color="#23A994" className="Successcheck" />
          </div>
        </>
      ) : (
        <>
          <div className="Plans" id="registerform">
            <div className="title">Register now</div>
            <div className="form">
              <div className="leftForm">
                <div className="Label">Plan</div>
                <Input
                  variant="filled"
                  required
                  size="lg"
                  value="Starter"
                  disabled={true}
                />
                <FormControl isRequired>
                  <FormLabel className="Label">Business Name</FormLabel>
                  <Input
                    name="name"
                    onChange={handlechange}
                    variant="filled"
                    required
                    size="lg"
                    placeholder="The name of your business"
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel className="Label">Phone number</FormLabel>
                  <Input
                    type="number"
                    name="phone"
                    onChange={handlechange}
                    variant="filled"
                    required
                    size="lg"
                    placeholder="Enter your phone number"
                  />
                </FormControl>
              </div>
              <div className="rightForm">
                <FormControl isRequired>
                  <FormLabel className="Label">Category</FormLabel>
                  <Select
                    variant="filled"
                    height={12}
                    name="category"
                    onChange={handlechange}
                    placeholder="Select Category"
                  >
                    <option value="hairstyle">Hairstyle</option>
                    <option value="fitness">Fitness</option>
                    <option value="beauty">Beauty</option>
                    <option value="facials">Facials</option>
                    <option value="message">Message</option>
                    <option value="nails">Nails</option>
                    <option value="filler">Filler</option>
                    <option value="wellness">Wellness</option>
                    <option value="lashes">Lashes</option>
                  </Select>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel className="Label">Email</FormLabel>
                  <Input
                    name="email"
                    onChange={handlechange}
                    variant="filled"
                    required
                    size="lg"
                    type="email"
                    placeholder="Enter your email adress"
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel className="Label">Adress</FormLabel>
                  <Input
                    name="adress"
                    onChange={handlechange}
                    variant="filled"
                    required
                    size="lg"
                    placeholder="Enter your Business Adress"
                  />
                </FormControl>
              </div>
            </div>
            <div className="btns">
              <Button className="id1" onClick={registersaloon}>
                Get started <Icon ml={2} h={5} w={6} as={BsArrowRight} />
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Plans;
