import { Box, Button, Divider, Input, useToast } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import "./Contactinfo.css";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import axios from "axios";

function Contactinfo() {
  //
  const [refreshKey, setRefreshKey] = useState(0);
  const toast = useToast();

  const [saloon, setSaloon] = useState({}); // for the saloon tuple returned from the data base

  // References for the inputs
  const phoneNumberRef = useRef();
  const facebookAdrRef = useRef();
  const instagramAdrRef = useRef();
  const twitterAdrRef = useRef();
  const tiktokAdrRef = useRef();
  const pinterestAdrRef = useRef();

  // Booleans to validate text inside inputs
  const [isFacebookUrl, setIsFacebookUrl] = useState(true);
  const [isInstagramUrl, setIsInstagramUrl] = useState(true);
  const [isTwitterUrl, setIsTwitterUrl] = useState(true);
  const [isTiktokUrl, setIsTiktokUrl] = useState(true);
  const [isPinterestUrl, setIsPinterestUrl] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);

  // Validate the urls inputs using regex and change validation variables if necessary
  function validateLinks() {
    let valid = true;
    let patternFacebook =
      /^(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w]*\/)?(?:profile.php\?id=(?=\d.*))?([\w]*)?/;
    let patternInstagram =
      /^(?:(?:http|https):\/\/)?(?:www.)?instagram.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w]*\/)?(?:profile.php\?id=(?=\d.*))?([\w]*)?/;
    let patternTwitter =
      /^(https:\/\/twitter.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))/;
    let patternTiktok = /^(https?:\/\/){0,1}(www\.){0,1}tiktok\.com/;
    let patternPinterest = /^(https?:\/\/){0,1}(www\.){0,1}pinterest\.com/;

    if (
      facebookAdrRef.current.value &&
      !patternFacebook.test(facebookAdrRef.current.value)
    ) {
      setIsFacebookUrl(false);
      valid = false;
    }
    if (
      instagramAdrRef.current.value &&
      !patternInstagram.test(instagramAdrRef.current.value)
    ) {
      setIsInstagramUrl(false);
      valid = false;
    }
    if (
      twitterAdrRef.current.value &&
      !patternTwitter.test(twitterAdrRef.current.value)
    ) {
      setIsTwitterUrl(false);
      valid = false;
    }
    if (
      tiktokAdrRef.current.value &&
      !patternTiktok.test(tiktokAdrRef.current.value)
    ) {
      setIsTiktokUrl(false);
      valid = false;
    }
    if (
      pinterestAdrRef.current.value &&
      !patternPinterest.test(pinterestAdrRef.current.value)
    ) {
      setIsPinterestUrl(false);
      valid = false;
    }

    return valid;
  }

  // Get Contact infos related to the saloon
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/saloon/contact");
        setSaloon(res.data[0]);
      } catch (err) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et par minutter",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  // Update Contact infos
  const handleUpdateSaloon = async (e) => {
    e.preventDefault();
    // Setting all links to valid in case they were false from previous tasks
    setIsInstagramUrl(true);
    setIsFacebookUrl(true);
    setIsTwitterUrl(true);
    setIsTiktokUrl(true);
    setIsPinterestUrl(true);

    // Validate phone number on Denmark format (8 digits) using regex
    if (!/^[0-9]{8}$/.test(phoneNumberRef.current.value)) {
      toast({
        title: "Ugyldigt telefonnummer",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setIsValidPhone(false);
    } else {
      // validate links
      if (!validateLinks()) {
        toast({
          title: "Ugyldige links",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      } else {
        // Setting all links to valid in case they were false from previous tasks
        setIsInstagramUrl(true);
        setIsFacebookUrl(true);
        setIsTwitterUrl(true);
        setIsTiktokUrl(true);
        setIsPinterestUrl(true);
        setIsValidPhone(true);

        try {
          const saloonContactData = {
            phone: phoneNumberRef.current.value,
            facebook: facebookAdrRef.current.value,
            instagram: instagramAdrRef.current.value,
            twitter: twitterAdrRef.current.value,
            tiktok: tiktokAdrRef.current.value,
            pinterest: pinterestAdrRef.current.value,
          };
          const res = await axios.put(`/saloon/contact`, saloonContactData);
          if (res.status === 200) {
            setRefreshKey((oldKey) => oldKey + 1);
            toast({
              title: "Virksomheds Info opdateret",
              status: "success",
              duration: 9000,
              position: "top-right",
              isClosable: true,
            });
          }
        } catch (err) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et par minutter",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    }
  };

  // Front end part starts from  here:
  return (
    <>
      <div className="Contactinfo">
        <div className="Contactheader">Kontact Information</div>
        <div className="Phonecontainer">
          <div>Telefonnummer</div>
          <Input
            variant="filled"
            type="number"
            placeholder="040-000"
            className={isValidPhone ? "" : "redError"}
            defaultValue={saloon.phone}
            ref={phoneNumberRef}
          />
        </div>
        <div className="Emailcontainer">
          <div>E-mailadresse (Kontakt support for at ændre e-mail)</div>
          <Input variant="filled" value={saloon.email} disabled={true} />
        </div>
        <Divider />
        <div className="Socialmediacontainer">
          <Accordion allowMultiple className="Accordion">
            <AccordionItem className="Accordioitem">
              <AccordionButton className="Accordionbtn">
                <Box as="span" flex="1" textAlign="left">
                  Sociale medier konti
                </Box>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel className="Accordionpanel">
                <div className="Fbcontainer">
                  <div>Facebook</div>
                  <Input
                    variant="filled"
                    placeholder="Facebook kontolink"
                    ref={facebookAdrRef}
                    defaultValue={saloon.facebook}
                    className={isFacebookUrl ? "" : "redError"}
                  />
                </div>
                <div className="Fbcontainer">
                  <div>Instagram</div>
                  <Input
                    variant="filled"
                    placeholder="Instagram kontolink"
                    ref={instagramAdrRef}
                    defaultValue={saloon.instagram}
                    className={isInstagramUrl ? "" : "redError"}
                  />
                </div>
                <div className="Fbcontainer">
                  <div>Twitter</div>
                  <Input
                    variant="filled"
                    placeholder="Twitter kontolink"
                    ref={twitterAdrRef}
                    defaultValue={saloon.twitter}
                    className={isTwitterUrl ? "" : "redError"}
                  />
                </div>
                <div className="Fbcontainer">
                  <div>Tiktok</div>
                  <Input
                    variant="filled"
                    placeholder="Tiktok kontolink"
                    ref={tiktokAdrRef}
                    defaultValue={saloon.tiktok}
                    className={isTiktokUrl ? "" : "redError"}
                  />
                </div>
                <div className="Fbcontainer">
                  <div>Pinterest</div>
                  <Input
                    variant="filled"
                    placeholder="Pinterest kontolink"
                    ref={pinterestAdrRef}
                    defaultValue={saloon.pinterest}
                    className={isPinterestUrl ? "" : "redError"}
                  />
                </div>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <Button className="Updatebtn" onClick={handleUpdateSaloon}>
          Opdatering
        </Button>
      </div>
    </>
  );
}

export default Contactinfo;
