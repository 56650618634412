import "./SalonPage.css";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import {
  Marker,
  DirectionsRenderer,
  DirectionsService,
} from "@react-google-maps/api";
import { Icon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Avatar,
} from "@chakra-ui/react";
import {
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { GoLocation } from "react-icons/go";
import { GrMapLocation } from "react-icons/gr";
import { FiPhone, FiTwitter } from "react-icons/fi";
import {
  AiOutlineMail,
  AiOutlineInstagram,
  AiOutlineShareAlt,
  AiOutlineHeart,
  AiFillHeart,
} from "react-icons/ai";
import { FaTiktok, FaPinterestP } from "react-icons/fa";
import { CiFacebook } from "react-icons/ci";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Home_components/Footer";
import TopNavBar from "../components/Home_components/Topnav";
import "animate.css";
import ReactStars from "react-rating-stars-component";
import moment from "moment/moment";
import { Clientauthcontext } from "../components/context/Clientauthcontext";
import { Helmet } from "react-helmet-async";

// import Share from "../components/Share";
function SalonPage() {
  // Client Authentication context - Client user information
  const { currentclient } = useContext(Clientauthcontext);
  //R.R.D naviguation - Link to Booking page
  const naviguate = useNavigate();
  // Review modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Subservice description modal
  const {
    isOpen: isDescOpen,
    onOpen: onDescOpen,
    onClose: onDescClose,
  } = useDisclosure();

  const [getDirection, setGetDirection] = useState(false);
  const { idSaloon } = useParams();
  // set the inital zoom for the MAP
  const MapZoom = 14;
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAYXObwfBn4s7Fmgwy96ztYPSypI9l-Hmg",
  });
  const [currentSalonGal, setCurrentSalonGal] = useState();
  const [today, setToday] = useState("");
  const [isLoadedSaloon, setIsLoadedSaloon] = useState(false);
  // Set current day -  Work schedule
  useEffect(() => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date();
    setToday(daysOfWeek[date.getDay()]);
  }, []);
  const [saloonInfo, setSaloonInfo] = useState();
  const [reviews, setReviews] = useState();
  const [staffs, setStaffs] = useState();
  const [schedule, setSchedule] = useState();
  const [services, setServices] = useState();
  const [servicesIndex, setServicesIndex] = useState([]);
  const [selectedSubservices, setSelectedSubservices] = useState([]);
  const [startX, setStartX] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isBookmark, setIsBookmark] = useState(false);
  const images = [
    saloonInfo && saloonInfo.image1,
    saloonInfo && saloonInfo.image2,
    saloonInfo && saloonInfo.image3,
    saloonInfo && saloonInfo.image4,
    saloonInfo && saloonInfo.image5,
  ].filter(Boolean);
  const [timerId, setTimerId] = useState(null);
  // Swap images - Carousel
  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    clearInterval(timerId);
  };
  const [directions, setDirections] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [isLoaded2, setIsLoaded2] = useState(false);
  const [called, setCalled] = useState(false);
  const [bookmarks, setBookmarks] = useState([]);
  // Get position of visitor - On page load
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setIsLoaded2(true);
      });
    }
  }, []);
  // Get direction - Map
  const directionsCallback = (response) => {
    if (!called && response !== null) {
      setDirections(response);
      setCalled(true);
    }
  };
  const directionsServiceOptions = {
    origin: userLocation,
    destination: {
      lat: saloonInfo && saloonInfo.saloon_latitude,
      lng: saloonInfo && saloonInfo.saloon_longtitude,
    },
    travelMode: "DRIVING",
  };
  // Define an asynchronous function called `handleBookmark` that performs the following steps:
  const handleBookmark = async () => {
    try {
      // Check if saloon is in bookmarks. If it does, exit the function.
      if (bookmarking()) return;

      // else we are going to add it to bookmarks
      // Send a POST request to the `/bookmarks` endpoint with the `saloonid` parameter set to the `saloon_id` property of the `saloonInfo` object.
      const res = await axios.post("/bookmarks", {
        saloonid: saloonInfo.saloon_id,
      });

      // If the request is successful, set the `isBookmark` state to true.
      if (res.status === 200) {
        setIsBookmark(true);
      }
      // Send a GET request to the `/bookmarks` endpoint.
      const res2 = await axios.get("/bookmarks");

      // If the request is successful, set the `bookmarks` state to the data received from the response.
      if (res2.status === 200) {
        setBookmarks(res2.data);
      }

      // Display a success toast notification.
      toast({
        title: "Vellykket",
        description: "Saloon er blevet tilføjet til favoritter",
        position: "top-right",
        isClosable: true,
        status: "success",
      });
    } catch {
      // If an error occurs, display an error toast notification.
      toast({
        title: "Server Fejl",
        description: "Prøv venligst igen om et par minutter",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  // Mobile view - End of carousel - Next step first image
  const handleTouchEnd = (e) => {
    const endX = e.changedTouches[0].clientX;
    if (startX > endX + 50) {
      setCurrentIndex(
        currentIndex === images.length - 1 ? 0 : currentIndex + 1
      );
    } else if (startX < endX - 50) {
      setCurrentIndex(
        currentIndex === 0 ? images.length - 1 : currentIndex - 1
      );
    }
    setStartX(null);
    const intervalId = setInterval(() => {
      setCurrentIndex(
        currentIndex === images.length - 1 ? 0 : currentIndex + 1
      );
    }, 4000);
    setTimerId(intervalId);
  };
  const toast = useToast();
  // Load saloon data - On page load
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadedSaloon(false);
        setGetDirection(false);
        if (currentclient) {
          const res2 = await axios.get(`/bookmarks`);
          if (res2.status === 200) {
            setBookmarks(res2.data);
          }
        }
        const res = await axios.get(`/saloon/byslug/${idSaloon}`);
        if (res.status === 200) {
          setSaloonInfo(res.data.saloon_info);
          setReviews(res.data.reviews);
          setStaffs(res.data.staffs);
          setSchedule(res.data.schedule);
          setServices(res.data.services);

          // get index list of services
          var indexes = [];
          for (let index = 0; index < res.data.services.length; index++) {
            indexes.push(index);
          }
          setServicesIndex(indexes);
          setIsLoadedSaloon(true);
          setCalled(false);
        }
      } catch (err) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et par minutter",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showFull, setShowFull] = useState(false);
  const [showAllReviews, setShowAllReviews] = useState(false);
  // Subservice description - More info modal
  const [description, setDescription] = useState("");
  if (!saloonInfo) {
    return null;
  }

  const aboutText = saloonInfo.saloon_about;
  const shouldTruncate = aboutText && aboutText.length > 250;
  // Handle more info - less info
  const handleToggleClick = () => {
    setShowFull(!showFull);
  };

  // Check if saloon is in bookmarks
  const bookmarking = () => {
    const isBookmarked = bookmarks.some(
      (bookmark) => bookmark.saloon_id === saloonInfo.saloon_id
    );
    return isBookmarked;
  };
  const visibleReviews = showAllReviews ? reviews : reviews.slice(0, 3);
  // Google maps grey styles
  const options = {
    styles: [
      {
        elementType: "geometry",
        stylers: [{ color: "#f5f5f5" }],
      },
      {
        elementType: "labels.text.fill",
        stylers: [{ color: "#616161" }],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [{ color: "#f5f5f5" }],
      },
      {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [{ color: "#bdbdbd" }],
      },
      {
        featureType: "landscape",
        elementType: "geometry.fill",
        stylers: [{ color: "#c8e6c9" }],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [{ color: "#eeeeee" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#757575" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#e5e5e5" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [{ color: "#757575" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#dadada" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#616161" }],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e" }],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [{ color: "#e5e5e5" }],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [{ color: "#eeeeee" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#c9c9c9" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e" }],
      },
    ],
    disableDefaultUI: true,
    zoomControl: true,
  };

  return (
    <div className="SalonPage">
      <Helmet>
        <title>
          Book behandlinger hos {saloonInfo && saloonInfo.saloon_name} med
          Bookinghero
        </title>
        <meta
          name="description"
          content={`${
            saloonInfo && saloonInfo.saloon_name
          } tilbyder en række skønheds- og velværebehandlinger. Book din næste tid hos ${
            saloonInfo && saloonInfo.saloon_name
          } med Bookinghero og nyd en luksuriøs behandling. Søg efter og book de bedste skønheds- og velværebehandlinger i nærheden af dig med Bookinghero. Vælg mellem hundreder af virksomheder og behandlinger.`}
        />
        <link rel="canonical" href="/places/:idSaloon" />
      </Helmet>
      {!isLoadedSaloon ? (
        <>
          <div className="Loading_animation">
            <img
              src={process.env.PUBLIC_URL + "/assets/Logo/logo_shape.png"}
              alt=""
              className="Loading_logo animate__animated animate__pulse animate__infinite"
            ></img>
          </div>
        </>
      ) : (
        <>
          <TopNavBar is_default_checked={true} />
          <div className="header">
            <div className="left">
              <div className="elements_mobile">
                Hjem / Søgeresultater / {saloonInfo && saloonInfo.saloon_name}{" "}
              </div>
              <div className="SaloonLogo">
                <img
                  src={
                    saloonInfo &&
                    "https://api-v3.bookinghero.io" + saloonInfo.saloon_logo
                  }
                  alt=""
                  className="Logo"
                />
              </div>
              <div className="secondFlex">
                <div className="salonName">
                  {saloonInfo && saloonInfo.saloon_name}
                </div>
                <div className="saloonRating">
                  <ReactStars
                    value={Math.round(saloonInfo.rating)}
                    count={5}
                    size={23}
                    activeColor="#ffd700"
                    edit={false}
                    className="stars"
                  />
                  <div className="rating">
                    {parseFloat(saloonInfo && saloonInfo.rating).toFixed(1)}
                  </div>
                </div>
              </div>
              <div className="thirdFlex">
                <Icon as={GoLocation} className="locationIcon" />{" "}
                {saloonInfo && saloonInfo.saloon_adress} -{" "}
                {saloonInfo && saloonInfo.saloon_city}
              </div>
            </div>
            <div className="right">
              <div className="elements">
                <Link to="/" className="breadCrumb">
                  Hjem
                </Link>{" "}
                /{" "}
                <Link to="/hvad/hvilken/hvor" className="breadCrumb">
                  Søgeresultater
                </Link>{" "}
                / {saloonInfo && saloonInfo.saloon_name}{" "}
              </div>
              <div className="operations">
                <button
                  className="delete"
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    toast({
                      title: "Info",
                      description: "Saloon-URL kopieret til udklipsholderen",
                      position: "top-right",
                      isClosable: true,
                      status: "success",
                    });
                  }}
                >
                  <Icon
                    style={{
                      marginRight: "3px",
                      position: "relative",
                      top: "2px",
                    }}
                    as={AiOutlineShareAlt}
                  />
                  Del
                </button>
                {currentclient ? (
                  <>
                    {bookmarking() || isBookmark ? (
                      <button disabled className="action" id="disabled">
                        <Icon
                          style={{
                            marginRight: "3px",
                            position: "relative",
                            top: "2px",
                            color: "#23a994",
                          }}
                          as={AiFillHeart}
                        />
                        Bogmærke
                      </button>
                    ) : (
                      <button className="action" onClick={handleBookmark}>
                        <Icon
                          style={{
                            marginRight: "3px",
                            position: "relative",
                            top: "2px",
                          }}
                          as={AiOutlineHeart}
                        />
                        Bogmærke
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <button disabled className="action" id="disabled">
                      <Icon
                        style={{
                          marginRight: "3px",
                          position: "relative",
                          top: "2px",
                        }}
                        as={AiOutlineHeart}
                      />
                      Bogmærke
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="line"></div>
          <div className="images_mobile">
            <div
              className="image-slider"
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              <div className="subimages">
                <img
                  src={"https://api-v3.bookinghero.io".concat(
                    images[currentIndex]
                  )}
                  className="slider-image"
                  alt=""
                />
              </div>
              <div className="navigation">
                {images.map((image, index) => (
                  <div
                    key={image}
                    className={index === currentIndex ? "dot active" : "dot"}
                    onClick={() => setCurrentIndex(index)}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="operations_mobile">
            <button
              classname="action"
              className="delete"
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                toast({
                  title: "Info",
                  description: "Virksomhedens adresse kopieres",
                  position: "top-right",
                  isClosable: true,
                  status: "success",
                });
              }}
            >
              <Icon
                style={{ marginRight: "3px", position: "relative", top: "2px" }}
                as={AiOutlineShareAlt}
              />
              Del
            </button>
            <button className="action" onClick={handleBookmark}>
              {bookmarking() || isBookmark ? (
                <Icon
                  style={{
                    marginRight: "3px",
                    position: "relative",
                    top: "2px",
                    color: "#23a994",
                  }}
                  as={AiFillHeart}
                />
              ) : (
                <Icon
                  style={{
                    marginRight: "3px",
                    position: "relative",
                    top: "2px",
                  }}
                  as={AiOutlineHeart}
                />
              )}
              Bogmærke
            </button>
          </div>
          <div className="main">
            <div className="left">
              <div className="description">
                <h2>Om denne konto</h2>
                <p className="text">
                  {shouldTruncate && !showFull
                    ? `${aboutText.substring(0, 250)}... `
                    : aboutText.concat(" ")}

                  {shouldTruncate && (
                    <button onClick={handleToggleClick} className="mere_info">
                      {showFull ? " mindre info" : " mere info"}
                    </button>
                  )}
                </p>
              </div>
              <div className="services">
                <h2>Tjenester</h2>
                <div className="Servicelistcontainer">
                  <Accordion
                    defaultIndex={servicesIndex}
                    allowMultiple
                    className="Accordion"
                  >
                    {services &&
                      services.map((service) => (
                        <AccordionItem key={service.service_id}>
                          <h2 className="serviceItem">
                            <AccordionButton>
                              <Box
                                flex="1"
                                textAlign="left"
                                className="Accordionheader"
                              >
                                {service.service_name}
                              </Box>
                              <AccordionIcon className="iconAccordio" />
                            </AccordionButton>
                          </h2>
                          {service.subservices.map((subservice) => (
                            <AccordionPanel pb={4}>
                              <div className="Panelitem">
                                <div className="Panelheading">
                                  <div className="Heading">
                                    <div>{subservice.subservice_name}</div>
                                    <div>
                                      <span className="subInfo">
                                        {" "}
                                        {subservice.subservice_time} min •{" "}
                                        {subservice.subservice_price} Kr —{" "}
                                      </span>
                                      <button
                                        className={
                                          selectedSubservices.includes(
                                            subservice
                                          )
                                            ? "hidden"
                                            : "mere_info"
                                        }
                                        onClick={() => {
                                          setDescription(
                                            subservice.subservice_description
                                          );
                                          onDescOpen();
                                        }}
                                      >
                                        mere info
                                      </button>
                                      <button
                                        className={
                                          selectedSubservices.includes(
                                            subservice
                                          )
                                            ? "less_info"
                                            : "hidden"
                                        }
                                        onClick={() => {
                                          setSelectedSubservices(
                                            (prevSelected) =>
                                              prevSelected.filter(
                                                (selected) =>
                                                  selected !== subservice
                                              )
                                          );
                                        }}
                                      >
                                        less info
                                      </button>
                                    </div>
                                    <div
                                      className={
                                        selectedSubservices.includes(subservice)
                                          ? ""
                                          : "hidden"
                                      }
                                    >
                                      {subservice.subservice_description}{" "}
                                    </div>
                                  </div>
                                  <Link
                                    to={
                                      "/d/".concat(idSaloon) +
                                      "/".concat(subservice.subservice_id)
                                    }
                                  >
                                    <Button className="btn_bestil">
                                      Bestil
                                    </Button>
                                  </Link>{" "}
                                </div>
                              </div>
                            </AccordionPanel>
                          ))}
                        </AccordionItem>
                      ))}
                  </Accordion>
                </div>
              </div>
              <div className="staffs">
                <h2>Vores personale</h2>
                <div className="cards">
                  {staffs &&
                    staffs.map((staff) => (
                      <div className="card" key={staff.staff_id}>
                        <img
                          fallbackSrc="https://via.placeholder.com/150"
                          src={
                            "https://api-v3.bookinghero.io" + staff.staff_image
                          }
                          alt=""
                          style={{
                            objectFit: "contain",
                            width: "8rem",
                            borderRadius: "12px",
                          }}
                          className="Staffimg"
                        ></img>
                        <div className="Staffinfo">
                          <div className="Staffname">{staff.staff_name}</div>
                          <div className="Staffocupation">
                            {staff.staff_occupation}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="reviews">
                <h2>Anmeldelser</h2>
                <div className="reviewsList">
                  {visibleReviews.map((review) => (
                    <div className="review" key={review.review_id}>
                      <div className="reviewHeader">
                        <Avatar src="https://bit.ly/broken-link" />

                        <div>
                          <div className="nameReviewer">
                            {review.client_name}
                          </div>
                          <div className="dateReview">
                            {moment(review.review_time).fromNow()}{" "}
                            <ReactStars
                              value={Math.round(review.review_rating)}
                              count={5}
                              size={18}
                              activeColor="#ffd700"
                              edit={false}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="test">
                        <div className="textReview">{review.review}</div>
                      </div>
                    </div>
                  ))}
                </div>
                {!showAllReviews && (
                  <button className="allReviews" onClick={onOpen}>
                    Se alle anmeldelser
                  </button>
                )}
              </div>
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent className="reviews">
                  <ModalHeader className="Modalheader">
                    Alle anmeldelser
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <div className="reviewsList">
                      {reviews.map((review, index) => (
                        <>
                          <div className="review_in_all" key={review.review_id}>
                            <div className="modalReviewHeader">
                              <Avatar src="https://bit.ly/broken-link" />
                              <div>
                                <div className="nameReviewer_in_all">
                                  {review.client_name}
                                </div>
                                <div className="dateReview_in_all">
                                  {moment(review.review_time).fromNow()}{" "}
                                  <ReactStars
                                    value={Math.round(review.review_rating)}
                                    count={5}
                                    size={18}
                                    activeColor="#ffd700"
                                    edit={false}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="test_in_all">
                              <div className="textReview_in_all">
                                {review.review}
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </ModalBody>
                </ModalContent>
              </Modal>
              <Modal isOpen={isDescOpen} onClose={onDescClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader className="Modalheader">Beskrivelse</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <div className="Subservice_Description">{description}</div>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </div>
            <div className="right">
              <div className="images_web">
                <img
                  src={"https://api-v3.bookinghero.io".concat(
                    currentSalonGal
                      ? currentSalonGal
                      : saloonInfo &&
                          (saloonInfo.image1 ||
                            saloonInfo.image2 ||
                            saloonInfo.image3 ||
                            saloonInfo.image4 ||
                            saloonInfo.image5)
                  )}
                  style={{
                    margin: "auto",
                    width: "80%",
                    height: "400px",
                    borderRadius: "20px",
                  }}
                  alt=""
                />
                <div className="subimages">
                  {saloonInfo && saloonInfo.image1 && (
                    <img
                      onClick={() => setCurrentSalonGal(saloonInfo.image1)}
                      src={"https://api-v3.bookinghero.io".concat(
                        saloonInfo.image1
                      )}
                      style={{
                        margin: "6px",
                        cursor: "pointer",
                        borderRadius: "8px",
                      }}
                      alt=""
                    />
                  )}
                  {saloonInfo && saloonInfo.image2 && (
                    <img
                      onClick={() => setCurrentSalonGal(saloonInfo.image2)}
                      src={"https://api-v3.bookinghero.io".concat(
                        saloonInfo.image2
                      )}
                      style={{
                        margin: "6px",
                        cursor: "pointer",
                        borderRadius: "8px",
                      }}
                      alt=""
                    />
                  )}
                  {saloonInfo && saloonInfo.image3 && (
                    <img
                      onClick={() => setCurrentSalonGal(saloonInfo.image3)}
                      src={"https://api-v3.bookinghero.io".concat(
                        saloonInfo.image3
                      )}
                      style={{
                        margin: "6px",
                        cursor: "pointer",
                        borderRadius: "8px",
                      }}
                      alt=""
                    />
                  )}
                  {saloonInfo && saloonInfo.image4 && (
                    <img
                      onClick={() => setCurrentSalonGal(saloonInfo.image4)}
                      src={"https://api-v3.bookinghero.io".concat(
                        saloonInfo.image4
                      )}
                      style={{
                        margin: "6px",
                        cursor: "pointer",
                        borderRadius: "8px",
                      }}
                      alt=""
                    />
                  )}
                  {saloonInfo && saloonInfo.image5 && (
                    <img
                      onClick={() => setCurrentSalonGal(saloonInfo.image5)}
                      src={"https://api-v3.bookinghero.io".concat(
                        saloonInfo.image5
                      )}
                      style={{
                        margin: "6px",
                        cursor: "pointer",
                        borderRadius: "8px",
                      }}
                      alt=""
                    />
                  )}
                </div>
              </div>
              <div className="salonInfos">
                <div>
                  <div className="title">Åbningstider</div>

                  <div>
                    {schedule &&
                      schedule.map((day) => (
                        // add className="boldy" for text bold
                        <div
                          key={day.id}
                          className={
                            day.day.toUpperCase() === today.toUpperCase()
                              ? "boldy"
                              : "day"
                          }
                        >
                          <span
                            style={{ display: "inline-block", width: "100px" }}
                          >
                            {
                              {
                                saturday: <>Lørdag</>,
                                friday: <>Fredag</>,
                                thursday: <>Torsdag</>,
                                wednesday: <>Onsdag</>,
                                tuesday: <>Tirsdag</>,
                                monday: <>Mandag</>,
                                sunday: <>Søndag</>,
                              }[day.day]
                            }{" "}
                          </span>
                          <span className="space"></span>
                          {day.offday === 1
                            ? "Lukket"
                            : `${day.start} - ${day.end}`}{" "}
                        </div>
                      ))}
                  </div>
                </div>
                <div>
                  <div className="title">Kontakt information</div>
                  <div className="contact">
                    <Icon as={FiPhone} style={{ marginRight: "7px" }} />
                    {saloonInfo && saloonInfo.saloon_phone}
                  </div>
                  <div className="contact">
                    <Icon as={AiOutlineMail} style={{ marginRight: "7px" }} />
                    {saloonInfo && saloonInfo.saloon_email}
                  </div>
                  <div className="links">
                    {saloonInfo && saloonInfo.facebook && (
                      <Link href={saloonInfo.facebook} isExternal>
                        {" "}
                        <Icon className="link" as={CiFacebook} />
                      </Link>
                    )}
                    {saloonInfo && saloonInfo.twitter && (
                      <Link href={saloonInfo.twitter} isExternal>
                        {" "}
                        <Icon className="link" as={FiTwitter} />
                      </Link>
                    )}
                    {saloonInfo && saloonInfo.instagram && (
                      <Link href={saloonInfo.instagram} isExternal>
                        <Icon className="link" as={AiOutlineInstagram} />
                      </Link>
                    )}
                    {saloonInfo && saloonInfo.tiktok && (
                      <Link href={saloonInfo.tiktok} isExternal>
                        {" "}
                        <Icon className="link" as={FaTiktok} />
                      </Link>
                    )}
                    {saloonInfo && saloonInfo.pinterest && (
                      <Link href={saloonInfo.pinterest} isExternal>
                        <Icon className="link" as={FaPinterestP} />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className="map">
                <div className="title">Placering på kortet</div>
                <div className="googlemap">
                  {!isLoaded || !isLoaded2 ? (
                    <>
                      <div>Loading...</div>
                    </>
                  ) : (
                    <>
                      {!getDirection || !userLocation ? (
                        <>
                          <GoogleMap
                            zoom={MapZoom}
                            center={{
                              lat: saloonInfo.saloon_latitude,
                              lng: saloonInfo.saloon_longtitude,
                            }}
                            mapContainerClassName="Mapcontainer"
                            options={options}
                          >
                            <MarkerF
                              position={{
                                lat: saloonInfo && saloonInfo.saloon_latitude,
                                lng: saloonInfo && saloonInfo.saloon_longtitude,
                              }}
                            />
                          </GoogleMap>
                        </>
                      ) : (
                        <>
                          <GoogleMap
                            zoom={MapZoom}
                            center={{
                              lat: saloonInfo.saloon_latitude,
                              lng: saloonInfo.saloon_longtitude,
                            }}
                            mapContainerClassName="Mapcontainer"
                            options={options}
                          >
                            <Marker
                              position={{
                                lat: saloonInfo.saloon_latitude,
                                lng: saloonInfo.saloon_longtitude,
                              }}
                            />

                            <DirectionsRenderer
                              options={{
                                directions: directions,
                              }}
                              onLoad={(directionsRenderer) => {
                                directionsRenderer.setMap(
                                  directionsRenderer.getMap()
                                );
                              }}
                            />

                            <DirectionsService
                              options={directionsServiceOptions}
                              callback={directionsCallback}
                            />
                          </GoogleMap>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div>
                  <button
                    className="btn_get_direction"
                    onClick={() => {
                      setGetDirection(true);
                    }}
                  >
                    Find vej{" "}
                    <Icon as={GrMapLocation} style={{ marginLeft: "5px" }} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}

export default SalonPage;
