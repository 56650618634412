import React, { ReactNode } from "react";
import {
  Box,
  Container,
  Stack,
  SimpleGrid,
  Text,
  Link,
  VisuallyHidden,
  chakra,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaYoutube, FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"600"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      target="_blank"
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function MarketplaceFooter() {
  return (
    <Box
      bg={useColorModeValue("gray.800", "gray.900")}
      color={useColorModeValue("white", "white")}
    >
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={8}>
          {/*    <Stack align={"flex-start"}>
            <ListHeader>Business</ListHeader>
            <Link href={"#"}>Sign up</Link>
            <Link href={"#"}>Sign in</Link>
            <Link href={"#"}>Learn More</Link>
            <Link href={"#"}>Contact Us</Link>
          </Stack>*/}

          <Stack align={"flex-start"}>
            <ListHeader>Om Bookinghero</ListHeader>
            <Link href={"/vilkar"}>Vilkar</Link>
            <Link href={"/Databehandling"}>Databehandling</Link>
          </Stack>

          <Stack align={"flex-start"}>
            <ListHeader>Produkt</ListHeader>
            <Link href={"https://influencer-program.bookinghero.io/"}>
              Influencer Program
            </Link>
            <Link href={"/om-os"}>Om os</Link>
          </Stack>

          <Stack align={"flex-start"}>
            <a
              href="https://apps.apple.com/app/bookinghero/id6453560091"
              target="blank"
              style={{
                display: "inline-block",
                overflow: "hidden",
                borderRadius: "13px",
                width: "200px",
                height: "53px",
              }}
            >
              <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/da-dk?size=250x83&amp;releaseDate=1550880000"
                alt="Download on the App Store"
                style={{ borderRadius: "13px", width: "150px", height: "53px" }}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=io.bookinghero.app"
              target="blank"
              style={{
                display: "inline-block",
                overflow: "hidden",
                borderRadius: "13px",
                width: "150px",
                height: "73px",
              }}
            >
              <img
                alt="Nu på Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/da_badge_web_generic.png"
                style={{ borderRadius: "13px", width: "150px", height: "63px" }}
              />
            </a>
          </Stack>
        </SimpleGrid>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.700")}
      >
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ md: "space-between" }}
          align={{ md: "center" }}
        >
          <Text>© 2023 Alle rettigheder forbeholdes</Text>
          <Stack direction={"row"} spacing={6}>
            <SocialButton
              label={"Linkedin"}
              href={"https://www.linkedin.com/company/bestilbooking-i-kbh-aps/"}
            >
              <FaLinkedin />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
