import React, { useEffect, useRef, useState } from "react";

import "react-pagination-js/dist/styles.css"; // import css
import MarketplaceNavbar from "../components/MarketplaceNavbar.tsx";
import MarketplaceFooter from "../components/MarketplaceFooter.tsx"; import {
  Box, Flex, Image, Text
} from "@chakra-ui/react";
import Fade from 'react-reveal/Fade';

function AboutUs() {


  useEffect(() => {

    window.scrollTo(0, 0);


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const [isHovered, setIsHovered] = useState();

  return (

    <>
      <div className="clientSupport">
        <Box position="sticky" top={0} zIndex={3}>
          <MarketplaceNavbar />
        </Box>

        <Box
          padding={{ base: "1%", md: "5%", lg: "10%" }}
          height={"100%"}
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          <Flex flexDirection={"column"} pr={{ base: "1%", md: "5%" }} pl={{ base: "1%", md: "5%" }} marginBottom={0} >

            {/* <Box position={"absolute"}  w={"100vw"} height={"40vh"} left={0} top={"0"}>
                <Box  background={"white"}  w={"100vw"} height={"90px"} >

</Box> <Box  background={"#53c7b7"}  w={"100vw"} height={"40vh"}  >

</Box>
                </Box> */}
            <Box w={{ base: "100%", md: "60%" }} zIndex={2}>

              <Text fontSize={{ base: "1.6rem", md: "3rem" }} w={{ base: "98%", md: "70vw" }} marginBottom={"4rem"} textAlign={"justify"}>Bedre selvpleje - mere velvære.</Text>
              <Box position="relative" overflow={"hidden"} w={{ base: "99%", md: "95%" }} h={{ base: "250px", md: "400px" }} borderRadius={"20px"}>

                <Image objectFit={"cover"} w={"100%"} h={"100%"} src="https://melumi.com/wp-content/uploads/2023/04/melumi-employees-new.png" />
              </Box>                </Box>

            <Box w={{ base: "100%", md: "35%", lg: "40%" }} alignSelf={"flex-end"} position={"relative"} bottom={{ base: 0, md: "180px" }}>
              <Text fontSize={"1.4rem"} marginBottom={"3rem"} marginTop={{ base: "3rem", md: 0 }} textAlign={"justify"}>Melumi vil gøre selvpleje nemt og tilgængeligt og sprede mere velvære</Text>
              <Text textAlign={"justify"}>

                Vi tror på, at ved at man passer på sig selv og behandler sig selv bedre, kan man tage sig bedre af andre. Det gælder både for dig som forretning og for dine kunder. Vi går sammen med innovative og omsorgsfulde frisører, massører, kosmetologer, neglesaloner og terapeuter, og vi ønsker at sprede velvære til så mange som muligt med de bedste digitale løsninger.

                Selvpleje bør ikke være til forhandling, men i en travl hverdag med mange ting at gøre, er det ofte det første, der bliver opgivet. Det vil Melumi gerne ændre på. Med Melumi har det aldrig været nemmere at prioritere selvpleje - både for virksomheder og brugere.

                For at kunne levere de bedste løsninger og nå ud til forretninger i hele landet,
                har Melumi i første omgang modtaget en investering på 10 millioner kroner.
              </Text>
            </Box>
          </Flex>

          <Flex justifyContent={"center"} alignItems={"center"} marginTop={{ base: "3rem", md: 0 }} position={"relative"} top={{ base: 0, md: "-60px" }} flexDirection={{ base: "column-reverse", md: "row" }}>


            <Text textAlign={{ base: "center", md: "right" }} marginRight={{ base: "auto", md: 14 }} w={{ base: "100%", md: "34%" }}>
              <Fade left duration={1500}>
                <Text fontSize={"5rem"} fontWeight={"500"} marginBottom={0}>+2000</Text>
                <Text marginBottom={"2rem"}>antal virksomheder</Text>
                <Text>Melumi (tidligere Klikbook og CBIT) har omkring +2000 virksomheder i Danmark og Norge.</Text>
              </Fade>
            </Text>
            <Box position="relative" overflow={"hidden"} w={{ base: "98%", md: "500px" }} h={{ base: "300px", md: "400px" }} borderRadius={"25px"}>

              <Image objectFit={"cover"} w={"100%"} h={"100%"} src="https://melumi.com/wp-content/uploads/2023/05/about-us-businesses.png" />
            </Box>

          </Flex>

          <Flex justifyContent={"center"} alignItems={"center"} marginTop={20} flexDirection={{ base: "column", md: "row" }} >
            <Box position="relative" overflow={"hidden"} w={{ base: "98%", md: "500px" }} h={{ base: "300px", md: "400px" }} borderRadius={"25px"}>

              <Image objectFit={"cover"} w={"100%"} h={"100%"} src="https://melumi.com/wp-content/uploads/2023/05/about-us-experience.png"
              />
            </Box>
            <Text textAlign={{ base: "center", md: "left" }} marginLeft={{ base: "auto", md: 14 }} w={{ base: "100%", md: "34%" }}>
              <Fade right duration={1500}>

                <Text fontSize={"5rem"} fontWeight={"500"} marginBottom={0}>25</Text>
                <Text marginBottom={"2rem"}>års erfaring</Text>
                <Text>Vi har over 25 års erfaring og et bredt spektrum af selvplejekategorier. Fra frisørsaloner til fodterapeuter.</Text>
              </Fade>
            </Text>
          </Flex>


          <Flex justifyContent={"center"} alignItems={"center"} marginTop={{ base: 20, md: 36 }} flexDirection={{ base: "column-reverse", md: "row" }}>
            <Text textAlign={{ base: "center", md: "right" }} marginRight={{ base: "auto", md: 14 }} w={{ base: "100%", md: "34%" }}>
              <Fade left duration={1500}>

                <Text fontSize={"5rem"} fontWeight={"500"} marginBottom={0}>145.204</Text>
                <Text marginBottom={"2rem"}>bruger downloads</Text>
                <Text>Med Melumi-appen kan eksisterende og nye brugere fra et brugerperspektiv finde alle virksomheder, der er registreret i Melumis bookingsystemer.</Text>
              </Fade>
            </Text>
            <Box position="relative" overflow={"hidden"} w={{ base: "98%", md: "500px" }} h={{ base: "300px", md: "400px" }} borderRadius={"25px"}>

              <Image objectFit={"cover"} w={"100%"} h={"100%"} src="https://melumi.com/wp-content/uploads/2023/05/about-us-app.png"
              />
            </Box>
          </Flex>


          <Box>
            <Text w={"fit-content"} margin={"auto"} fontSize={"3rem"} fontWeight={"500"} marginTop={"8rem"}>Holdet.</Text>
            <Flex flexWrap={"wrap"} w={{ base: "fit-content", lg: "1220px" }} justifyContent={"center"} margin={"auto"} >
              <Box margin={"2.5rem"} w={"fit-content"} alignItems={"center"} >

                <Box position="relative" overflow={"hidden"} w={{ base: 56, lg: 56 }} h={{ base: 56, lg: 56 }} borderRadius={"18px"} marginBottom={"14px"}>

                  <Image objectFit={"cover"} w={"100%"} h={"100%"} src="http://melumi.com/wp-content/uploads/2023/03/mikolaj-scaled.jpg"
                  />
                </Box>
                <Text>Mikolaj Baranski</Text>
                <Text fontSize={"14px"}>Lead Data Analyst</Text>
              </Box>
              <Box margin={"2.5rem"} w={"fit-content"} alignItems={"center"} >

                <Box position="relative" overflow={"hidden"} w={{ base: 56, lg: 56 }} h={{ base: 56, lg: 56 }} borderRadius={"18px"} marginBottom={"14px"}>

                  <Image objectFit={"cover"} w={"100%"} h={"100%"} src="http://melumi.com/wp-content/uploads/2023/03/mikolaj-scaled.jpg"
                  />
                </Box>
                <Text>Mikolaj Baranski</Text>
                <Text fontSize={"14px"}>Lead Data Analyst</Text>
              </Box>

              <Box margin={"2.5rem"} w={"fit-content"} alignItems={"center"} >

                <Box position="relative" overflow={"hidden"} w={{ base: 56, lg: 56 }} h={{ base: 56, lg: 56 }} borderRadius={"18px"} marginBottom={"14px"}>

                  <Image objectFit={"cover"} w={"100%"} h={"100%"} src="http://melumi.com/wp-content/uploads/2023/03/mikolaj-scaled.jpg"
                  />
                </Box>
                <Text>Mikolaj Baranski</Text>
                <Text fontSize={"14px"}>Lead Data Analyst</Text>
              </Box>

              <Box margin={"2.5rem"} w={"fit-content"} alignItems={"center"} >

                <Box position="relative" overflow={"hidden"} w={{ base: 56, lg: 56 }} h={{ base: 56, lg: 56 }} borderRadius={"18px"} marginBottom={"14px"}>

                  <Image objectFit={"cover"} w={"100%"} h={"100%"} src="http://melumi.com/wp-content/uploads/2023/03/mikolaj-scaled.jpg"
                  />
                </Box>
                <Text>Mikolaj Baranski</Text>
                <Text fontSize={"14px"}>Lead Data Analyst</Text>
              </Box>




              <Box margin={"2.5rem"} w={"fit-content"} alignItems={"center"} >

                <Box position="relative" overflow={"hidden"} w={{ base: 56, lg: 56 }} h={{ base: 56, lg: 56 }} borderRadius={"18px"} marginBottom={"14px"}>

                  <Image objectFit={"cover"} w={"100%"} h={"100%"} src="http://melumi.com/wp-content/uploads/2023/03/mikolaj-scaled.jpg"
                  />
                </Box>
                <Text>Mikolaj Baranski</Text>
                <Text fontSize={"14px"}>Lead Data Analyst</Text>
              </Box>


              <Box margin={"2.5rem"} w={"fit-content"} alignItems={"center"} >

                <Box position="relative" overflow={"hidden"} w={{ base: 56, lg: 56 }} h={{ base: 56, lg: 56 }} borderRadius={"18px"} marginBottom={"14px"}>

                  <Image objectFit={"cover"} w={"100%"} h={"100%"} src="http://melumi.com/wp-content/uploads/2023/03/mikolaj-scaled.jpg"
                  />
                </Box>
                <Text>Mikolaj Baranski</Text>
                <Text fontSize={"14px"}>Lead Data Analyst</Text>
              </Box>



              <Box margin={"2.5rem"} w={"fit-content"} alignItems={"center"} >

                <Box position="relative" overflow={"hidden"} w={{ base: 56, lg: 56 }} h={{ base: 56, lg: 56 }} borderRadius={"18px"} marginBottom={"14px"}>

                  <Image objectFit={"cover"} w={"100%"} h={"100%"} src="http://melumi.com/wp-content/uploads/2023/03/mikolaj-scaled.jpg"
                  />
                </Box>
                <Text>Mikolaj Baranski</Text>
                <Text fontSize={"14px"}>Lead Data Analyst</Text>
              </Box>


              <Box margin={"2.5rem"} w={"fit-content"} alignItems={"center"} >

                <Box position="relative" overflow={"hidden"} w={{ base: 56, lg: 56 }} h={{ base: 56, lg: 56 }} borderRadius={"18px"} marginBottom={"14px"}>

                  <Image objectFit={"cover"} w={"100%"} h={"100%"} src="http://melumi.com/wp-content/uploads/2023/03/mikolaj-scaled.jpg"
                  />
                </Box>
                <Text>Mikolaj Baranski</Text>
                <Text fontSize={"14px"}>Lead Data Analyst</Text>
              </Box>
            </Flex>

          </Box>
        </Box>

        <MarketplaceFooter />
      </div>
    </>
  );
}

export default AboutUs;
