import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import React from "react";
import "./Planpanel.css";

function Planepanel() {
  return (
    <>
      <div className="Planpanel">
        <div className="Panelheader">Choose the plan that suits you</div>
        <div className="Panelbody">
          <div className="Starterplan">
            <div className="Starterheader">Starter</div>
            <div className="Starterammount">
              <span className="bold">£00</span>/ 3 months
            </div>
            <div className="Plansteps">
              <div className="Step">
                <CheckCircleIcon color="#23A994" /> <div>Create 4 services</div>
              </div>
              <div className="Step">
                <CheckCircleIcon color="#23A994" />
                <div>Unlimited Reservations</div>
              </div>
              <div className="Step">
                <CheckCircleIcon color="#23A994" /> <div>Support</div>
              </div>
              <div className="Step">
                <WarningIcon color="#FE6B28" />
                <div>Something</div>
              </div>
              <div className="Step">
                <WarningIcon color="#FE6B28" /> <div>Something</div>
              </div>
              <div className="Step">
                <WarningIcon color="#FE6B28" /> <div>Something</div>
              </div>
            </div>
          </div>
          <div className="Upgradeplan">
            <div className="Starterheader">Gold</div>
            <div className="Starterammount">
              <span className="bold">£12.99</span>/ 1 months
            </div>
            <div className="Plansteps">
              <div className="Step">
                <CheckCircleIcon color="#23A994" />
                <div>Unlimitted services</div>
              </div>
              <div className="Step">
                <CheckCircleIcon color="#23A994" />
                <div>Unlimited Reservations</div>
              </div>
              <div className="Step">
                <CheckCircleIcon color="#23A994" /> <div>Support</div>
              </div>
              <div className="Step">
                <WarningIcon color="#FE6B28" />
                <div>Something</div>
              </div>
              <div className="Step">
                <WarningIcon color="#FE6B28" /> <div>Something</div>
              </div>
              <div className="Step">
                <WarningIcon color="#FE6B28" /> <div>Something</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Planepanel;
