import React, { useEffect, useState } from "react";
import "./Calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/da";
import axios from "axios";
import {
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useCallback } from "react";

// Handle style of calendar
const event_style_getter = (event) => {
  return {
    style: {
      backgroundColor: "#23a994",
    },
  };
};

function CalendarPage() {
  // localise which day this is to show calendar
  const localizer = momentLocalizer(moment); // or globalizeLocalizer
  // initialise empty list of events
  const [eventsList, setEventsList] = useState([]);
  const toast = useToast();
  // Load all appointments when page loads
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/appointment/confirmed");
        if (res.status === 200) {
          let updateEventsList = [];
          for (let index = 0; index < res.data.length; index++) {
            /*****************************************************/
            // get the date from the db
            const start_date = new Date(res.data[index].date);
            // get the start time from the db
            const start_time_array = res.data[index].start.split(":");
            // merge the date and the start time
            start_date.setHours(
              start_time_array[0],
              start_time_array[1],
              start_time_array[2]
            );
            /*****************************************************/
            // get the date from the db
            const end_date = new Date(res.data[index].date);
            // get the end time from the db
            const end_time_array = res.data[index].end.split(":");
            // merge the date and the end time
            end_date.setHours(
              end_time_array[0],
              end_time_array[1],
              end_time_array[2]
            );
            /*****************************************************/
            const newEvent = {
              id: res.data[index].id,
              client_name: res.data[index].clientname,
              service: res.data[index].name,
              service_price: res.data[index].price,
              staff_member: res.data[index].staffname,
              appointment_status: res.data[index].status,
              appointment_note: res.data[index].note,
              start: start_date,
              end: end_date,
              title: res.data[index].clientname + " - " + res.data[index].name,
              allDay: false,
            };
            updateEventsList.push(newEvent);
          }
          setEventsList(updateEventsList);
        }
      } catch (err) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et par minutter",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Handle open & close select event popup
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Selected event
  const [selected_event, set_selected_event] = useState(null);
  // Handle selecting an event
  const handle_select_event = useCallback(
    (event) => {
      set_selected_event(event);
      onOpen();
    },
    [onOpen]
  );

  return (
    <>
      <div className="Calendar">
        <div className="Header">Hold styr på alle dine aftaler</div>
        <div className="Calendarcontainer">
          <Calendar
            className="calendarcalendar"
            eventPropGetter={event_style_getter}
            localizer={localizer}
            events={eventsList}
            popup
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={handle_select_event}
            messages={{
              next: "Næste",
              today: "I dag",
              previous: "Tilbage",
              month: "Måned",
              week: "Uge",
              day: "Dag",
              agenda: "Dagsorden",
              noEventsInRange: "Der er ingen aftaler lige nu.",
              showMore: (total) => `+${total} Mere aftaler`,
            }}
          />
        </div>
        <div>
          <Modal onClose={onClose} size="xl" isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader style={{ fontFamily: "Poppins" }}>
                Aftale Information
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {selected_event ? (
                  <>
                    <div className="select_event_modal_container">
                      <div className="element">
                        <div className="element_label">Aftale status :</div>
                        <div className="element_content">
                          <Badge fontSize="1em" colorScheme="green">
                            {selected_event.appointment_status === "accepted" &&
                              "accepteret"}
                            {selected_event.appointment_status === "declined" &&
                              "afvist"}
                            {selected_event.appointment_status === "canceled" &&
                              "aflyst"}
                            {selected_event.appointment_status === "pending" &&
                              "afventende"}
                          </Badge>
                        </div>
                      </div>
                      <div className="element">
                        <div className="element_label">Kundenavn :</div>
                        <div className="element_content">
                          {selected_event.client_name}
                        </div>
                      </div>
                      <div className="element">
                        <div className="element_label">Service :</div>
                        <div className="element_content">
                          {selected_event.service}
                        </div>
                      </div>
                      <div className="element">
                        <div className="element_label">Dato :</div>
                        <div className="element_content">
                          {moment(selected_event.start).format(
                            "dddd, MMMM D, YYYY"
                          )}
                        </div>
                      </div>
                      <div className="element">
                        <div className="element_label">Fra :</div>
                        <div className="element_content">
                          {moment(selected_event.start).format("HH:mm")}
                        </div>
                        <div className="element_label">Til :</div>
                        <div className="element_content">
                          {moment(selected_event.end).format("HH:mm")}
                        </div>
                      </div>
                      <div className="element">
                        <div className="element_label">Pris :</div>
                        <div className="element_content">
                          {selected_event.service_price} Kr
                        </div>
                      </div>
                      <div className="element">
                        <div className="element_label">Medarbejder :</div>
                        <div className="element_content">
                          {selected_event.staff_member}
                        </div>
                      </div>
                      <div className="element" id="element_note">
                        <div className="element_label">Bemærkning :</div>
                        <div className="element_content">
                          {selected_event.appointment_note}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </ModalBody>
              <ModalFooter>
                <Button onClick={onClose}>Close</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default CalendarPage;
