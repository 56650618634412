import { Search2Icon, SearchIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Avatar,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
  Box,
  Text,
  Button,
} from "@chakra-ui/react";
import React from "react";
import "./MarketplaceHero.css";
import { useState } from "react";
import axios from "axios";
const src = process.env.PUBLIC_URL + "/assets/videos/banner.mp4";

function MarketplaceHero() {
  const [blur, set_blur] = useState(true);

  const toast = useToast();

  const [input_value, set_input_value] = useState("");

  const [showdropdown, setShowdropdown] = useState(false);

  const [finalresults, setfinalresults] = useState([
    {
      title: "Populær",
      subitems: [
        {
          id: 1,
          label: "frisør",
          logo: "",
          type: "service",
        },
        {
          id: 3,
          label: "massage",
          logo: "",
          type: "service",
        },
        {
          id: 3,
          label: "hårstyling",
          logo: "",
          type: "service",
        },
      ],
    },
  ]);

  const handle_search_suggest = async (event) => {
    setShowdropdown(true);
    set_input_value(event.target.value);

    try {
      const res = await axios.get(
        `/search/web/suggest/?search_input=${event.target.value}`
      );
      if (res.status === 200) {
        let loop_array = [];
        if (
          event.target.value === "" ||
          res.data.search_suggestions.length === 0
        ) {
          setfinalresults([
            {
              title: "Populær",
              subitems: [
                {
                  id: 1,
                  label: "frisør",
                  logo: "",
                  type: "service",
                },
                {
                  id: 3,
                  label: "massage",
                  logo: "",
                  type: "service",
                },
                {
                  id: 3,
                  label: "hårstyling",
                  logo: "",
                  type: "service",
                },
              ],
            },
          ]);
        } else {
          if (res.data.search_suggestions.services.length !== 0) {
            // loop services
            let services = [
              {
                title: "Tjenester",
                subitems: [],
              },
            ];
            for (
              let index = 0;
              index < res.data.search_suggestions.services.length;
              index++
            ) {
              services[0].subitems.push({
                id: res.data.search_suggestions.services[index].service_id,
                label: res.data.search_suggestions.services[index].service_name,
                logo: "",
                type: "service",
              });
            }
            loop_array = [...loop_array, ...services];
          }
          if (res.data.search_suggestions.external_salons.length !== 0) {
            // loop external saloons
            let external_saloons = [
              {
                title: "Venues",
                subitems: [],
              },
            ];
            for (
              let index = 0;
              index < res.data.search_suggestions.external_salons.length;
              index++
            ) {
              external_saloons[0].subitems.push({
                id: res.data.search_suggestions.external_salons[index]
                  .saloon_id,
                label:
                  res.data.search_suggestions.external_salons[index]
                    .saloon_name,
                logo: res.data.search_suggestions.external_salons[index]
                  .saloon_logo,
                slug: res.data.search_suggestions.external_salons[index]
                  .saloon_slug,
                type: "external_saloon",
              });
            }
            loop_array = [...loop_array, ...external_saloons];
          }
          if (res.data.search_suggestions.direct_salons.length !== 0) {
            // loop direct saloons
            let direct_saloons = [
              {
                title: "Direkte booking",
                subitems: [],
              },
            ];
            for (
              let index = 0;
              index < res.data.search_suggestions.direct_salons.length;
              index++
            ) {
              direct_saloons[0].subitems.push({
                id: res.data.search_suggestions.direct_salons[index].saloon_id,
                label:
                  res.data.search_suggestions.direct_salons[index].saloon_name,
                logo:
                  "https://api-v3.bookinghero.io" +
                  res.data.search_suggestions.direct_salons[index].saloon_logo,
                slug: res.data.search_suggestions.direct_salons[index]
                  .saloon_slug,
                type: "direct_saloon",
              });
            }
            loop_array = [...loop_array, ...direct_saloons];
          }
          setfinalresults(loop_array);
        }
      }
    } catch (err) {
      toast({
        title: "Server Fejl",
        description: "Prøv venligst igen om et par minutter",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  return (
    <>
      <Box className="Hero" height={{ md: "60vh", lg: "60vh", "2xl": "40vh" }}>
        <div className="Background_video"></div>
        <div className="Content">
          <Box
            fontWeight={"bolder"}
            textAlign={{ base: "left", sm: "center", xs: "center" }}
            fontSize={{ md: "45px", sm: "40px" }}
          >
            <span className="header_text">
              Næsten alle Danmarks <br /> saloner samlet et sted
            </span>
          </Box>
          <div className="Searchcontainer">
            <InputGroup size="lg" width="50rem" className="Inputgroup">
              {blur ? (
                <>
                  <Input
                    focusBorderColor="transparent"
                    pr="4.5rem"
                    type="text"
                    placeholder="Søg på service, salon eller firmanavn"
                    className="Input"
                    color={"black"}
                    _placeholder={{
                      color: "black", // Change this value to your desired color
                    }}
                    value={input_value}
                    onChange={handle_search_suggest}
                    onFocus={() => {
                      setShowdropdown(true);
                    }}
                    onBlur={() => {
                      setShowdropdown(false);
                    }}
                  />
                </>
              ) : (
                <>
                  <Input
                    focusBorderColor="transparent"
                    pr="4.5rem"
                    type="text"
                    color={"black"}
                    _placeholder={{
                      color: "black", // Change this value to your desired color
                    }}
                    placeholder="Søg på service, salon eller firmanavn"
                    className="Input"
                    value={input_value}
                    onChange={handle_search_suggest}
                    onFocus={() => {
                      setShowdropdown(true);
                    }}
                  />
                </>
              )}

              <InputRightElement width="6rem" className="Inputright">
                <Button
                  color={"white"}
                  aria-label="Search database"
                  h="3rem"
                  w="3rem"
                  className="Searchbtn"
                  as="a"
                  href={"/søg?q=" + input_value.toLocaleLowerCase()}
                >
                  Søg
                </Button>
              </InputRightElement>
            </InputGroup>
            {showdropdown ? (
              <>
                <div
                  className="Dropdown"
                  onMouseDown={() => {
                    set_blur(false);
                  }}
                >
                  {finalresults.length === 0 ? (
                    <></>
                  ) : (
                    <>
                      {finalresults.map((result) => (
                        <>
                          <div>
                            <Text
                              px={"10px"}
                              mb={"10px"}
                              mt={"10px"}
                              color={"gray.600"}
                              fontWeight={"600"}
                              fontSize={"1.2rem"}
                            >
                              {result.title}
                            </Text>
                          </div>
                          {result.subitems.map((subitem) => (
                            <>
                              {result.title === "Populær" ||
                              result.title === "Tjenester" ? (
                                <>
                                  <Box
                                    as="a"
                                    href={
                                      "/søg?q=" +
                                      subitem.label.toLocaleLowerCase()
                                    }
                                    textDecoration="none"
                                    className="Dropdownrow"
                                  >
                                    <Search2Icon className="Dropdownicon" />
                                    <Text className="Dropdowntext">
                                      {subitem.label}
                                    </Text>
                                    <ExternalLinkIcon className="selectIcon" />
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Box
                                    as="a"
                                    href={
                                      subitem.type === "direct_saloon"
                                        ? `/d/${subitem.slug}`
                                        : `/e/${subitem.slug}`
                                    }
                                    textDecoration="none"
                                    className="Dropdownrow"
                                  >
                                    <Text className="Dropdowntext">
                                      <Avatar
                                        size="xs"
                                        name={subitem.label}
                                        src={subitem.logo}
                                        className="Avatar"
                                      />
                                      {subitem.label}
                                    </Text>
                                    <ExternalLinkIcon className="selectIcon" />
                                  </Box>
                                </>
                              )}
                            </>
                          ))}
                        </>
                      ))}
                    </>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Box>
    </>
  );
}

export default MarketplaceHero;
