import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Businesssidebar.css";
import { Icon } from "@chakra-ui/icons";
import {
  MdSpaceDashboard,
  MdCalendarToday,
  MdEditCalendar,
  MdMedicalServices,
  MdPersonAddAlt1,
  MdOutlineStarHalf,
  MdCreditCard,
  MdUpgrade,
  MdSettings,
  MdOutlineLogout,
  MdQueryStats,
} from "react-icons/md";
import { Authcontext } from "./context/Authcontext";
import { useToast } from "@chakra-ui/react";

function Businesssidebar() {
  // handle naviguation between pages
  const navigate = useNavigate();

  // get logout function to log user out
  const { logoutsaloon } = useContext(Authcontext);

  // declare toast for notifications
  const toast = useToast();

  // handle logging out of dashboard
  const logout = async (e) => {
    e.preventDefault();
    try {
      const res = await logoutsaloon();
      if (res.status === 200) {
        navigate("/");
      }
    } catch (err) {
      toast({
        title: "Server Fejl",
        description: "prøv venligst igen om nogle minutter",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  return (
    <>
      <div className="Businesssidebar">
        <Link to="/booking-system/dashboard">
          <img
            src={process.env.PUBLIC_URL + "/assets/Logo/logo.png"}
            alt=""
            className="Logocontainer"
          ></img>
        </Link>
        <div className="Nav">
          <Link to="/booking-system/dashboard" className="Navitem">
            <Icon as={MdSpaceDashboard} className="Navicon" w={5} h={5} />{" "}
            Dashboard
          </Link>
          <Link to="/booking-system/calendar" className="Navitem">
            <Icon as={MdCalendarToday} className="Navicon" w={5} h={5} />{" "}
            Kalender
          </Link>
          <Link to="/booking-system/appointments" className="Navitem">
            <Icon as={MdEditCalendar} className="Navicon" w={5} h={5} /> Aftaler
          </Link>
          <Link to="/booking-system/services" className="Navitem">
            <Icon as={MdMedicalServices} className="Navicon" w={5} h={5} />{" "}
            Tjenester
          </Link>
          <Link to="/booking-system/staffs" className="Navitem">
            <Icon as={MdPersonAddAlt1} className="Navicon" w={5} h={5} />
            Personale
          </Link>
          <Link to="/booking-system/reviews" className="Navitem">
            <Icon as={MdOutlineStarHalf} className="Navicon" w={5} h={5} />{" "}
            Anmeldelser
          </Link>
          <Link to="/booking-system/statistic" className="Navitem">
            <Icon as={MdQueryStats} className="Navicon" w={5} h={5} /> Statistik
          </Link>
          <Link to="/booking-system/payment" className="Navitem isDisabled">
            <Icon as={MdCreditCard} className="Navicon" w={5} h={5} /> Betaling{" "}
            <span className="soon_tag">snart</span>
          </Link>
          <Link to="/booking-system/upgrade" className="Navitem isDisabled">
            <Icon as={MdUpgrade} className="Navicon" w={5} h={5} /> Opgrader{" "}
            <span className="soon_tag">snart</span>
          </Link>
          <Link
            to="/booking-system/settings/sec/generalinfo"
            className="Navitem"
          >
            <Icon as={MdSettings} className="Navicon" w={5} h={5} />{" "}
            Indstillinger
          </Link>
          <span className="Navitem" id="Logout" onClick={logout}>
            <Icon as={MdOutlineLogout} className="Navicon" w={5} h={5} /> Log ud
          </span>
        </div>
      </div>
    </>
  );
}

export default Businesssidebar;
