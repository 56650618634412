import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Topnav.css";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  IconButton,
  Avatar,
  Divider,
  useToast,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  Switch,
  FormLabel,
  SimpleGrid,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";
import { BiStore } from "react-icons/bi";
import { FaUserAlt, FaPhoneAlt, FaRegAddressCard } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Clientauthcontext } from "../context/Clientauthcontext";
import axios from "axios";

function Topnav(props) {
  // Client Authentication context - Client user information - Client logout function
  const { currentclient, logoutclient } = useContext(Clientauthcontext);
  // Handle route naviguation
  const navigate = useNavigate();
  // Toast notifications
  const toast = useToast();
  // Client name
  const [clientName, setClientName] = useState("");
  // Switch configuration
  const [checked, setchecked] = useState(props.is_default_checked);
  // Handle switch change
  const handlechange = () => {
    setchecked(!checked);
    if (props.is_default_checked) {
      navigate("/partner");
    } else {
      navigate("/");
    }
  };
  // Drawer configuration
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Business subscription configuration
  const {
    isOpen: is_subscription_open,
    onOpen: on_supscription_open,
    onClose: on_supscription_close,
  } = useDisclosure();
  const btnRef = React.useRef();
  // Check client connected - Redirect to home page
  useEffect(() => {
    if (currentclient) {
      const fetchData = async () => {
        try {
          const res = await axios.get("/client/");
          if (res.status === 200) {
            setClientName(res.data.name);
          }
        } catch (err) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et par minutter",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentclient]);
  // Handle client logout
  const handle_logout = async (e) => {
    e.preventDefault();
    try {
      const res = await logoutclient();
      if (res.status === 200) {
        navigate("/");
      }
    } catch (err) {
      toast({
        title: "Server Fejl",
        description: "prøv venligst igen om nogle minutter",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };
  // Subscription modal switch
  const [subscription_checked, set_subscription_checked] = useState(false);
  // Subscription modal inputs
  const [inputValues, setInputValues] = useState({
    email: "",
    name: "",
    cvr: "",
    phone: "",
  });
  // handle change of subscription modal inputs
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // handle loading animation
  const [subscription_loading, set_subscription_loading] = useState(false);
  // Handle subscription
  const handle_subscription = async (e) => {
    e.preventDefault();
    set_subscription_loading(true);
    try {
      // Check if inputs are empty
      if (
        inputValues.email === "" ||
        inputValues.name === "" ||
        inputValues.cvr === "" ||
        inputValues.phone === ""
      ) {
        toast({
          title: "Skriv fejl",
          description: "Alle felter er påkrævet",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        set_subscription_loading(false);
      } else {
        const data = {
          email: inputValues.email,
          name: inputValues.name,
          cvr: inputValues.cvr,
          phone: inputValues.phone,
        };
        const res = await axios.post("saloon/register/", data);

        if (res.status === 200) {
          set_subscription_loading(false);
          toast({
            title: "Abonnement bekræftet",
            description: "Vores supportteam vil være i kontakt",
            position: "top-right",
            isClosable: true,
            status: "success",
          });
          on_supscription_close();
          onClose();
        }
      }
      // Translate errors back end with rafik
    } catch (err) {
      if (err.response.status === 404) {
        toast({
          title: "Login fejl",
          description: err.response.data.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      set_subscription_loading(false);
    }
  };

  return (
    <>
      <div className="Topnaviguation">
        <div className="Leftside">
          <div className="Logocontainer">
            <Link to="/">
              <img
                src={process.env.PUBLIC_URL + "/assets/Logo/logo.webp"}
                alt=""
                className="Logo"
              ></img>
            </Link>
          </div>
          <div className="Switchcontaner">
            <input
              type="checkbox"
              id="Switcher"
              defaultChecked={checked}
              //onChange={handlechange}
            />
            <label for="Switcher"></label>
          </div>
        </div>
        <div className="Rightside">
          {!currentclient ? (
            <>
              <Link to="/login">
                <div className="Logincontainer">
                  <div className="Loginicon">
                    <Image
                      boxSize="30px"
                      objectFit="cover"
                      src={
                        process.env.PUBLIC_URL + "/assets/Icons/login_icon.svg"
                      }
                      alt="Dan Abramov"
                    />
                  </div>
                  <div>Log ind</div>
                </div>
              </Link>
            </>
          ) : (
            <>
              <Link to="/appointments">
                <div className="Logincontainer">
                  <div className="Loginicon">
                    <Image
                      boxSize="25px"
                      objectFit="cover"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Icons/appointment_icon.svg"
                      }
                      alt="Dan Abramov"
                    />
                  </div>
                  <div>Aftaler</div>
                </div>
              </Link>
              <Link to="/favorits">
                <div className="Logincontainer">
                  <div className="Loginicon">
                    <Image
                      boxSize="25px"
                      objectFit="cover"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Icons/favorits_icon.svg"
                      }
                      alt="Dan Abramov"
                    />
                  </div>
                  <div>Favoritter</div>
                </div>
              </Link>
            </>
          )}

          <div className="Menucontainer">
            <IconButton
              ref={btnRef}
              onClick={onOpen}
              icon={<HamburgerIcon />}
            ></IconButton>
            <Drawer
              isOpen={isOpen}
              placement="right"
              onClose={onClose}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader></DrawerHeader>

                <DrawerBody>
                  {!currentclient ? (
                    <>
                      <Link to="/login">
                        <div className="Item">
                          <div className="Loginicon">
                            <Image
                              boxSize="30px"
                              objectFit="cover"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Icons/login_icon.svg"
                              }
                              alt="Dan Abramov"
                            />
                          </div>
                          <div>Log ind</div>
                        </div>
                      </Link>
                      <div className="Item" onClick={on_supscription_open}>
                        <div className="Loginicon">
                          <Image
                            boxSize="30px"
                            objectFit="cover"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Icons/business_icon.svg"
                            }
                            alt="Dan Abramov"
                          />
                        </div>
                        <div>Tilmeld din virksomhed</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Link to="/profile" className="Profile">
                        <div className="Profile_Icon">
                          <Avatar src="https://bit.ly/broken-link" />
                        </div>
                        <div className="Profile_Name">{clientName}</div>
                      </Link>
                      <Divider />
                      <Link to="/appointments">
                        <div className="Item">
                          <div className="Loginicon">
                            <Image
                              boxSize="30px"
                              objectFit="cover"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Icons/appointment_icon.svg"
                              }
                              alt="Dan Abramov"
                            />
                          </div>
                          <div>Aftaler</div>
                        </div>
                      </Link>
                      <Link to="/favorits">
                        <div className="Item">
                          <div className="Loginicon">
                            <Image
                              boxSize="30px"
                              objectFit="cover"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Icons/favorits_icon.svg"
                              }
                              alt="Dan Abramov"
                            />
                          </div>
                          <div>Favoritter</div>
                        </div>
                      </Link>
                    </>
                  )}

                  <Link to="/support" className="Item">
                    <div className="Loginicon">
                      <Image
                        boxSize="30px"
                        objectFit="cover"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Icons/support_icon.svg"
                        }
                        alt="Dan Abramov"
                      />
                    </div>
                    <div>Support</div>
                  </Link>

                  {currentclient && (
                    <>
                      <div className="Item" onClick={handle_logout}>
                        <div className="Loginicon">
                          <Image
                            boxSize="30px"
                            objectFit="cover"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Icons/logout_icon.svg"
                            }
                            alt="Dan Abramov"
                          />
                        </div>
                        <div>Log ud</div>
                      </div>
                    </>
                  )}
                </DrawerBody>

                <DrawerFooter></DrawerFooter>
              </DrawerContent>
            </Drawer>
          </div>
        </div>
        <Modal
          onClose={on_supscription_close}
          size="xl"
          isOpen={is_subscription_open}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <div className="subscription_modal_container">
                <div className="subscription_modal_icon_container">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/Logo/logo.png"}
                    alt=""
                    className="subscription_modal_icon"
                  ></img>
                </div>
                <div className="subscription_modal_title">
                  Opret din virksomhed
                </div>
                <div className="subscription_modal_subtitle">
                  Gratis oprettelse. Ingen kreditkort.
                </div>
                <div className="subscription_modal_form">
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon as={FaUserAlt} color="gray.300" />}
                    />
                    <Input
                      variant="flushed"
                      placeholder="Dit navn"
                      name="name"
                      value={inputValues.name}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon as={MdEmail} color="gray.300" />}
                    />
                    <Input
                      name="email"
                      variant="flushed"
                      placeholder="Email"
                      value={inputValues.email}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon as={FaRegAddressCard} color="gray.300" />}
                    />
                    <Input
                      variant="flushed"
                      placeholder="CVR"
                      name="cvr"
                      value={inputValues.cvr}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon as={FaPhoneAlt} color="gray.300" />}
                    />
                    <Input
                      name="phone"
                      variant="flushed"
                      placeholder="Telefon"
                      value={inputValues.phone}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                  <FormControl
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <Switch
                      size="lg"
                      id="isChecked"
                      onChange={() => {
                        set_subscription_checked(!subscription_checked);
                      }}
                      colorScheme="teal"
                    />
                    <FormLabel
                      htmlFor="isChecked"
                      fontSize="xl"
                      style={{ alignSelf: "center" }}
                    >
                      <Link to="/vilkar" target="_blank">
                        Accepter generelle betingelser
                      </Link>
                    </FormLabel>
                  </FormControl>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              {subscription_checked ? (
                <>
                  {subscription_loading ? (
                    <>
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "12px",
                          color: "#23a994",
                        }}
                        isLoading
                        loadingText="Indsender"
                      >
                        Kom i gang
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "12px",
                          color: "#23a994",
                        }}
                        onClick={handle_subscription}
                      >
                        Kom i gang
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Button
                    style={{
                      width: "100%",
                      borderRadius: "12px",
                      color: "#23a994",
                    }}
                    disabled
                  >
                    Kom i gang
                  </Button>
                </>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
}

export default Topnav;
