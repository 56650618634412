import { DragHandleIcon, Icon } from "@chakra-ui/icons";
import { BsPerson } from "react-icons/bs";
import { FiCreditCard } from "react-icons/fi";
import React from "react";
import "./Card.css";
function Card({ holderName, ExpireDate, CardNumber }) {
  return (
    <>
      <div className="Card">
        <div className="Infosectioncontainer">
          <div className="Infosection">
            <div className="cardimg">
              <img src="/assets/visa_logo.png" alt="" />
            </div>
            <div className="cardinfo">
              <div className="cardnumber">{CardNumber}</div>
              <div className="bottomPart">
                <div className="cardholder">
                  <div className="icon">
                    <Icon as={BsPerson} w={6} h={6} />
                  </div>
                  <div>{holderName}</div>
                </div>
                <div className="cardexpiration">
                  <div>
                    <Icon className="icon" as={FiCreditCard} w={6} h={6} />
                  </div>
                  {ExpireDate}
                </div>
              </div>
            </div>
          </div>
          <div>
            <DragHandleIcon />
          </div>
        </div>
      </div>
    </>
  );
}

export default Card;
