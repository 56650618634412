import { Box } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import "./B2b.css";
import TopNavBar from "../components/B2b_components/TopNavBar";
import BusinessStarter from "../components/B2b_components/BusinessStarter";
import Stats from "../components/B2b_components/Stats";
import HowItWorks from "../components/B2b_components/HowItWorks";
import Plans from "../components/B2b_components/Plans";
import Footer from "../components/Home_components/Footer";
import { Authcontext } from "../components/context/Authcontext";
import { useNavigate } from "react-router-dom";
import Planepanel from "../components/B2b_components/Planepanel";


function B2b() {
  // handle naviguation between pages
  const navigate = useNavigate();
  // check if there is a curret user
  const { currentuser } = useContext(Authcontext);

  // redirect if there is a current user to dashboard
  useEffect(() => {
    if (currentuser) {
      navigate("/booking-system/b2b/dashboard");
    }
  }, [currentuser, navigate]);

  return (
    <>
      <div className="B2b">
        <TopNavBar />
        <Box>
          <BusinessStarter />
          <Stats />
          <HowItWorks />
          <Planepanel />
          <Plans />
          <Footer />
        </Box>
      </div>
    </>
  );
}

export default B2b;
