import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";

import Login from "./pages/Login";
import Fergotpass from "./components/Fergotpass";
import Otp from "./components/Otp";
import Newpass from "./components/Newpass";
import Business from "./pages/Business";
import Homepage from "./pages/Homepage";
import SalonPage from "./pages/SalonPage";
import Checkout from "./pages/Checkout";
import B2b from "./pages/B2b";
import Booking from "./pages/Booking";
import Vilkar from "./pages/Vilkar";
import Bookmarks from "./pages/Bookmarks";
import Datahandeling from "./pages/Datahandeling";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Profile from "./pages/Profile";
import AppointmentPage from "./pages/AppointmentPage";
import NotFound from "./pages/NotFound";
import MarketplaceLogin from "./components/MarketplaceLogin";
import MarketplaceSupport from "./pages/MarketplaceSupport";
import MarketplaceFavorits from "./pages/MarketplaceFavorites";
import AboutUs from "./pages/AboutUs";

// Cookies config
import Cookies from "js-cookie";

// Chakra ui config
import { ChakraProvider } from "@chakra-ui/react";
import customTheme from "./pages/customTheme";

// SEO config
import { HelmetProvider } from "react-helmet-async";

// Authentication and authorization configurations
import { AuthcontextProvider } from "./components/context/Authcontext";
import { ClientAuthContextProvider } from "./components/context/Clientauthcontext";

// Axios request handeling config
import axios from "axios";
import MarketplaceSearchPage from "./pages/MarketplaceSearchPage";
import MarketplaceRegister from "./components/MarketplaceRegister";
import MarketplaceExternalSalon from "./pages/MarketplaceExternalSalon";
import MarketplaceConfirmOtp from "./components/MarketplaceConfirmOtp";
import MarketplaceForgotPass from "./components/MarketplaceForgotPass";
import MarketplaceFergotPin from "./components/MarketplaceFergotPin";
import MarketplaceResetPass from "./components/MarketplaceResetPass";
import MarketplaceSecurity from "./components/MarketplaceSecurity";
axios.defaults.baseURL = "https://api-v3.bookinghero.io/api/";
//axios.defaults.baseURL = "http://localhost:5000/api/";
axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
axios.defaults.withCredentials = true;

const validCategories = [
  "akupressur",
  "søg",
  "akupunktur",
  "alternativ-medicin",
  "dyrepleje",
  "ayurveda",
  "barber",
  "skønhed",
  "akupunktur",
  "bioresonans-terapi",
  "kropsterapeuter",
  "kinesisk-medicin",
  "kiropraktik",
  "kosmetisk-tatovering",
  "kraniosakral-terapi",
  "tandpleje",
  "tandlæge",
  "kostråd",
  "hundefrisør",
  "øjenvippeforlængelse",
  "fyldstoffer",
  "frekvensterapi",
  "hårpleje",
  "frisør",
  "healing",
  "sundhed",
  "sundhedspleje",
  "hypnose",
  "injektionsbehandlinger",
  "kinesiologi",
  "laser-behandling",
  "øjenvipper",
  "makeup",
  "manicure",
  "massage",
  "neglepleje",
  "negle-forlængelse",
  "naprapati",
  "ernæringsterapi",
  "ernæringsekspert",
  "ortopædi",
  "osteopati",
  "pedicure",
  "fysioterapi",
  "piercing",
  "plastikkirurgi",
  "profylakse",
  "zoneterapi",
  "hudpleje",
  "spa",
  "spray-tan",
  "stylist",
  "tatovering",
  "taxa",
  "terapi",
  "trådning",
  "vaccination",
  "spray-tan",
  "dyrlæge",
  "wellness",
  "fillers",
];
const SearchRoute = () => {
  const { search } = useParams();

  // Check if the value exists in the validCategories array
  const categoryExists = validCategories.includes(search);

  return categoryExists ? <MarketplaceSearchPage /> : <NotFound />;
};
const ProtectedRoute = (element) => {
  if (Cookies.get("user_id") && Cookies.get("auth_token")) {
    return <Homepage />;
  } else {
    return element;
  }
};

const ReverseProtectedRoute = (element) => {
  if (Cookies.get("user_id") && Cookies.get("auth_token")) {
    return element;
  } else {
    return <Homepage />;
  }
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GoogleOAuthProvider clientId="317838096961-s50tjit42khbsarv6kdi47u3cmhsnkdn.apps.googleusercontent.com">
        <HelmetProvider>
          <AuthcontextProvider>
            <ChakraProvider>
              <Routes>
                <Route
                  exact
                  path="/booking-system/fergotpassword"
                  element={<Fergotpass />}
                />

                <Route
                  exact
                  path="/booking-system/login/fergotpassword/otp"
                  element={
                    localStorage.getItem("forgotpasssaloon") ? (
                      <Otp />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route
                  exact
                  path="/booking-system/login/fergotpassword/reset"
                  element={
                    localStorage.getItem("forgotpasssaloon") ? (
                      <Newpass />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route exact path="/booking-system/b2b" element={<B2b />} />
                <Route exact path="/booking-system/*" element={<Business />} />
                <Route exact path="/booking-system/login" element={<Login />} />
              </Routes>
            </ChakraProvider>
          </AuthcontextProvider>

          <ClientAuthContextProvider>
            <ChakraProvider theme={customTheme}>
              <Routes>
                <Route exact path="/" element={<Homepage />} />
                <Route exact path="/vilkar" element={<Vilkar />} />
                <Route exact path="/om-os" element={<AboutUs />} />
                <Route
                  exact
                  path="/Databehandling"
                  element={<Datahandeling />}
                />
                <Route
                  exact
                  path="/logind"
                  element={ProtectedRoute(<MarketplaceLogin />)}
                />
                <Route
                  exact
                  path="/logind/glemt-kodeord"
                  element={ProtectedRoute(<MarketplaceForgotPass />)}
                />
                <Route
                  exact
                  path="/logind/glemt-kodeord/otp"
                  element={ProtectedRoute(<MarketplaceFergotPin />)}
                />
                <Route
                  exact
                  path="/login/nulstille-kodeord"
                  element={ProtectedRoute(<MarketplaceResetPass />)}
                />
                <Route exact path="/:search" element={<SearchRoute />} />
                <Route
                  exact
                  path="/e/:idSaloon"
                  element={<MarketplaceExternalSalon />}
                />
                <Route
                  exact
                  path="/opret-konto"
                  element={ProtectedRoute(<MarketplaceRegister />)}
                />
                <Route
                  exact
                  path="/opret-konto/otp"
                  element={ProtectedRoute(<MarketplaceConfirmOtp />)}
                />
                <Route
                  exact
                  path="/profil"
                  element={ReverseProtectedRoute(<Profile />)}
                />
                <Route
                  exact
                  path="/beskyttelse"
                  element={ReverseProtectedRoute(<MarketplaceSecurity />)}
                />
                <Route
                  exact
                  path="/favoritter"
                  element={ReverseProtectedRoute(<MarketplaceFavorits />)}
                />
                <Route exact path="/support" element={<MarketplaceSupport />} />

                <Route exact path="/d/:idSaloon" element={<SalonPage />} />
                <Route
                  exact
                  path="/d/:idSaloon/:idSubservice"
                  element={<Booking />}
                />
                <Route exact path="/checkout" element={<Checkout />} />

                <Route
                  exact
                  path="/appointments/:idappointment"
                  element={<AppointmentPage />}
                />
              </Routes>
            </ChakraProvider>
          </ClientAuthContextProvider>
        </HelmetProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
