import { ArrowForwardIcon, CheckCircleIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { Link } from "react-scroll";
import React from "react";
import "./BusinessStarter.css";

function BusinessStarter() {
  return (
    <>
      <div className="BusinessStarter">
        <div className="Starterleft">
          <div className="Starterheader">
            <span className="Green">Bookingsystem </span> for <br></br> der
            arbejder med <br></br>
            Skønhed'-
            <br></br>Sundhed og <span className="Green">Wellness</span>
          </div>
          <div className="Startertext">
            Bestilbooking bookingsystem hjælper dig med at modtage og
            administrere dine bookinger. Det sparer tid, som du kan bruge på
            det, du er bedst til!
          </div>
          <div className="Starterbtns">
            <Button
              rightIcon={<ArrowForwardIcon />}
              variant="solid"
              className="Registerbtn"
              size="lg"
            >
              <Link to="registerform" spy={true} smooth={true}>
                Get started
              </Link>
            </Button>

            <Button variant="outline" className="Contactbtn" size="lg">
              Contact us
            </Button>
          </div>
          <div className="Starterchecks">
            <div className="Checkmark">
              <CheckCircleIcon className="Checkicon" />
              <div className="Checktxt">Online Reservations</div>
            </div>
            <div className="Checkmark">
              <CheckCircleIcon className="Checkicon" />
              <div className="Checktxt">Online Reservations</div>
            </div>
            <div className="Checkmark">
              <CheckCircleIcon className="Checkicon" />
              <div className="Checktxt">Online Reservations</div>
            </div>
          </div>
        </div>
        <div className="Starterright">
          <img
            src={process.env.PUBLIC_URL + "/assets/b2b_starter_img.png"}
            alt=""
            className="starterimg"
          ></img>
        </div>
      </div>
    </>
  );
}

export default BusinessStarter;
