import { Icon } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import React from "react";
import "./Upgrade.css";
import { MdCheckCircle } from "react-icons/md";

function Upgrade() {
  return (
    <>
      <div className="Upgrade">
        <div className="Header">Opgradere til premium</div>
        <div className="Subheader">Din startplan slutter om 25 dage</div>
        <div className="Upgradebtn">
          <Button className="Upgradebtnbtn">Opgrader nu</Button>
        </div>
        <div className="Upgradeplans">
          <div className="Starterplan">
            <div className="plan_header">Basic</div>
            <div className="plan_pricing">GRATIS</div>
            <div className="features">
              <div className="features_header">FEATURES</div>
              <div className="features_subheader">
                Vores gratis plan gir mulighed
              </div>
            </div>
            <div className="plan_points">
              <div className="point">
                <Icon
                  as={MdCheckCircle}
                  style={{
                    color: "#29ac3b",
                    width: "1.5rem",
                    height: "1.5rem",
                    background: "#fff",
                    borderRadius: "50%",
                  }}
                />
                <div style={{ color: "#fff" }}>Personlig profilside</div>
              </div>
              <div className="point">
                <Icon
                  as={MdCheckCircle}
                  style={{
                    color: "#29ac3b",
                    width: "1.5rem",
                    height: "1.5rem",
                    background: "#fff",
                    borderRadius: "50%",
                  }}
                />
                <div style={{ color: "#fff" }}>Social media links</div>
              </div>
              <div className="point">
                <Icon
                  as={MdCheckCircle}
                  style={{
                    color: "#29ac3b",
                    width: "1.5rem",
                    height: "1.5rem",
                    background: "#fff",
                    borderRadius: "50%",
                  }}
                />
                <div style={{ color: "#fff" }}>Oget eksponering</div>
              </div>
              <div className="point">
                <Icon
                  as={MdCheckCircle}
                  style={{
                    color: "#29ac3b",
                    width: "1.5rem",
                    height: "1.5rem",
                    background: "#fff",
                    borderRadius: "50%",
                  }}
                />
                <div style={{ color: "#fff" }}>Kort og GPS funktion</div>
              </div>
              <div className="point">
                <Icon
                  as={MdCheckCircle}
                  style={{
                    color: "#29ac3b",
                    width: "1.5rem",
                    height: "1.5rem",
                    background: "#fff",
                    borderRadius: "50%",
                  }}
                />
                <div style={{ color: "#fff" }}>Sogeord tags</div>
              </div>
            </div>
          </div>
          <div className="Premiumplan">
            <div className="plan_header">Basic+</div>
            <div className="plan_pricing">
              <div className="price">195</div>
              <div className="additional_info">
                <div>DKK</div>
                <div style={{ fontWeight: "bold" }}>Per maned</div>
              </div>
            </div>
            <div className="features">
              <div className="features_header">FEATURES</div>
              <div className="features_subheader">
                Premium konto med 1 maned proveperiode
              </div>
            </div>
            <div className="plan_points">
              <div className="point">
                <Icon
                  as={MdCheckCircle}
                  style={{
                    color: "#29ac3b",
                    width: "1.5rem",
                    height: "1.5rem",
                    background: "#fff",
                    borderRadius: "50%",
                  }}
                />
                <div style={{ color: "#fff" }}>inetgrete bookingsystem</div>
              </div>
              <div className="point">
                <Icon
                  as={MdCheckCircle}
                  style={{
                    color: "#29ac3b",
                    width: "1.5rem",
                    height: "1.5rem",
                    background: "#fff",
                    borderRadius: "50%",
                  }}
                />
                <div style={{ color: "#fff" }}>
                  Anouncering og oget eksponering
                </div>
              </div>
              <div className="point">
                <Icon
                  as={MdCheckCircle}
                  style={{
                    color: "#29ac3b",
                    width: "1.5rem",
                    height: "1.5rem",
                    background: "#fff",
                    borderRadius: "50%",
                  }}
                />
                <div style={{ color: "#fff" }}>
                  Direkt kundebooking pa Bookinghero
                </div>
              </div>
              <div className="point">
                <Icon
                  as={MdCheckCircle}
                  style={{
                    color: "#29ac3b",
                    width: "1.5rem",
                    height: "1.5rem",
                    background: "#fff",
                    borderRadius: "50%",
                  }}
                />
                <div style={{ color: "#fff" }}>
                  Prioreteret support og hjaelp
                </div>
              </div>
              <div className="point">
                <Icon
                  as={MdCheckCircle}
                  style={{
                    color: "#29ac3b",
                    width: "1.5rem",
                    height: "1.5rem",
                    background: "#fff",
                    borderRadius: "50%",
                  }}
                />
                <div style={{ color: "#fff" }}>
                  +AI funktionalitet fra Basic
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Upgrade;
