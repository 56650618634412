import React from "react";
import "./Payment.css";
import { AddIcon } from "@chakra-ui/icons";
import {
  Tr,
  Th,
  Tbody,
  Table,
  TableContainer,
  Thead,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import BillingHistory from "./BillingHistory";
import Card from "./Card";

function Payment() {
  // open and close popup modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  return (
    <>
      <div className="Payment">
        <div className="Header">Administrere din betalinger</div>
        <div className="cardsContainer">
          <div className="TopHeader">
            <div className="Cardheader">Kortoplysninger</div>
            <Button
              rightIcon={<AddIcon />}
              className="AddPaymentBtn"
              onClick={onOpen}
            >
              Tilføj betaling
            </Button>
          </div>
          <div className="Body">
            <Card
              holderName={"John Doe"}
              ExpireDate={"Udløbsdato: 15/8/2023"}
              CardNumber={"Visa **** 2248"}
            />
            <Card
              holderName={"John Doe"}
              ExpireDate={"Udløbsdato: 15/8/2023"}
              CardNumber={"Master **** 2248"}
            />
          </div>
        </div>
        <div className="BillingHistoryContainer">
          <div>
            <div className="Tableheader">Faktureringshistorik</div>
          </div>
          <div className="Tablecontainer">
            <hr />
            <TableContainer className="Table">
              <Table variant="simple">
                <Thead className="Thead">
                  <Tr>
                    <Th className="Th">Faktura</Th>
                    <Th className="Th">Plan</Th>
                    <Th className="Th">Beløb</Th>
                    <Th className="Th">Status</Th>
                    <Th className="Th">Handling</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <BillingHistory
                    invoice={"Premium plan dec 2022"}
                    plan={"Premium"}
                    amount={"99"}
                    status={"Betalt"}
                  />
                  <BillingHistory
                    invoice={"Premium plan nov 2022"}
                    plan={"Premium"}
                    amount={"99"}
                    status={"Betalt"}
                  />
                  <BillingHistory
                    invoice={"Basic plan okt 2022"}
                    plan={"Basic"}
                    amount={"33"}
                    status={"Betalt"}
                  />
                </Tbody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
          width={"50rem"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody pb={6} pt={10}>
              <FormControl mt={4}>
                <FormLabel>Kort ejer</FormLabel>
                <Input variant="filled" ref={initialRef} />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Kortnummer</FormLabel>
                <Input variant="filled" ref={initialRef} />
              </FormControl>
              <FormControl className="doubleInput" display={"flex"} mt={4}>
                <div>
                  <FormLabel mr={2}>Udløbsdato</FormLabel>
                  <Input variant="filled" placeholder="MM/YY" mr={2} />
                </div>
                <div>
                  <FormLabel ml={2}>CVV</FormLabel>
                  <Input variant="filled" placeholder="***" ml={2} />
                </div>
              </FormControl>
            </ModalBody>

            <ModalFooter pb={8}>
              <Button className="Modelbtn" width={"100%"}>
                Tilføj kort
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
}

export default Payment;
