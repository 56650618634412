import { ChevronLeftIcon, ChevronRightIcon, Icon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  IconButton,
  Image,
  Radio,
  RadioGroup,
  Box,
  useRadio,
  useDisclosure,
  // 485
  useToast,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import "./Booking.css";
import { useState } from "react";
import axios from "axios";
import { Stepper, Step } from "react-progress-stepper";
import { da } from "date-fns/locale";
import "animate.css";
import Footer from "../components/Home_components/Footer";
import { Clientauthcontext } from "../components/context/Clientauthcontext";
import { FaUserCheck } from "react-icons/fa";
import { Helmet } from "react-helmet-async";
function Booking() {
  // Client Authentication context - Client user information
  const { currentclient } = useContext(Clientauthcontext);
  // Link parameters
  const { idSaloon } = useParams();
  const { idSubservice } = useParams();
  // R.R.D naviguation - Link previous page
  const naviguate = useNavigate();
  // Toast notifications
  const toast = useToast();
  // Staff member selected - Select default on page load
  const [value, setValue] = React.useState();
  // Time slot selected
  const [slot, setSlot] = React.useState("");
  // Todays date - To disable all previous dates - Monthly calendar - Weekly calendar
  const [thisDay, setThisDay] = useState(new Date());
  // Selected date - Monthly calendar
  const [selected, setSelected] = useState(new Date());
  // Last week - Weekly calendar
  const [lastweek, setLastweek] = useState(new Date());
  // Next week - Weekly calendar
  const [nextweek, setNextweek] = useState(new Date());
  // Next 7 days - Weekly calendar
  const [dayslist, setDayslist] = useState([]);
  // Switch month week view
  const [week, setWeek] = useState(false);
  // Set last week indicator - Set next week indicator - Set next seven days - On Weekly calendar - On page load
  useEffect(() => {
    // Scroll page to the top
    window.scrollTo(0, 0);

    var list = [];
    var previous = new Date(selected.getTime() - 7 * 24 * 60 * 60 * 1000);
    var next = new Date(selected.getTime() + 7 * 24 * 60 * 60 * 1000);
    //previous.setDate(selected.getDate() - 7);
    //next.setDate(selected.getDate() + 7);
    setLastweek(previous);
    setNextweek(next);
    for (let index = 0; index <= 6; index++) {
      var item = new Date(
        selected.getFullYear(),
        selected.getMonth(),
        selected.getDate() + index
      );
      list.push(new Date(item));
    }
    setDayslist(list);
  }, [selected]);
  function RadioCaard(props) {
    const { getInputProps, getRadioProps } = useRadio(props);

    const inputs = getInputProps();
    const checkbox = getRadioProps();

    return (
      <Box as="label">
        <input {...inputs} />
        <Box
          {...checkbox}
          cursor="pointer"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="md"
          _checked={{
            bg: "teal.600",
            color: "white",
            borderColor: "teal.600",
          }}
          _focus={{
            boxShadow: "outline",
          }}
          px={5}
          py={3}
        >
          {props.children}
        </Box>
      </Box>
    );
  }
  // Staff list responsibel for subservice
  const [staffs, setStaffs] = useState([]);
  // Subservice information
  const [subservice, setSubservice] = useState();
  // Saloon general information
  const [salooninfo, setSalooninfo] = useState();
  // Loading data animation - Page load
  const [loading, setLoading] = useState(true);
  // Disabled days
  const [offday, setOffday] = useState([]);
  // Work days
  const [workDays, setWorkDays] = useState([]);
  // Taken slots
  const [Taken, setTaken] = useState([]);

  //
  const [selectedItem, setSelectedItem] = useState(-1);
  // Load data - Page load
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `/appointment/booking/${idSaloon}/${idSubservice}`
        );
        if (res.status === 200) {
          setStaffs(res.data.staffs);
          setValue(res.data.staffs[0].staff_id.toString());
          setSubservice(res.data.subservice_info);
          setSalooninfo(res.data.saloon_info);
          // loop through schedule and get off days and start end time of each day
          var counter = 0;
          var offdays = [];
          var work_days = [];
          for (let index = 6; index >= 0; index--) {
            // Off days
            if (res.data.saloon_schedule[index].offday === 1) {
              offdays.push(counter);
            } else {
              // Work days
              var start = res.data.saloon_schedule[index].start;
              var end = res.data.saloon_schedule[index].end;
              var slots = res.data.saloon_schedule[index].time_slots;
              work_days.push({ counter, start, end, slots });
            }
            counter++;
          }
          // console.log(work_days);
          setOffday(offdays);
          setWorkDays(work_days);
          setLoading(false);
        }
      } catch (err) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et par minutter",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    };
    // Scroll page to the top
    window.scrollTo(0, 0);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set disabled days - React Day Picker
  const disabledDays = (date) => {
    const day = date.getDay();
    return offday.includes(day);
  };

  // Verify if the time slot on the day X is taken or not
  const isTimeSlotTaken = (day, Time_Slot) => {
    // Loop over each time slot in the array
    for (let t of Taken) {
      // Extract the date from the time slot and create a new date object
      const dateParts = t.date.split("-");
      const tyear = dateParts[0];
      const tmonth = dateParts[1] - 1;
      const tday = dateParts[2];
      const takenDate = new Date(tyear, tmonth, tday);
      // Create a new date object from the passed-in day
      const dateInReal = new Date(day);

      // Check if the date of the time slot matches the passed-in date
      if (
        takenDate.getFullYear() === dateInReal.getFullYear() &&
        takenDate.getMonth() === dateInReal.getMonth() &&
        takenDate.getDate() === dateInReal.getDate()
      ) {
        // Convert the start, end, and time slot times to minutes past midnight
        const startMinutes =
          parseInt(t.start.split(":")[0]) * 60 +
          parseInt(t.start.split(":")[1]);
        const endMinutes =
          parseInt(t.end.split(":")[0]) * 60 + parseInt(t.end.split(":")[1]);
        const timeSlotMinutes =
          parseInt(Time_Slot.split(":")[0]) * 60 +
          parseInt(Time_Slot.split(":")[1]);

        // Check if the time slot falls within the range of start and end times
        if (timeSlotMinutes >= startMinutes && timeSlotMinutes < endMinutes) {
          // Return true if the time slot is taken
          return true;
        }
      }
    }

    // Return false if the time slot is not taken
    return false;
  };

  // Load taken time slots - On staff member selected
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/appointment/taken/${value}`);
        if (res.status === 200) {
          setTaken(res.data);
        }
      } catch (err) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et par minutter",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    };
    // Scroll page to the top
    window.scrollTo(0, 0);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  // Handle checkout button click - If not logged in prompt to log in - If logged in naviguate to checkout
  const handleCheckoutBtn = () => {
    if (currentclient) {
      // client is logged in
      const dateWithoutOffset = slot.replace(/([-+]\d{2}):(\d{2})$/, "");
      const date = new Date(dateWithoutOffset);

      // Get the day of the week (as a string)
      const dayOfWeek = date.toLocaleDateString("da-DA", { weekday: "long" });

      // Get the month (as a string)
      const month = date.toLocaleDateString("da-DA", { month: "long" });

      // Get the day of the month (as a number)
      const dayOfMonth = date.getDate();

      // Get the year (as a number)
      const year = date.getFullYear();

      // Assemble the formatted string
      const formattedDate = `${dayOfWeek} ${month} ${dayOfMonth}-${year} kl ${slot.slice(
        slot.length - 5,
        slot.length
      )}`;

      naviguate("/checkout", {
        state: {
          subserviceId: subservice.subservice_id,
          saloonId: salooninfo.saloon_id,
          Timeslot: formattedDate,
          date: date,
          start: slot.slice(slot.length - 5, slot.length),
          staffId: value,
        },
      });
    } else {
      // no client logged in
      onOpen();
    }
  };

  return (
    <>
      <Helmet>
        <title>Book en skønhedsbehandling med Bookinghero</title>
        <meta
          name="description"
          content="Book din næste skønheds- eller velværebehandling med Bookinghero. Vælg en dag og tid, der passer dig, og nyd en luksuriøs behandling."
        />
        <link rel="canonical" href="/places/:idSaloon/:idSubservice" />
      </Helmet>
      <div className="Booking">
        {loading ? (
          <>
            <div className="Loading_animation">
              <img
                src={process.env.PUBLIC_URL + "/assets/Logo/logo_shape.png"}
                alt=""
                className="Loading_logo animate__animated animate__pulse animate__infinite"
              ></img>
            </div>
          </>
        ) : (
          <>
            <div className="Booking_Container">
              <div className="Head_Logocontainer">
                <Link to="/">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/Logo/logo.png"}
                    alt=""
                    className="HeadLogo"
                  ></img>
                </Link>
              </div>
              <div className="Headercontainer">
                <div className="Previous">
                  <IconButton
                    className="Previousbtn"
                    icon={<ChevronLeftIcon className="Previousicon" />}
                    onClick={() => {
                      naviguate(-1);
                    }}
                  />
                </div>
                <div className="Header">
                  <div className="Topheader">
                    {salooninfo && salooninfo.saloon_name} -{" "}
                    {subservice && subservice.subservice_name}
                  </div>
                  <div className="Subheader">
                    {subservice.subservice_time} min -{" "}
                    {subservice.subservice_price} Kr
                  </div>
                </div>
              </div>
              <div className="Bodycontainer">
                <div className="Calendarcontainer">
                  <div className="Calendarheader">Vælg en dato </div>
                  <Divider />
                  <div className="Booking_Stepper">
                    <Stepper
                      step={1}
                      theme={{
                        light: {
                          step: {
                            pending: {
                              background: "#ededed",
                              color: "black",
                            },
                            progress: {
                              background: "#ededed",
                              color: "black",
                            },
                            completed: {
                              background: "#23a994",
                              color: "#ffffff",
                            },
                          },
                          progressBar: {
                            pending: {
                              background: "#ededed",
                            },
                            progress: {
                              background: "#ededed",
                              fill: "#ededed",
                            },
                            completed: {
                              background: "#ededed",
                              fill: "#23a994",
                            },
                          },
                        },
                      }}
                    >
                      <Step></Step>
                      <Step></Step>
                      <Step></Step>
                    </Stepper>
                  </div>
                  <div className="Calendarbody">
                    {!week ? (
                      <>
                        <Button
                          colorScheme="teal"
                          variant="link"
                          className="Viewchanger"
                          onClick={() => {
                            setWeek(true);
                          }}
                        >
                          Ugevisning
                        </Button>
                        <DayPicker
                          weekStartsOn={1}
                          mode="single"
                          selected={selected}
                          onSelect={(date) => {
                            if (!date) {
                              setWeek(true);
                            } else {
                              setWeek(true);
                              setSelected(date);
                            }
                          }}
                          disabled={[{ before: thisDay }, disabledDays]}
                          locale={da}
                        />
                      </>
                    ) : (
                      <>
                        <Button
                          colorScheme="teal"
                          variant="link"
                          className="Viewchanger"
                          onClick={() => {
                            setWeek(false);
                          }}
                        >
                          Månedsvisning
                        </Button>
                        <div className="Weekcalendar">
                          <div className="Weekheader">
                            {selected.valueOf() <= thisDay.valueOf() ? (
                              <>
                                <div className="Leftheader">
                                  <Button
                                    leftIcon={
                                      <ChevronLeftIcon className="Btnicon" />
                                    }
                                    colorScheme="teal"
                                    variant="link"
                                    className="Prevbtn"
                                    disabled
                                  >
                                    Tidligere
                                  </Button>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="Leftheader">
                                  <Button
                                    leftIcon={
                                      <ChevronLeftIcon className="Btnicon" />
                                    }
                                    colorScheme="teal"
                                    variant="link"
                                    className="Prevbtn"
                                    onClick={() => {
                                      setSelected(lastweek);
                                    }}
                                  >
                                    Tidligere
                                  </Button>
                                </div>
                              </>
                            )}

                            <div className="Middleheader">
                              {selected.getDate()}{" "}
                              {selected.toLocaleString("da-DA", {
                                month: "long",
                              })}{" "}
                              - {nextweek.getDate() - 1}{" "}
                              {nextweek.toLocaleString("da-DA", {
                                month: "long",
                              })}
                            </div>
                            <div className="Rightheader">
                              <Button
                                rightIcon={
                                  <ChevronRightIcon className="Btnicon" />
                                }
                                colorScheme="teal"
                                variant="link"
                                className="Nextbtn"
                                onClick={() => {
                                  setSelected(nextweek);
                                }}
                              >
                                Senere
                              </Button>
                            </div>
                          </div>
                          <RadioGroup onChange={setSlot} value={slot}>
                            <div className="Weekbody">
                              {dayslist.map((day, index) => (
                                <>
                                  {offday.includes(day.getDay()) ||
                                  day.getMonth() < thisDay.getMonth() ||
                                  (day.getDate() < thisDay.getDate() &&
                                    day.getMonth() === thisDay.getMonth()) ? (
                                    <>
                                      <div className="Day">
                                        <div className="Topday" id="Offday">
                                          <div className="Dayname">
                                            <span className="notmobile">
                                              {index === 0 ? (
                                                <>I dag</>
                                              ) : (
                                                <>
                                                  {day.toLocaleString("da-DA", {
                                                    weekday: "long",
                                                  })}
                                                </>
                                              )}
                                            </span>
                                            <span className="mobile">
                                              {index === 0 ? (
                                                <>I dag</>
                                              ) : (
                                                <>
                                                  {day.toLocaleString("da-DA", {
                                                    weekday: "short",
                                                  })}
                                                </>
                                              )}
                                            </span>
                                          </div>
                                          <div className="Daynumber">
                                            {day.getDate()}
                                          </div>
                                        </div>
                                        <div
                                          className="Bottomday"
                                          id="Offday"
                                        ></div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="Day">
                                        <div className="Topday">
                                          <span className="notmobile">
                                            <div className="Dayname">
                                              {index === 0 ? (
                                                <>I dag</>
                                              ) : (
                                                <>
                                                  {day.toLocaleString("da-DA", {
                                                    weekday: "long",
                                                  })}
                                                </>
                                              )}
                                            </div>
                                          </span>
                                          <span className="mobile">
                                            <div className="Dayname">
                                              {index === 0 ? (
                                                <>I dag</>
                                              ) : (
                                                <>
                                                  {day.toLocaleString("da-DA", {
                                                    weekday: "short",
                                                  })}
                                                </>
                                              )}
                                            </div>
                                          </span>
                                          <div className="Daynumber">
                                            {day.getDate()}
                                          </div>
                                        </div>
                                        <div className="Bottomday">
                                          {workDays[day.getDay()].slots.map(
                                            (Time_Slot, index2) => (
                                              <>
                                                <div
                                                  className={
                                                    selectedItem ==
                                                    `${index2}${day.getDate()}${day.getMonth()}${day.getFullYear()}`
                                                      ? "TimeslotActive"
                                                      : "Timeslot"
                                                  }
                                                  colorScheme="teal"
                                                  key={index2}
                                                >
                                                  <Radio
                                                    style={{ opacity: 0 }}
                                                    value={
                                                      day + "-" + Time_Slot
                                                    }
                                                    isDisabled={isTimeSlotTaken(
                                                      day,
                                                      Time_Slot
                                                    )}
                                                    onChange={() =>
                                                      setSelectedItem(
                                                        `${index2}${day.getDate()}${day.getMonth()}${day.getFullYear()}`
                                                      )
                                                    }
                                                  >
                                                    <span className="text_timeslot">
                                                      {Time_Slot}
                                                    </span>
                                                  </Radio>
                                                </div>
                                              </>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </div>
                          </RadioGroup>
                        </div>
                      </>
                    )}
                    {slot === "" ? (
                      <>
                        <div className="Calendar_Bottom">
                          <Button className="Checkout" size="md" disabled>
                            Gå til kassen
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="Calendar_Bottom">
                          <Button
                            className="Checkout"
                            size="md"
                            onClick={handleCheckoutBtn}
                          >
                            Gå til kassen
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="Staffcontainer">
                  <div className="Staffheader">Vælg medarbejder</div>
                  <Divider />
                  <div className="Stafflist">
                    <RadioGroup onChange={setValue} value={value}>
                      {staffs.map((staff) => (
                        <div className="Staff" key={staff.staff_id}>
                          <div className="Checkstaff">
                            <Radio
                              className="Radiobtn"
                              size="lg"
                              value={staff.staff_id.toString()}
                              colorScheme="teal"
                              name={staff.staff_name}
                            ></Radio>
                          </div>
                          <div className="Staffimage">
                            <Image
                              className="Image"
                              src={
                                "https://api-v3.bookinghero.io" +
                                staff.staff_image
                              }
                              fallbackSrc="https://via.placeholder.com/150"
                            />
                          </div>
                          <div className="Staffinfo">
                            <div className="Name">{staff.staff_name}</div>
                            <div className="Occupation">
                              {staff.staff_occupation}
                            </div>
                          </div>
                        </div>
                      ))}
                    </RadioGroup>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <Footer />
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div className="booking_popup_icon">
              <Icon as={FaUserCheck} />
            </div>
            <div className="booking_popup_title">
              Log ind for at fuldføre reservationen
            </div>
            <div className="booking_popup_text">
              Ombook, annuller og gem favoritter
            </div>
            <Button
              background={"#23a994"}
              color={"white"}
              borderRadius={"20px"}
              mr={3}
              onClick={() => naviguate("/login")}
            >
              Log ind for at fuldfør booking
            </Button>
            <div className="booking_popup_footer">
              Har du ikke en konto? Oprette gratis en korto her
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Booking;
