import React, { useEffect } from "react";
import "./Homepage.css";
import { Helmet } from "react-helmet-async";

import { Box } from "@chakra-ui/react";
import MarketplaceNavbar from "../components/MarketplaceNavbar.tsx";
import MarketplaceFooter from "../components/MarketplaceFooter.tsx";
import MarketplaceCategories from "../components/MarketplaceCategories.jsx";
import MarketplaceHero from "../components/MarketplaceHero.jsx";
import MarketplaceNearbySalons from "../components/MarketplaceNearbySalons.tsx";

function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Bookinghero - Book din næste skønheds</title>
        <meta
          name="description"
          content=" Bookinghero er din en-stop-shop for at booke tider hos de bedste skønheds- og velvære virksomheder. Book din næste frisør-, negle-, eller skønhedsbehandling online i dag."
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <div className="Homepage">
        <Box position="sticky" top={0} zIndex={3}>
          <MarketplaceNavbar />
        </Box>
        <div className="Herosection">
          <MarketplaceHero />
        </div>
        <div className="Categoriessection">
          <MarketplaceCategories />
        </div>
        <MarketplaceNearbySalons />
        <MarketplaceFooter />
      </div>
    </>
  );
}

export default Homepage;
