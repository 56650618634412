import React, { useRef, useState } from "react";
import "./Profile.css";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { useEffect } from "react";
import axios from "axios";
import MarketplaceNavbar from "../components/MarketplaceNavbar.tsx";
import MarketplaceFooter from "../components/MarketplaceFooter.tsx";

function Profile() {
  // loading when clicking on the button save changes
  const [isLoading, setisLoading] = useState(false);

  // contains the client infos returned from the DB
  const [clientData, SetClientData] = useState();

  // references to inputs fields
  const newClientName = useRef(null);
  const newClientCity = useRef(null);
  const newClientAdress = useRef(null);
  const newClientPhone = useRef(null);

  //to enbale save button if there is any input change
  //else it will be disabled per default
  const [isChange, setIsChange] = useState(false);

  // to call useeffect on evry update of infos
  const [changedClient, setChangedClient] = useState(0);

  // Handle between page navigation
  const navigate = useNavigate();

  //to show error or success popups
  const toast = useToast();

  // Getting init client infos from DB

  useEffect(() => {
    setIsChange(false);

    const fetchData = async () => {
      try {
        const res = await axios.get(`/client/profile-info`);
        if (res.status === 200) {
          // Fill client data
          SetClientData(res.data);
        }
      } catch (err) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et par minutter",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    };
    window.scrollTo(0, 0);
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedClient]);

  // Upadte client infos
  const updtaeClient = async (e) => {
    e.preventDefault();
    setisLoading(true);
    try {
      const newClientInfos = {
        name: newClientName.current.value,
        adress: newClientAdress.current.value
          ? newClientAdress.current.value
          : null,
        city: newClientCity.current.value ? newClientCity.current.value : null,
        phone: newClientPhone.current.value
          ? newClientPhone.current.value
          : null,
      };
      const res = await axios.put(`/client/profile-info`, newClientInfos);
      if (res.status === 200) {
        setChangedClient((changedClient) => changedClient + 1);
        toast({
          title: "Success",
          description: "Infos updated succesfully",
          position: "top-right",
          isClosable: true,
          status: "success",
        });
        setisLoading(false);
      }
    } catch (err) {
      toast({
        title: "Server Fejl",
        description: "Prøv venligst igen om et par minutter",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };
  return (
    <>
      <div className="clientProfile">
        <Box position="sticky" top={0} zIndex={3}>
          <MarketplaceNavbar />
        </Box>
        <div className="profileContainer">
          <div className="previousContainer">
            <div className="Previous">
              <IconButton
                className="previousBtn"
                icon={<ChevronLeftIcon className="previousIcon" />}
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <div className="Header">Rediger profil</div>
          </div>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              disabled
              variant="filled"
              size="lg"
              value={clientData && clientData.client_email}
            />
            <FormHelperText>
              Hvis du ønsker at ændre din e-mail, kontakt venligst support.
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel>Navn</FormLabel>
            <Input
              type="text"
              variant="filled"
              size="lg"
              ref={newClientName}
              defaultValue={clientData && clientData.client_name}
              onChange={() => setIsChange(true)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Adresse</FormLabel>
            <Input
              type="text"
              variant="filled"
              size="lg"
              ref={newClientAdress}
              defaultValue={clientData && clientData.adress}
              onChange={() => setIsChange(true)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>By</FormLabel>
            <Input
              type="text"
              variant="filled"
              size="lg"
              ref={newClientCity}
              defaultValue={clientData && clientData.city}
              onChange={() => setIsChange(true)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Telefon nummer</FormLabel>
            <InputGroup size="lg">
              <InputLeftAddon children="+45" />
              <Input
                type="numebr"
                variant="filled"
                size="lg"
                ref={newClientPhone}
                defaultValue={clientData && clientData.phone}
                onChange={() => setIsChange(true)}
              />
            </InputGroup>
          </FormControl>
          {isLoading ? (
            <Button
              className="submitBtn"
              size="lg"
              isLoading
              disabled
              loadingText="Indlæser"
            >
              Gem ændringer
            </Button>
          ) : (
            <Button
              className="submitBtn"
              size="lg"
              disabled={!isChange}
              onClick={updtaeClient}
            >
              Gem ændringer
            </Button>
          )}
        </div>
        <MarketplaceFooter />
      </div>
    </>
  );
}

export default Profile;
