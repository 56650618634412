import "./Checkout.css";
import React, { useContext, useEffect } from "react";
import Footer from "../components/Home_components/Footer";
import TopNavBar from "../components/Home_components/Topnav";
import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Image,
  Input,
  Textarea,
  Icon,
  Toast,
  useToast,
} from "@chakra-ui/react";
import {
  CalendarIcon,
  CheckCircleIcon,
  ChevronLeftIcon,
  EmailIcon,
  ExternalLinkIcon,
  PhoneIcon,
} from "@chakra-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { Stepper, Step } from "react-progress-stepper";
import ReactStars from "react-rating-stars-component";
import { GoLocation } from "react-icons/go";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { Clientauthcontext } from "../components/context/Clientauthcontext";
import { useState } from "react";
import axios from "axios";
import moment from "moment/moment.js";

function Checkout() {
  const [saloon, setSaloon] = useState();
  const [client, setClient] = useState();
  const [staff, setStaff] = useState();
  const [subService, setSubService] = useState();
  // Form inputs
  const [inputs, setInputs] = useState({
    fullname: "",
    number: "",
    note: "",
  });
  // Handle input value change
  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  // Handle toast notifications
  const toast = useToast();
  // Confirm Animation - Loading button
  const [isLoading, setisLoading] = useState(false);
  // Client Authentication context - Client user information
  const { currentclient } = useContext(Clientauthcontext);
  // Handle state between routes
  const location = useLocation();
  // Subservice id
  const [subserviceId, setSubserviceId] = useState(
    location.state ? location.state.subserviceId : ""
  );
  // Saloon id
  const [saloonId, setSaloonId] = useState(
    location.state ? location.state.saloonId : ""
  );
  // Staff id
  const [staffId, setStaffId] = useState(
    location.state ? location.state.staffId : ""
  );
  // TimeSlot
  const [Timeslot, setTimeslot] = useState(
    location.state ? location.state.Timeslot : ""
  );
  const [formatDate, setFormatDate] = useState();
  // R.R.D naviguation - Link previous page
  const naviguate = useNavigate();
  // roll page to top - On page load
  useEffect(() => {
    if (!location.state || !currentclient) {
      naviguate("/");
    } else {
      window.scrollTo(0, 0);
      const fetchData = async () => {
        try {
          const res = await axios.get(
            `/appointment/checkout/${saloonId}/${subserviceId}/${staffId}`
          );
          if (res.status === 200) {
            setClient(res.data.client_info);
            setSaloon(res.data.saloon_info);
            setSubService(res.data.subs_info);
            setStaff(res.data.staff_info);

            setInputs({
              ...inputs,
              number: res.data.client_info.client_phone,
              fullname: res.data.client_info.client_name,
            });
          }
        } catch (err) {
          Toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et par minutter",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      };
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentclient, naviguate]);
  // Terms & conditions - Open new tab
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  // Handle making appointment
  const confirmAppointment = async (e) => {
    e.preventDefault();
    setisLoading(true);
    const end_time = moment(location.state.start, "HH:mm").add(
      subService.subservice_time,
      "minutes"
    );
    const data = {
      start: location.state.start,
      end: end_time.format("HH:mm"),
      date: moment(location.state.date).format("YYYY-MM-DD"),
      subserviceid: subserviceId,
      saloonid: saloonId,
      staffid: location.state.staffId,
      client_name: inputs.fullname,
      contact_number: inputs.number,
      note: inputs.note,
    };

    try {
      // Check if inputs are empty
      if (inputs.name === "" || inputs.number === "") {
        toast({
          title: "Skriv fejl",
          description: "Du mangler at udfylde et område",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        setisLoading(false);
      } else {
        // Call to api
        const res = await axios.post("appointment/", data);

        if (res.status === 200) {
          naviguate("/");
          setisLoading(false);
          toast({
            title: "Appointment booked",
            description: "Please check your appointments",
            position: "top-right",
            isClosable: true,
            status: "success",
          });
        }
      }
    } catch (err) {
      if (err.status === 404) {
        toast({
          title: "Login fejl",
          description: err.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      setisLoading(false);
    }
  };

  return (
    <>
      <TopNavBar is_default_checked={true} />

      <div className="checkout">
        <div className="toppart">
          <div className="Previous">
            <IconButton
              className="Previousbtn"
              icon={<ChevronLeftIcon className="Previousicon" />}
              onClick={() => {
                naviguate(-1);
              }}
            />
          </div>
          <div className="Header">
            <div className="Topheader">
              {saloon && saloon.saloon_name} -{" "}
              {subService && subService.subservice_name}
            </div>
            <div className="Subheader">
              {subService && subService.subservice_time} min -{" "}
              {subService && subService.subservice_price} Kr
            </div>
          </div>
        </div>
        <div className="Bottom_Part">
          <div className="Stepper">
            <Stepper
              step={2}
              theme={{
                light: {
                  step: {
                    pending: {
                      background: "#ededed",
                      color: "black",
                    },
                    progress: {
                      background: "#ededed",
                      color: "black",
                    },
                    completed: {
                      background: "#23a994",
                      color: "#ffffff",
                    },
                  },
                  progressBar: {
                    pending: {
                      background: "#ededed",
                    },
                    progress: {
                      background: "#ededed",
                      fill: "#ededed",
                    },
                    completed: {
                      background: "#ededed",
                      fill: "#23a994",
                    },
                  },
                },
              }}
            >
              <Step></Step>
              <Step></Step>
              <Step></Step>
            </Stepper>
          </div>
          <div className="Bottom_Container">
            <div className="Left_Container">
              <div className="Header">Fuldfør booking</div>
              <FormControl isRequired>
                <FormLabel>Email address</FormLabel>
                <Input
                  variant="filled"
                  width="80%"
                  type="email"
                  value={client && client.client_email}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Fulde navn</FormLabel>
                <Input
                  name="fullname"
                  variant="filled"
                  width="80%"
                  type="text"
                  size="lg"
                  onChange={handleChange}
                  defaultValue={client && client.client_name}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Telefonnummer</FormLabel>
                <Input
                  name="number"
                  variant="filled"
                  width="80%"
                  type="number"
                  size="lg"
                  onChange={handleChange}
                  defaultValue={client && client.client_phone}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Bemærkning</FormLabel>
                <Textarea
                  variant="filled"
                  width="80%"
                  type="text"
                  size="lg"
                  name="note"
                  onChange={handleChange}
                />
              </FormControl>
              {!isLoading ? (
                <>
                  <Button
                    className="Submit_Button"
                    width="80%"
                    size="lg"
                    onClick={confirmAppointment}
                  >
                    Udføre booking
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="Submit_Button"
                    width="80%"
                    size="lg"
                    isLoading
                    loadingText="Indlæser"
                  >
                    Udføre booking
                  </Button>
                </>
              )}
              <p className="Terms_Conditions">
                Ved at klikke på "Udføre booking" accepterer du vores{" "}
                <span className="Link" onClick={() => openInNewTab("/vilkar")}>
                  {" "}
                  vilkår og betingelser <ExternalLinkIcon />
                </span>{" "}
              </p>
            </div>
            <div className="Right_Container">
              <div className="Head_Container">
                <Image
                  boxSize="100px"
                  objectFit="cover"
                  borderRadius="12px"
                  src={
                    saloon &&
                    "https://api-v3.bookinghero.io" + saloon.saloon_logo
                  }
                  fallbackSrc="https://via.placeholder.com/150"
                  alt="Dan Abramov"
                />
                <div className="Head_Info">
                  <div className="Saloon_Name">
                    <div>{saloon && saloon.saloon_name}</div>{" "}
                    {saloon && (
                      <>
                        <ReactStars
                          value={Math.round(saloon.saloon_rating)}
                          count={5}
                          size={23}
                          activeColor="#ffd700"
                          edit={false}
                          className="stars"
                        />
                      </>
                    )}
                  </div>
                  <div className="Saloon_Location">
                    <Icon as={GoLocation} className="Icon" />
                    <div>
                      {saloon && saloon.saloon_adress} -{" "}
                      {saloon && saloon.saloon_city}
                    </div>
                  </div>
                </div>
              </div>
              <div className="Contact_Container">
                <div className="Header">Kontakt information</div>
                <div className="Item">
                  <PhoneIcon className="Icon" />
                  <div>{saloon && saloon.saloon_phone}</div>
                </div>
                <div className="Item">
                  <EmailIcon className="Icon" />
                  <div>{saloon && saloon.saloon_email}</div>
                </div>
              </div>
              <div className="Contact_Container">
                <div className="Item">
                  <CalendarIcon className="Icon" />
                  <div>{Timeslot}</div>
                </div>
                <div className="Item">
                  <CheckCircleIcon className="Icon" />
                  <div>
                    {staff && staff.staff_name},{" "}
                    {staff && staff.staff_occupation}
                  </div>
                </div>
              </div>
              <div className="Contact_Container">
                <div className="Header">
                  Total : {subService && subService.subservice_price}Kr
                </div>
                <div className="Item">
                  <Icon as={FaMoneyCheckAlt} className="Icon" />
                  <div className="betaling">Betalingsmetode : </div>
                  <div className="Sub_Text">
                    <span className="betaling">Betal hos virksomhed</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Checkout;
