import React, { useContext, useEffect, useState } from "react";
import Loginright from "./Loginright";
import "./Otp.css";
import {
  PinInput,
  PinInputField,
  HStack,
  Button,
  useToast,
} from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Authcontext } from "./context/Authcontext";

function Otp() {
  // call loginsaloon function from state and return response
  // get current user to check if saloon is already connected or not
  const { currentuser } = useContext(Authcontext);

  // handle animation for loading requests
  const [isLoading, setisLoading] = useState(false);

  // handle naviguation between pages
  const navigate = useNavigate();

  // declare toast for notifications
  const toast = useToast();

  // get the pin code that is sent with the state
  const location = useLocation();

  // hide the pin code to verify it
  const pin = location.state?.pin;

  // store data from inputs
  const [input, setInput] = useState(0);

  // redirect if there is a current user to dashboard
  useEffect(() => {
    if (currentuser) {
      navigate("/booking-system/dashboard");
    }
  }, [currentuser, navigate]);

  // handle submitting the form
  const handlesubmit = async (e) => {
    e.preventDefault();
    setisLoading(true);
    if (input === 0) {
      toast({
        title: "Skriv fejl",
        description: "Pin nummer er tomt",
        position: "top-right",
        isClosable: true,
        status: "warning",
      });
      setisLoading(false);
    } else {
      if (input === pin) {
        navigate("/booking-system/login/fergotpassword/reset");
        setisLoading(false);
      } else {
        toast({
          title: "Skriv fejl",
          description: "Pin nummer er forkert",
          position: "top-right",
          isClosable: true,
          status: "warning",
        });
        setisLoading(false);
      }
    }
  };

  return (
    <>
      <div className="Otp">
        <div className="Otpcontainer">
          <Link to="/">
            <img
              src={process.env.PUBLIC_URL + "/assets/Logo/logo.png"}
              alt=""
              className="Logocontainer"
            ></img>
          </Link>
          <div className="Header">Indsæt Verifikations Kode</div>
          <div className="Subheader">
            Vi har sendt dig et hemmeligt pin nummer til din e-mail.
          </div>
          <HStack className="Pininput">
            <PinInput
              size="lg"
              onChange={(value) => {
                setInput(value);
              }}
            >
              <PinInputField className="pin" />
              <PinInputField className="pin" />
              <PinInputField className="pin" />
              <PinInputField className="pin" />
            </PinInput>
          </HStack>

          {!isLoading ? (
            <>
              <Button className="Submitbtn" onClick={handlesubmit}>
                Indsend
              </Button>
            </>
          ) : (
            <>
              <Button className="Submitbtn" isLoading loadingText="Indlæser">
                Indsend
              </Button>
            </>
          )}
        </div>
        <Loginright className="Loginright" />
      </div>
    </>
  );
}

export default Otp;
