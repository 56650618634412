import React from "react";
import { MdDevices } from "react-icons/md";
import { FiCalendar } from "react-icons/fi";
import { BsCheckCircle } from "react-icons/bs";
import { TbArrowBearRight } from "react-icons/tb";
import "./HowItWorks.css";

function HowItWorks() {
  return (
    <>
      <div className="HowItWorks">
        <div className="title">How it works</div>
        <div className="items">
          <div className="item">
            <div className="number">
              <MdDevices />
            </div>
            <div className="sub_title">Step 1</div>
            <div className="paragraph">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores,
              similique! Nobis.
            </div>
          </div>
          <div className="item">
            <TbArrowBearRight className="itemAsIcon" />
          </div>
          <div className="item">
            <div className="number">
              <FiCalendar />
            </div>
            <div className="sub_title">Step 2</div>
            <div className="paragraph">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores,
              similique! Nobis.
            </div>
          </div>
          <div className="item">
            <TbArrowBearRight className="itemAsIcon" />
          </div>
          <div className="item">
            <div className="number">
              <BsCheckCircle />
            </div>
            <div className="sub_title">Step 3</div>
            <div className="paragraph">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores,
              similique! Nobis.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowItWorks;
