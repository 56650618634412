import React, { useContext, useEffect, useState } from "react";
import "./Loginleft.css";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { Authcontext } from "./context/Authcontext";

function Loginleft() {
  // call loginsaloon function from state and return response
  // get current user to check if saloon is already connected or not
  const { currentuser, loginsaloon } = useContext(Authcontext);

  // handle animation for loading requests
  const [isLoading, setisLoading] = useState(false);

  // handle naviguation between pages
  const navigate = useNavigate();

  // declare toast for notifications
  const toast = useToast();

  // set show and hide password
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  // store data from inputs
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  // handle getting the data from the inputs
  const handlechange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // handle submitting the form
  const handlesubmit = async (e) => {
    e.preventDefault();
    setisLoading(true);
    try {
      if (inputs.email === "" || inputs.password === "") {
        toast({
          title: "Skriv fejl",
          description: "E-mail- eller adgangskodefeltet er tomt",
          position: "top-right",
          isClosable: true,
          status: "warning",
        });
        setisLoading(false);
      } else {
        const res = await loginsaloon(inputs);

        if (res.status === 200) {
          navigate("/booking-system/dashboard");
          setisLoading(false);
        }
      }
    } catch (err) {
      if (err.status === 404 || err.status === 400) {
        toast({
          title: "Login fejl",
          description: err.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      setisLoading(false);
    }
  };

  // redirect if there is a current user to dashboard
  useEffect(() => {
    if (currentuser) {
      navigate("/booking-system/dashboard");
    }
  }, [currentuser, navigate]);

  return (
    <>
      <div className="Loginleft">
        <Link to="/">
          <img
            src="../assets/Logo/logo.png"
            alt=""
            className="Logocontainer"
          ></img>
        </Link>
        <div className="Header">Velkommen tilbage !</div>
        <div className="Subheader">
          Indtast venligst dine oplysninger for at logge ind.
        </div>
        <form>
          <div className="Label">E-mail</div>
          <Input
            name="email"
            required
            variant="filled"
            placeholder="din e-mail@gmail.dk"
            size="lg"
            className="Emailinput"
            type="email"
            onChange={handlechange}
          />
          <div className="Passlabel">Adgangskode</div>
          <InputGroup className="Passinput">
            <Input
              name="password"
              required
              type={show ? "text" : "password"}
              placeholder="Indtast adgangskode"
              variant="filled"
              className="Password"
              onChange={handlechange}
            />
            <InputRightElement width="4.5rem">
              <Button
                h="1.75rem"
                size="sm"
                onClick={handleClick}
                className="ShowHide"
              >
                {show ? "Skjul" : "Vise"}
              </Button>
            </InputRightElement>
          </InputGroup>
          <div className="Fergotpass">
            <Link to="/booking-system/fergotpassword">
              Har du glemt adgangskoden ?
            </Link>
          </div>
          {!isLoading ? (
            <>
              <Button className="Submitbtn" onClick={handlesubmit}>
                Log ind
              </Button>
            </>
          ) : (
            <>
              <Button className="Submitbtn" isLoading loadingText="Indlæser">
                Log ind
              </Button>
            </>
          )}
        </form>
      </div>
    </>
  );
}

export default Loginleft;
