import React, { useContext, useEffect, useRef } from "react";
import "./AppointmentPage.css";
import Footer from "../components/Home_components/Footer";
import TopNavBar from "../components/Home_components/Topnav";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactStars from "react-rating-stars-component";

import {
  Avatar,
  Button,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  CheckCircleIcon,
  ChevronLeftIcon,
  DeleteIcon,
  EmailIcon,
  PhoneIcon,
  RepeatClockIcon,
} from "@chakra-ui/icons";
import { useState } from "react";
import { Clientauthcontext } from "../components/context/Clientauthcontext";
import axios from "axios";
import "animate.css";
import moment from "moment/moment";

function AppointmentPage() {
  // Client Authentication context - Client user information
  const { currentclient } = useContext(Clientauthcontext);
  // Toast notifications
  const toast = useToast();
  // R.R.D naviguation - Link previous page
  const naviguate = useNavigate();
  // Data loading Animation - On page load
  const [isDataLoading, setIsDataLoading] = useState(true);
  // Appointment ID - Link params
  const { idappointment } = useParams();
  // Appointment page data
  const [Data, setData] = useState();
  // Handle setting appointment & saloon & subservice & staff data - On page load
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!currentclient) {
      naviguate("/");
    } else {
      const fetchData = async () => {
        setIsDataLoading(true);
        try {
          const res = await axios.get("/appointment/list/" + idappointment);
          if (res.status === 200) {
            setData(res.data);
            setIsDataLoading(false);
          }
        } catch (err) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et par minutter",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Subservice description modal
  const {
    isOpen: isDescOpen,
    onOpen: onDescOpen,
    onClose: onDescClose,
  } = useDisclosure();

  // Add Review modal
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  // Subservice description
  const [subDescription, setSubDescription] = useState("");
  // Handle canceling appointment
  const handleCancelAppointment = async () => {
    try {
      const res = await axios.put(`/appointment/cancel/${Data.appointment_id}`);
      if (res.status === 200) {
        toast({
          title: "Aflyst",
          description: "Tjek venligst dine aftaler",
          position: "top-right",
          isClosable: true,
          status: "success",
        });
        naviguate(-1);
      }
    } catch (err) {
      toast({
        title: "Server Fejl",
        description: "Prøv venligst igen om et par minutter",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  // Create a reference to the comment input field
  const commentRef = useRef();

  // Initialize a state variable named nb_stars with a default value of 0
  const [nb_stars, setNb_starts] = useState(0);

  // Define a callback function that will be called when the user selects a rating
  const ratingChanged = (newRating) => {
    // Log the new rating to the console
    console.log(newRating);
    // Update the nb_stars state variable with the new rating
    setNb_starts(newRating);
  };

  // Define an asynchronous function that will be called when the user submits their review
  const submitReview = async () => {
    try {
      // If the nb_stars state variable is 0, return without submitting the review
      if (!nb_stars) return;

      // Send a POST request to the server with the review data using axios
      const res = await axios.post("/review", {
        saloonid: Data.saloon_id,
        review: commentRef.current.value,
        rating: nb_stars,
      });

      // If the request is successful, call the onAddClose() function and display a success toast notification
      if (res.status === 200) {
        onAddClose();
        toast({
          title: "Modtaget",
          description: "Din anmeldelse er modtaget",
          position: "top-right",
          isClosable: true,
          status: "success",
        });
      }
    } catch {
      // If an error occurs, display an error toast notification
      toast({
        title: "Server Fejl",
        description: "Prøv venligst igen om et par minutter",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  return (
    <>
      <TopNavBar is_default_checked={true} />
      <div className="AppointmentPage">
        {isDataLoading ? (
          <>
            <div className="Loading_animation">
              <img
                src={process.env.PUBLIC_URL + "/assets/Logo/logo_shape.png"}
                alt=""
                className="Loading_logo animate__animated animate__pulse animate__infinite"
              ></img>
            </div>
          </>
        ) : (
          <>
            <div className="toppart">
              <div className="Previous">
                <IconButton
                  className="Previousbtn"
                  icon={<ChevronLeftIcon className="Previousicon" />}
                  onClick={() => {
                    naviguate(-1);
                  }}
                />
              </div>
              <div className="Header">Mine aftaler</div>
            </div>
            <div className="bottomContainer">
              <div className="cardContainer">
                <div className="Main">
                  <div className="Status">{Data.appointment_status}</div>
                  <div className="appointmentInfo">
                    <Image
                      boxSize="30px"
                      objectFit="cover"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Icons/appointment_icon.svg"
                      }
                      alt="Dan Abramov"
                    />
                    <div className="Info">
                      <div className="Time">
                        {moment(Data.appointment_date, "YYYY-MM-DD")
                          .format("dddd")
                          .toUpperCase()
                          .slice(0, 1) +
                          moment(Data.appointment_date, "YYYY-MM-DD")
                            .format("dddd")
                            .slice(1)}
                        ,{" "}
                        {moment(Data.appointment_date, "YYYY-MM-DD").format(
                          "D"
                        )}{" "}
                        {moment(Data.appointment_date, "YYYY-MM-DD").format(
                          "MMMM"
                        )}{" "}
                        - {Data.appointment_start}
                      </div>
                      <div className="Adress">
                        {Data.saloon_adress}, {Data.saloon_city}
                      </div>
                    </div>
                  </div>
                  <div className="subserviceInfo">
                    <CheckCircleIcon className="checkIcon" />
                    <div className="Info">
                      <div className="Name">{Data.subservice_name}</div>
                      <div className="subInfo">
                        {Data.subservice_time} min - {Data.subservice_price} kr
                        ·{" "}
                        <span
                          className="moreInfo"
                          onClick={() => {
                            setSubDescription(Data.subservice_description);
                            onDescOpen();
                          }}
                        >
                          mere info
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="staffInfo">
                    <CheckCircleIcon className="checkIcon" />
                    <Avatar
                      fallbackSrc="https://bit.ly/broken-link"
                      src={"https://api-v3.bookinghero.io" + Data.staff_image}
                    />
                    <div className="Name">
                      {Data.staff_name} - {Data.staff_occupation}
                    </div>
                  </div>
                  <div className="paymentContainer">
                    <strong>Betaling :</strong> Betal hos virksomhed{" "}
                  </div>
                  <div className="totalContainer">
                    <strong>Total :</strong> {Data.subservice_price} kr{" "}
                  </div>
                </div>
                {Data.appointment_status === "accepted" ||
                Data.appointment_status === "pending" ? (
                  <>
                    <Button
                      leftIcon={<DeleteIcon boxSize="25px" />}
                      backgroundColor="#23a994"
                      color="white"
                      size="md"
                      onClick={handleCancelAppointment}
                    >
                      Annuller aftale
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      leftIcon={<RepeatClockIcon boxSize="25px" />}
                      backgroundColor="#23a994"
                      color="white"
                      size="md"
                      onClick={() => {
                        naviguate("/search/".concat(Data.saloon_slug));
                      }}
                    >
                      Book igen
                    </Button>
                    <div className="writeReview" onClick={() => onAddOpen()}>
                      Skriv anmeldelse
                    </div>
                  </>
                )}
              </div>
              <div className="saloonInfo">
                <Image
                  boxSize="200px"
                  objectFit="cover"
                  src={"https://api-v3.bookinghero.io" + Data.saloon_logo}
                  fallbackSrc="https://via.placeholder.com/200"
                  alt="Dan Abramov"
                />
                <Link to={"/search/".concat(Data.saloon_slug)}>
                  <div className="saloonName">{Data.saloon_name}</div>
                </Link>
                <div className="saloonInformation">
                  <div className="Header">Kontakt information</div>
                  <div className="Phone">
                    <PhoneIcon /> {Data.saloon_phone}
                  </div>
                  <div className="Email">
                    <EmailIcon />
                    {Data.saloon_email}
                  </div>
                </div>
              </div>
            </div>
            <Modal isOpen={isDescOpen} onClose={onDescClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader className="Modalheader">Beskrivelse</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <div className="Subservice_Description">{subDescription}</div>
                </ModalBody>
              </ModalContent>
            </Modal>
            <Modal isOpen={isAddOpen} onClose={onAddClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader className="Modalheader">Beskrivelse</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {/* <div>Overall rating</div> */}
                  <div className="stars_review">
                    <ReactStars
                      count={5}
                      onChange={ratingChanged}
                      size={40}
                      activeColor="#ffd700"
                    />
                  </div>
                  <Textarea
                    ref={commentRef}
                    variant="filled"
                    name="review_text"
                    h={40}
                    mt={4}
                  ></Textarea>
                  <div className="Subservice_Description">{subDescription}</div>
                  <Button
                    className="submit_review_btn"
                    background={"#23a994"}
                    onClick={submitReview}
                  >
                    Send anmeldelse
                  </Button>
                </ModalBody>
              </ModalContent>
            </Modal>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default AppointmentPage;
