import { ChevronDownIcon, HamburgerIcon, Icon } from "@chakra-ui/icons";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import {
  MdLogin,
  MdInfoOutline,
  MdBusinessCenter,
  MdOutlineSupportAgent,
} from "react-icons/md";
import { Link } from "react-router-dom";
import "./TopNavBar.css";

function TopNavBar() {
  // open and close the drawer
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  return (
    <>
      <div className="Mobiletopnav">
        <div className="Drawercontainer">
          <IconButton
            ref={btnRef}
            icon={<HamburgerIcon />}
            onClick={onOpen}
            className="Drawerbtn"
          >
            Open
          </IconButton>
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerBody>
                <div className="Nav">
                  <div className="Navitem">
                    <Icon as={MdLogin} className="Navicon" w={5} h={5} />
                    Sign in
                  </div>
                  <div className="Navitem">
                    <Icon as={MdInfoOutline} className="Navicon" w={5} h={5} />
                    About Bestilbooking
                  </div>
                  <div className="Navitem">
                    <Icon
                      as={MdBusinessCenter}
                      className="Navicon"
                      w={5}
                      h={5}
                    />
                    Connect your business
                  </div>
                  <div className="Navitem">
                    <Icon
                      as={MdOutlineSupportAgent}
                      className="Navicon"
                      w={5}
                      h={5}
                    />
                    Contact us
                  </div>
                </div>
              </DrawerBody>

              <DrawerFooter className="Drawerfooter">
                <Menu>
                  <MenuButton>
                    Eng <ChevronDownIcon />
                  </MenuButton>

                  <MenuList>
                    <MenuItem>Dk</MenuItem>
                    <MenuItem>Fr</MenuItem>
                  </MenuList>
                </Menu>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </div>
        <div className="Mobilecontainer">
          <img
            src={process.env.PUBLIC_URL + "/assets/Logo/logo.png"}
            alt=""
            className="Logo"
          ></img>
        </div>
      </div>
      <div className="topNavbar">
        <div className="Navbarleft">
          <Link to="/">
            <img
              src={process.env.PUBLIC_URL + "/assets/Logo/logo.png"}
              alt=""
              className="Logo"
            ></img>
          </Link>
        </div>
        <div className="Navbarmiddle">
          <div className="Navlink">Forside</div>
          <div className="Navlink">Om Bestilbooking</div>
          <div className="Navlink">Oprette din virksomhed</div>
        </div>
        <div className="Navbarright">
          <Link to="/booking-system/login">
            <Button className="Signupbtn" variant="outline">
              Login
            </Button>
          </Link>
          <Menu>
            <MenuButton>
              DK <ChevronDownIcon />
            </MenuButton>
            <Portal>
              <MenuList>
                <MenuItem>English</MenuItem>
                <MenuItem>French</MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </div>
      </div>
    </>
  );
}

export default TopNavBar;
