import React from "react";
import Businesssidebar from "../components/Businesssidebar";
import Dashboard from "../components/Dashboard";
import Appointments from "../components/Appointment";
import "./Business.css";
import { Route, Routes } from "react-router-dom";
import BusinesssidebarMobile from "../components/BusinesssidebarMobile";
import Services from "../components/Services";
import Staffs from "../components/Staffs";
import Reviews from "../components/Reviews";
import Settings from "../components/Settings";
import Payment from "../components/Payment";
import CalendarPage from "../components/CalendarPage";
import Upgrade from "../components/Upgrade";
import Statistic from "../components/Statistic";

function Business() {
  return (
    <>
      <div className="Business">
        <div className="BusinesssidebarMobile">
          <BusinesssidebarMobile className="Mobilesidebar" />
        </div>
        <div className="Businesssidebar">
          <Businesssidebar />
        </div>
        <div className="Businessright">
          <Routes>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="appointments" element={<Appointments />} />
            <Route path="services" element={<Services />} />
            <Route path="staffs" element={<Staffs />} />
            <Route path="reviews" element={<Reviews />} />
            <Route path="payment" element={<Payment />} />
            <Route exact path="settings/sec/*" element={<Settings />} />
            <Route path="calendar" element={<CalendarPage />} />
            <Route path="upgrade" element={<Upgrade />} />
            <Route path="statistic" element={<Statistic />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default Business;
