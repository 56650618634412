import { Divider } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import "./Settingsleftbar.css";

function Settingsleftbar() {
  return (
    <>
      <div className="Settingsleftbar">
        <Link className="Settingslink" to="generalinfo">
          Generel information
        </Link>
        <Link className="Settingslink" to="contactinfo">
          Kontakt information
        </Link>
        <Link className="Settingslink" to="album">
          Billeder
        </Link>
        <Link className="Settingslink" to="workschedule">
          Arbejdsskema
        </Link>
        <Link className="Settingslink" to="location">
          Beliggenhed
        </Link>
        <Link className="Settingslink" to="security">
          Sikkerhed
        </Link>
        <Link className="Settingslink" to="support">
          Support
        </Link>
        <Divider className="Settingsdevider" />
        <Link to="cancel" className="Settingscancel">
          luk konto
        </Link>
      </div>
    </>
  );
}

export default Settingsleftbar;
