import React from "react";
import "./MarketplaceExternalSalon.css";
import MarketplaceNavbar from "../components/MarketplaceNavbar.tsx";
import MarketplaceFooter from "../components/MarketplaceFooter.tsx";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Tag,
  Text,
  Tooltip,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import {
  BsHeart,
  BsStar,
  BsStarFill,
  BsStarHalf,
  BsTelephoneFill,
} from "react-icons/bs";
import { MapContainer, Marker } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import {
  MdIosShare,
  MdOutlineAlternateEmail,
  MdOutlineMyLocation,
} from "react-icons/md";
import { useEffect } from "react";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { useState } from "react";
import axios from "axios";
import L from "leaflet";
import { useParams } from "react-router-dom";

const CustomIcon = ({ rating, isHovered, showAnimation }) => {
  const leftPosition = Number.isInteger(rating) ? "10px" : "6px";
  const leftPosition2 = Number.isInteger(rating) ? "14px" : "9px";
  const jumpAnimationClass = showAnimation ? "" : "jump-animation";
  const fontSize = "10px";
  return L.divIcon({
    className: "custom-icon",
    html: `
      <div  style="position: relative; width=40px; height=40px;">
      
      ${
        rating
          ? !isHovered
            ? `<i class="fa-sharp fa-solid fa-location-pin fa-2xl" style="color: #019362; font-size:36px; ">
          ${`<span style="position:absolute; top:5px; left:${leftPosition};margin:auto; color: white;  font-weight: 600; font-size: ${fontSize};">${rating} </span>`}
          </i>
          `
            : `<i class="${jumpAnimationClass} fa-sharp fa-solid fa-location-pin fa-2xl" style=" color: #001642; font-size:45px; position:relative; left:-3px;top:-3px; ">
            ${`<span style="position:absolute;top:-8px; left:${leftPosition2};margin:auto; color: white;  font-weight: 600; font-size: ${fontSize};">${rating} </span>`}
            <i class="fa-solid fa-star fa-fade" style="position:relative;top:-8px; left:12px;color: #ffffff; font-size:10px;"></i>
            </i>`
          : !isHovered
          ? `<i class="fa-sharp fa-solid fa-location-dot fa-2xl" style="color: #019362; font-size:36px; ">
          
            </i>`
          : `<i class="${jumpAnimationClass} fa-sharp fa-solid fa-location-dot fa-2xl" style=" color: #001642; font-size:45px; position:relative; left:-3px;top:-3px; ">
             
              </i>`
      }
   
      </div>
    `,
  });
};

const DanishDays = {
  Monday: "Mandag",
  Tuesday: "Tirsdag",
  Wednesday: "Onsdag",
  Thursday: "Torsdag",
  Friday: "Fredag",
  Saturday: "Lørdag",
  Sunday: "Søndag",
};

function MarketplaceExternalSalon() {
  const toast = useToast();

  const { idSaloon } = useParams();

  const isSmallScreen = useBreakpointValue({ base: true, lg: false });

  const [auth, set_auth] = useState(false);

  const [refresh_counter, set_refresh_counter] = useState(0);

  const [isScrolled, setIsScrolled] = useState(false);

  const getDecryptedCookie = () => {
    const encryptedValue = Cookies.get("auth_token");
    const decryptedValue = decryptCookieValue(encryptedValue); // Replace with your decryption logic
    return decryptedValue;
  };

  const decryptCookieValue = (encryptedValue) => {
    const secretKey = "bookinghero";
    const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
    const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedValue;
  };

  useEffect(() => {
    if (Cookies.get("user_id") && Cookies.get("auth_token")) {
      const auth_token = getDecryptedCookie();
      const client_id = Cookies.get("user_id");
      if (auth_token === client_id) {
        set_auth(true);
      } else {
        set_auth(false);
      }
    } else {
      set_auth(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh_counter]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [is_bookmarked, set_is_bookmarked] = useState(true);

  const [saloon_details, set_saloon_details] = useState();

  const [saloon_schedule, set_saloon_schedule] = useState();

  const [services_display_count, set_services_display_count] = useState(5);

  const [staffs_display_count, set_staffs_display_count] = useState(5);

  const [all_services, set_all_services] = useState(5);

  const [all_staffs, set_all_staffs] = useState(5);

  const [saloon_services, set_saloon_services] = useState([]);

  const [saloon_staffs, set_saloon_staffs] = useState([]);

  const [is_data_loading, set_is_data_loading] = useState(true);

  const [hoveredMarker, setHoveredMarker] = useState(null);

  const handleMarkerHover = (markerId) => {
    setHoveredMarker(markerId);
  };

  const handleMarkerMouseLeave = () => {
    setHoveredMarker(null);
  };

  const handleShowMoreServices = () => {
    set_services_display_count(all_services);
  };

  const handleShowLessServices = () => {
    set_services_display_count(5);
  };

  const handleShowMoreStaffs = () => {
    set_staffs_display_count(all_services);
  };

  const handleShowLessStaffs = () => {
    set_staffs_display_count(5);
  };

  const [showAnimation, setShowAnimation] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`saloon/web/external-slug/${idSaloon}`);

        if (res.status === 200) {
          set_is_bookmarked(res.data.is_bookmarked);
          set_saloon_details(res.data.saloon_data.saloon_details);
          set_saloon_schedule(
            Object.entries(res.data.saloon_data.saloon_schedule)
          );
          set_saloon_services(res.data.saloon_data.saloon_services);
          set_all_services(res.data.saloon_data.saloon_services.length);
          set_saloon_staffs(res.data.saloon_data.saloon_staffs);
          set_all_staffs(res.data.saloon_data.saloon_staffs.length);
          set_is_data_loading(false);
        }
      } catch (err) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    };
    fetchData();
  }, [all_services]);

  const handle_bookmark = async (e, saloon_id) => {
    e.preventDefault();
    try {
      const Data = {
        saloon_id: saloon_id,
      };
      const res = await axios.post("bookmarks/web/external/", Data);

      if (res.status === 200) {
        toast({
          title: "Info",
          description: "Saloon er blevet tilføjet til favoritter",
          position: "top-right",
          isClosable: true,
          status: "success",
        });
      }
    } catch (err) {
      toast({
        title: "Server Fejl",
        description: "Prøv venligst igen om et øjeblik",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  const encryptCookieValue = (valueToEncrypt) => {
    const secretKey = "bookinghero"; // Replace with your secret key used for encryption

    const encryptedValue = CryptoJS.AES.encrypt(
      valueToEncrypt,
      secretKey
    ).toString();
    return encryptedValue;
  };

  const [userLocation, setUserLocation] = useState(null);

  // Get position of visitor - On page load
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
    console.log(userLocation);
  };

  const handleGetDirection = (saloonlt, saloonlg) => {
    const acceptCookies = Cookies.get("accept_cookies");
    if (acceptCookies == "true") {
      if (Cookies.get("blt")) {
        setUserLocation({
          lat: decryptCookieValue(Cookies.get("blt")),
          lng: decryptCookieValue(Cookies.get("blg")),
        });
      } else {
        getLocation();
        const cookieOptions = {
          sameSite: "strict", // Set the SameSite attribute to 'Strict'
        };
        Cookies.set(
          "blt",
          encryptCookieValue(userLocation.lat.toString()),
          cookieOptions
        );
        Cookies.set(
          "blg",
          encryptCookieValue(userLocation.lng.toString()),
          cookieOptions
        );
      }
    } else {
      getLocation();
    }

    const googleMapsUrl = `https://www.google.com/maps/dir/${userLocation.lat.toString()},${userLocation.lng.toString()}/${saloonlt},${saloonlg}`;

    // Open the URL in a new window or tab
    window.open(googleMapsUrl, "_blank");
  };

  const handleBookNow = async (saloon_details) => {
    let ip = "unknown";
    let lt = "unknown";
    let lg = "unknown";
    let city = "unknown";
    let country = "unknown";

    const date = new Date();

    // Get individual date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Create the formatted date string
    const time = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    const acceptCookies = Cookies.get("accept_cookies");
    if (acceptCookies == "true") {
      if (Cookies.get("bip")) {
        ip = decryptCookieValue(Cookies.get("bip"));
      }
      if (Cookies.get("blt")) {
        lt = decryptCookieValue(Cookies.get("blt"));
        lg = decryptCookieValue(Cookies.get("blg"));
      }

      if (Cookies.get("bcity")) {
        city = decryptCookieValue(Cookies.get("bcity"));
      }
      if (Cookies.get("bcountry")) {
        country = decryptCookieValue(Cookies.get("bcountry"));
      }
    }
    const history = {
      ip: ip,
      city: city,
      country: country,
      time: time,
      latitude: lt,
      longtitude: lg,
    };
    try {
      const res = await axios.post(
        `/saloon/web/monotorise/${saloon_details.saloon_id}`,
        history
      );
      if (res["status"] === 200) {
        console.log("success");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint according to your design.

  return !is_data_loading ? (
    <>
      <Helmet>
        <title>Bookinghero - Book din næste skønheds</title>
        <meta
          name="description"
          content=" Bookinghero er din en-stop-shop for at booke tider hos de bedste skønheds- og velvære virksomheder. Book din næste frisør-, negle-, eller skønhedsbehandling online i dag."
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <div>
        <Box position="sticky" top={0} zIndex={4}>
          <MarketplaceNavbar />
        </Box>
        <Box marginTop={"20px"} paddingX={"3%"} height={"fit-content"}>
          <Flex marginLeft={"2px"} gap={"5px"} marginBottom={"15px"}>
            <Link
              fontWeight={"500"}
              color={"gray.600"}
              _hover={{ textDecoration: "none" }}
            >
              Hjem
            </Link>
            <Text fontSize={"lg"} fontWeight={"500"} color={"gray.600"}>
              •
            </Text>
            <Link
              fontWeight={"500"}
              color={"gray.600"}
              _hover={{ textDecoration: "none" }}
              href={
                "/" +
                saloon_details.saloon_category
                  .toLowerCase()
                  .replace(/\s+/g, "-") +
                "?type=all&sort=normal&page=1"
              }
            >
              {saloon_details.saloon_category}
            </Link>
            <Text fontSize={"lg"} fontWeight={"500"} color={"gray.600"}>
              •
            </Text>
            <Link
              fontWeight={"500"}
              color={"gray.600"}
              _hover={{ textDecoration: "none" }}
              href={"/e/" + saloon_details.saloon_slug}
            >
              {saloon_details.saloon_name}
            </Link>
          </Flex>
          <Flex marginBottom={"15px"}>
            <Heading size={"2xl"} fontWeight={"600"}>
              {saloon_details.saloon_name}
            </Heading>
          </Flex>
          <Flex gap={"5px"} width={"100%"} position={"relative"}>
            <Text fontSize={"lg"} fontWeight={"600"}>
              {saloon_details.saloon_rating}
            </Text>
            {Array(Math.round(saloon_details.saloon_rating))
              .fill("")
              .map((_, i) => {
                const roundedRating =
                  Math.round(saloon_details.saloon_rating * 2) / 2;
                if (roundedRating - i >= 1) {
                  return (
                    <BsStarFill
                      key={i}
                      color={"#ECC94B"}
                      size={isMobile ? "25px" : "18px"}
                      style={{ marginTop: isMobile ? "0" : "2px" }}
                    />
                  );
                }
                if (roundedRating - i === 0.5) {
                  <BsStarHalf
                    key={i}
                    color={"#ECC94B"}
                    size={isMobile ? "25px" : "18px"}
                    style={{ marginTop: isMobile ? "0" : "2px" }}
                  />;
                }
                return (
                  <BsStar
                    key={i}
                    color={"#ECC94B"}
                    size={isMobile ? "25px" : "18px"}
                    style={{ marginTop: isMobile ? "0" : "2px" }}
                  />
                );
              })}
            <Text
              fontSize={"md"}
              fontWeight={"600"}
              color={"teal"}
              marginLeft={"5px"}
            >
              ({saloon_details.saloon_number_reviews})
            </Text>
            <Text
              fontSize={"lg"}
              fontWeight={"500"}
              color={"gray.600"}
              marginLeft={"5px"}
              marginRight={"5px"}
            >
              •
            </Text>
            <Text fontSize={isSmallScreen ? "sm" : "md"} fontWeight={"500"}>
              {saloon_details.saloon_adress}
            </Text>
            <Box
              border={"1px solid lightgray"}
              borderRadius={"50%"}
              cursor={"pointer"}
              _hover={{ background: "whitesmoke" }}
              width={"fit-content"}
              height={"fit-content"}
              padding={"10px"}
              justifySelf={"flex-end"}
              position={"absolute"}
              right={isSmallScreen ? "5" : "0"}
              bottom={isSmallScreen ? "-20" : "2"}
              background={"white"}
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                toast({
                  title: "Info",
                  description: "Saloon-URL kopieret til udklipsholderen",
                  position: "top-right",
                  isClosable: true,
                  status: "success",
                });
              }}
            >
              <MdIosShare size={"20px"} />
            </Box>
            <Box
              border={"1px solid lightgray"}
              borderRadius={"50%"}
              cursor={auth ? "pointer" : "not-allowed"}
              _hover={{ background: "whitesmoke" }}
              width={"fit-content"}
              height={"fit-content"}
              padding={"10px"}
              justifySelf={"flex-end"}
              position={"absolute"}
              right={isSmallScreen ? "70" : "50"}
              bottom={isSmallScreen ? "-20" : "2"}
              background={"white"}
              onClick={(event) => {
                if (auth) {
                  handle_bookmark(event, saloon_details.saloon_id);
                }
              }}
            >
              <BsHeart size={"20px"} />
            </Box>
          </Flex>
        </Box>
        <Flex flexDirection={"row"} marginTop={"20px"} paddingX={"3%"}>
          <Flex
            flex={7}
            height={"fit-content"}
            overflow={"hidden"}
            flexDirection={"column"}
            gap={"10px"}
          >
            <Flex width={"97%"} marginBottom={"50px"}>
              <Image
                src={saloon_details.saloon_logo}
                borderRadius={"12px"}
                height={{ base: "300px", md: "70vh" }}
                width={"100%"}
                objectFit={"cover"}
              />
            </Flex>
            <Flex marginBottom={"20px"}>
              <Heading size={"xl"} fontWeight={"600"}>
                Tjenester
              </Heading>
            </Flex>
            <Flex
              flexDirection={"column"}
              gap={"20px"}
              width={"97%"}
              marginBottom={"50px"}
            >
              {saloon_services.length === 0 ? (
                <>
                  <Text
                    fontSize={"lg"}
                    fontWeight={"500"}
                    textAlign={"justify"}
                    width={"97%"}
                    lineHeight={"7"}
                    color={"gray"}
                  >
                    Der er ingen tjenester tildelt til kunden.
                  </Text>
                </>
              ) : (
                <>
                  {saloon_services
                    .slice(0, services_display_count)
                    .map((service, index) => (
                      <Box
                        key={index}
                        width={"100%"}
                        height={"fit-content"}
                        border={"1px solid lightgray"}
                        borderRadius={"12px"}
                        padding={"20px"}
                      >
                        <Text
                          fontSize={"lg"}
                          fontWeight={"500"}
                          marginBottom={"5px"}
                        >
                          {service.service_name}
                        </Text>
                        <Text color={"gray.500"} fontWeight={"500"}>
                          {service.service_time
                            ? service.service_time
                            : "utidsbestemt"}
                        </Text>
                      </Box>
                    ))}
                </>
              )}

              {all_services <= 5 ? (
                <>
                  <Box
                    display={saloon_services.length > 0 ? "flex" : "none"}
                    width={"fit-content"}
                    height={"fit-content"}
                    border={"1px solid lightgray"}
                    borderRadius={"12px"}
                    paddingX={"15px"}
                    paddingY={"13px"}
                    cursor={"no-drop"}
                    _hover={{ background: "whitesmoke" }}
                  >
                    <Text fontSize={"lg"} fontWeight={"500"}>
                      {services_display_count === 5 ? "Se alt" : "Se mindre"}
                    </Text>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    display={saloon_services.length > 0 ? "flex" : "none"}
                    width={"fit-content"}
                    height={"fit-content"}
                    border={"1px solid lightgray"}
                    borderRadius={"12px"}
                    paddingX={"15px"}
                    paddingY={"13px"}
                    cursor={"pointer"}
                    _hover={{ background: "whitesmoke" }}
                    onClick={
                      services_display_count === 5
                        ? handleShowMoreServices
                        : handleShowLessServices
                    }
                  >
                    <Text fontSize={"lg"} fontWeight={"500"}>
                      {services_display_count === 5 ? "Se alt" : "Se mindre"}
                    </Text>
                  </Box>
                </>
              )}
            </Flex>
            <Flex marginBottom={"20px"}>
              <Heading size={"xl"} fontWeight={"600"}>
                Medarbejdere
              </Heading>
            </Flex>
            <Flex
              width={"97%"}
              marginBottom={"20px"}
              flexWrap={"wrap"}
              gap={"20px"}
            >
              {saloon_staffs.length === 0 ? (
                <>
                  <Text
                    fontSize={"lg"}
                    fontWeight={"500"}
                    textAlign={"justify"}
                    width={"97%"}
                    lineHeight={"7"}
                    color={"gray"}
                  >
                    Der er ingen medarbejdere tildelt til opgaven.
                  </Text>
                </>
              ) : (
                <>
                  {saloon_staffs
                    .slice(0, staffs_display_count)
                    .map((staff, index) => (
                      <Box marginRight={"80px"} key={index}>
                        <Avatar
                          size="2xl"
                          name={staff.name}
                          src="https://bit.ly/broken-link"
                          marginBottom={"10px"}
                          bg={"gray.200"}
                          color={"gray.700"}
                        />
                        <Text
                          fontSize={"lg"}
                          fontWeight={"500"}
                          textAlign={"center"}
                        >
                          {staff.name}
                        </Text>
                      </Box>
                    ))}
                </>
              )}
            </Flex>
            <Flex marginBottom={"50px"}>
              {all_staffs <= 5 ? (
                <>
                  {" "}
                  <Box
                    display={saloon_staffs.length > 0 ? "flex" : "none"}
                    width={"fit-content"}
                    height={"fit-content"}
                    border={"1px solid lightgray"}
                    borderRadius={"12px"}
                    paddingX={"15px"}
                    paddingY={"13px"}
                    cursor={"no-drop"}
                    _hover={{ background: "whitesmoke" }}
                  >
                    <Text fontSize={"lg"} fontWeight={"500"}>
                      {staffs_display_count === 5 ? "Se alt" : "Se mindre"}
                    </Text>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    display={saloon_staffs.length > 0 ? "flex" : "none"}
                    width={"fit-content"}
                    height={"fit-content"}
                    border={"1px solid lightgray"}
                    borderRadius={"12px"}
                    paddingX={"15px"}
                    paddingY={"13px"}
                    cursor={"pointer"}
                    _hover={{ background: "whitesmoke" }}
                    onClick={
                      staffs_display_count === 5
                        ? handleShowMoreStaffs
                        : handleShowLessStaffs
                    }
                  >
                    <Text fontSize={"lg"} fontWeight={"500"}>
                      {staffs_display_count === 5 ? "Se alt" : "Se mindre"}
                    </Text>
                  </Box>
                </>
              )}
            </Flex>
            <Flex marginBottom={"10px"}>
              <Heading size={"xl"} fontWeight={"600"}>
                Om
              </Heading>
            </Flex>
            <Flex marginBottom={"20px"}>
              <Text
                fontSize={"md"}
                fontWeight={"500"}
                textAlign={"justify"}
                width={"97%"}
                lineHeight={"7"}
              >
                {saloon_details.saloon_description}
              </Text>
            </Flex>
            <Flex marginBottom={"10px"}>
              <Box
                width={"97%"}
                height={{ base: "300px", md: "500px" }}
                borderRadius={"12px"}
              >
                <MapContainer
                  center={[
                    saloon_details.saloon_latitude,
                    saloon_details.saloon_longitude,
                  ]}
                  zoom={14}
                  style={{
                    height: "100%",
                    width: "100%",
                    zIndex: 1,
                    borderRadius: "12px",
                  }}
                >
                  <ReactLeafletGoogleLayer
                    apiKey="AIzaSyAYXObwfBn4s7Fmgwy96ztYPSypI9l-Hmg"
                    type={"roadmap"}
                    style={{ zIndex: 1, borderRadius: "12px" }}
                  />
                  <Marker
                    key={saloon_details.saloon_id}
                    position={[
                      saloon_details.saloon_latitude,
                      saloon_details.saloon_longitude,
                    ]}
                    eventHandlers={{
                      mouseover: () =>
                        handleMarkerHover(saloon_details.saloon_id),
                      mouseout: handleMarkerMouseLeave,
                    }}
                    zIndexOffset={
                      hoveredMarker === saloon_details.saloon_id ? 1 : 0
                    } // Higher zIndex for hovered marker
                    icon={CustomIcon({
                      rating: saloon_details.saloon_rating,
                      isHovered: hoveredMarker === saloon_details.saloon_id,
                      showAnimation,
                    })}
                  ></Marker>
                </MapContainer>
              </Box>
            </Flex>
            <Flex marginBottom={"40px"}>
              <Text fontSize={"md"} fontWeight={"500"}>
                {saloon_details.saloon_adress}{" "}
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() =>
                    handleGetDirection(
                      saloon_details.saloon_latitude,
                      saloon_details.saloon_longitude
                    )
                  }
                >
                  Få vejledninger
                </span>
              </Text>
            </Flex>
            <Flex
              marginBottom={"50px"}
              flexDirection={{ base: "column", md: "row" }}
            >
              <Flex
                flex={5}
                height={"fit-content"}
                overflow={"hidden"}
                flexDirection={"column"}
              >
                <Heading
                  size={isSmallScreen ? "md" : "lg"}
                  fontWeight={"500"}
                  marginBottom={"20px"}
                >
                  Åbningstider
                </Heading>

                {saloon_schedule &&
                  saloon_schedule.map(([day, schedule], index) => (
                    <Flex
                      justifyContent={"space-between"}
                      marginRight={isSmallScreen ? "0" : "30px"}
                      marginBottom={"5px"}
                      key={index}
                    >
                      <Text
                        fontSize={isSmallScreen ? "sm" : "lg"}
                        fontWeight={"400"}
                      >
                        {DanishDays[day]}
                      </Text>
                      <Text
                        fontSize={isSmallScreen ? "sm" : "lg"}
                        fontWeight={"400"}
                      >
                        {schedule.offday === 1
                          ? "Lukket"
                          : schedule.startTime + " - " + schedule.endTime}
                      </Text>
                    </Flex>
                  ))}
              </Flex>
              <Flex
                flex={5}
                height={"fit-content"}
                overflow={"hidden"}
                flexDirection={"column"}
                paddingLeft={{ base: "0", md: "90px" }}
                marginTop={{ base: "20px", md: "0" }}
              >
                <Heading
                  size={isSmallScreen ? "md" : "lg"}
                  fontWeight={"500"}
                  marginBottom={"20px"}
                >
                  Kontakt information
                </Heading>
                <Flex gap={"15px"} alignItems={"center"} marginBottom={"20px"}>
                  <BsTelephoneFill />
                  <Text
                    fontWeight={"500"}
                    fontSize={isSmallScreen ? "md" : "lg"}
                  >
                    {saloon_details.saloon_phone}
                  </Text>
                </Flex>
                <Flex gap={"15px"} alignItems={"center"}>
                  <MdOutlineAlternateEmail />
                  <Text
                    fontWeight={"500"}
                    fontSize={isSmallScreen ? "md" : "lg"}
                  >
                    {saloon_details.saloon_email}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            display={isSmallScreen ? "none" : "flex"}
            flex={3}
            justifyContent={"left"}
            overflow={"hidden"}
            height={"fit-content"}
            marginBottom={"20px"}
            position="sticky"
            top={100}
            right={0}
            zIndex={3}
          >
            <Box
              width={"100%"}
              height={"fit-content"}
              borderRadius={"12px"}
              border={"1px solid lightgray"}
              flexDirection={"column"}
            >
              <Heading size={"xl"} padding={"20px"} fontWeight={"700"}>
                {saloon_details.saloon_name}
              </Heading>
              <Flex px={"20px"} py={"5px"} gap={"10px"}>
                <Text fontSize={"2xl"} fontWeight={"600"}>
                  {saloon_details.saloon_rating}
                </Text>
                {Array(Math.round(saloon_details.saloon_rating))
                  .fill("")
                  .map((_, i) => {
                    const roundedRating =
                      Math.round(saloon_details.saloon_rating * 2) / 2;
                    if (roundedRating - i >= 1) {
                      return (
                        <BsStarFill
                          key={i}
                          color={"#ECC94B"}
                          size={"20px"}
                          style={{ marginTop: "7px" }}
                        />
                      );
                    }
                    if (roundedRating - i === 0.5) {
                      <BsStarHalf
                        key={i}
                        color={"#ECC94B"}
                        size={"20px"}
                        style={{ marginTop: "7px" }}
                      />;
                    }
                    return (
                      <BsStar
                        key={i}
                        color={"#ECC94B"}
                        size={"20px"}
                        style={{ marginTop: "7px" }}
                      />
                    );
                  })}
                <Text fontSize={"2xl"} fontWeight={"500"} color={"teal"}>
                  ({saloon_details.saloon_number_reviews})
                </Text>
              </Flex>
              <Tag
                size={"lg"}
                variant="solid"
                background={"gray.100"}
                color={"gray.700"}
                mx={"20px"}
                mt={"10px"}
              >
                {saloon_details.saloon_category}
              </Tag>
              <Flex
                padding={"20px"}
                width={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {saloon_details.saloon_website ? (
                  <>
                    <Button
                      size={"lg"}
                      width={"100%"}
                      mt={2}
                      background={"#1a202c"}
                      color={"white"}
                      _hover={{ bg: "#232c3d" }}
                      onClick={() => {
                        handleBookNow(saloon_details);
                        window.open(saloon_details.saloon_website, "_blank");
                      }}
                    >
                      Book Nu
                    </Button>
                  </>
                ) : (
                  <>
                    <Tooltip
                      label={
                        "Saloon har ingen online booking system, venligst ring for booking"
                      }
                    >
                      <Button
                        size={"lg"}
                        width={"100%"}
                        mt={2}
                        background={"#69748a"}
                        color={"white"}
                        _hover={{ bg: "#575f71" }}
                        cursor={"no-drop"}
                      >
                        Book Nu
                      </Button>
                    </Tooltip>
                  </>
                )}
              </Flex>
              <Flex width={"100%"} marginTop={3}>
                <Box
                  width={"100%"}
                  padding={"20px"}
                  borderTop={"1px solid lightgray"}
                >
                  <Flex marginTop={"20px"} gap={"10px"}>
                    <MdOutlineMyLocation size={"30px"} />
                    <Text
                      fontWeight={"500"}
                      alignSelf={"center"}
                      textAlign={"left"}
                    >
                      {saloon_details.saloon_adress}{" "}
                      <span
                        style={{
                          marginLeft: "5px",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleGetDirection(
                            saloon_details.saloon_latitude,
                            saloon_details.saloon_longitude
                          )
                        }
                      >
                        Få vejledninger
                      </span>
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
          <Flex
            display={isSmallScreen ? "flex" : "none"}
            position="fixed"
            bottom={"0"}
            left={"0"}
            width={"100%"}
            justifyContent={"space-between"}
            height={"fit-content"}
            padding={"15px"}
            background={"white"}
            alignItems={"center"}
            boxShadow="rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em"
            zIndex={"5"}
          >
            <Text fontWeight={"600"}>{saloon_details.saloon_name}</Text>
            {saloon_details.saloon_website ? (
              <>
                <Button
                  size={"lg"}
                  background={"#1a202c"}
                  color={"white"}
                  _hover={{ bg: "#232c3d" }}
                  onClick={() => {
                    handleBookNow(saloon_details);
                    window.open(saloon_details.saloon_website, "_blank");
                  }}
                >
                  Book nu
                </Button>
              </>
            ) : (
              <>
                <Button
                  size={"lg"}
                  background={"#69748a"}
                  color={"white"}
                  _hover={{ bg: "#575f71" }}
                  onClick={() => {
                    handleBookNow(saloon_details);
                    window.open(saloon_details.saloon_website, "_blank");
                  }}
                >
                  Book nu
                </Button>
              </>
            )}
          </Flex>
        </Flex>
        <Box>
          <MarketplaceFooter />
        </Box>
      </div>
    </>
  ) : null;
}

export default MarketplaceExternalSalon;
