import React, { useState } from "react";
import "./MarketplaceLogin.css";
import { useNavigate } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import {
  Button,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
  Link,
} from "@chakra-ui/react";
import { Clientauthcontext } from "./context/Clientauthcontext";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";

function MarketplaceLogin() {
  const { loginclient } = useContext(Clientauthcontext);

  const toast = useToast();

  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const [isLoading, setisLoading] = useState(false);

  const [inputs, setInputs] = useState({
    client_email: "",
    client_password: "",
  });

  const handlechange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handle_signin = async (e) => {
    e.preventDefault();
    setisLoading(true);
    try {
      if (inputs.client_email === "" || inputs.client_password === "") {
        toast({
          title: "Skriv fejl",
          description: "E-mail- eller adgangskodefeltet er tomt",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        setisLoading(false);
      } else {
        const res = await loginclient(inputs);

        if (res.status === 200) {
          navigate("/");
          setisLoading(false);
        }
        if (res.status === 201) {
          navigate("/opret-konto/otp", {
            state: { client_user_id: res.data.client_user_id },
          });
          setisLoading(false);
        }
      }
    } catch (err) {
      if (err.response.status === 404) {
        toast({
          title: "Login fejl",
          description: err.response.data.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      setisLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Log ind på Bookinghero Marketplace</title>
        <meta
          name="description"
          content="Log ind på Bookinghero Marketplace for at få adgang til hundreder af skønheds- og velværevirksomheder og behandlinger."
        />
        <link rel="canonical" href="/login" />
      </Helmet>
      <div className="LoginClient">
        <div className="Left">
          <Link href={"/"} className="logo_container">
            <img src="../assets/Logo/logo.png" alt="" className="logo"></img>
          </Link>
          <div className="Header">Velkommen Tilbage!</div>
          <div className="Subheader">
            Indtast venligst dine oplysninger for at logge ind
          </div>
          <form>
            <div className="Label">Email</div>
            <Input
              name="client_email"
              required
              variant="filled"
              placeholder="din e-mail@gmail.dk"
              size="lg"
              className="Emailinput"
              type="email"
              onChange={handlechange}
            />
            <div className="Passlabel">Adgangskode</div>
            <InputGroup className="Passinput">
              <Input
                name="client_password"
                required
                type={show ? "text" : "password"}
                placeholder="Indtast adgangskode"
                variant="filled"
                className="Password"
                onChange={handlechange}
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  className="ShowHide"
                  onClick={() => setShow(!show)}
                >
                  <Icon
                    as={show ? AiFillEye : AiFillEyeInvisible}
                    h={5}
                    w={5}
                  />
                </Button>
              </InputRightElement>
            </InputGroup>
            <div className="Fergotpass">
              <Link href={"/logind/glemt-kodeord"}>
                <strong>Glemt kodeord?</strong>
              </Link>
            </div>
            {!isLoading ? (
              <>
                <Button className="Submitbtn" onClick={handle_signin}>
                  Log ind
                </Button>
              </>
            ) : (
              <>
                <Button className="Submitbtn" isLoading loadingText="Indlæser">
                  Log ind
                </Button>
              </>
            )}
          </form>
          <div>
            <div className="registerText">
              Har du ikke en konto endnu?
              <Link href={"/opret-konto"} className="Link">
                Oprette gratis en konto
              </Link>
            </div>
          </div>
        </div>
        <div className="Right">
          <div className="Imagecontainer">
            <div className="Headerscontainer">
              <div className="Header">
                BOOK DIN YNDLINGSBEHANDLING HVOR SOM HELST
              </div>
              <div className="Subheader">BOOKINGHERO ™</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MarketplaceLogin;
