import React, { useEffect, useRef, useState } from "react";
import "./MarketplaceSearchPage.css";
import { Select } from "chakra-react-select";
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css"; // import css
import MarketplaceNavbar from "../components/MarketplaceNavbar.tsx";
import {
  Box,
  Flex,
  Avatar,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
  Text,
  Select as SelectC,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuOptionGroup,
  MenuList,
  MenuItemOption,
  Button,
  Icon,
  Image,
  Tag,
  Spinner,
  Link,
} from "@chakra-ui/react";
import { Search2Icon, SearchIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { MapContainer, Marker, Popup } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { GoSettings } from "react-icons/go";
import { useParams, useNavigate } from "react-router-dom";
import L from "leaflet";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";

const regions = [
  {
    id: 1129,
    label: "Region Hovedstaden",
    region_lat: 55.8333,
    region_lng: 12.3333,
    value: "region-hovedstaden",
  },
  {
    id: 2003,
    label: "Region Midtjylland",
    region_lat: 56.1667,
    region_lng: 9.5,
    value: "region-midtjylland",
  },
  {
    id: 2072,
    label: "Region Nordjylland",
    region_lat: 57,
    region_lng: 9.66667,
    value: "region-nordjylland",
  },
  {
    id: 1661,
    label: "Region Sjælland",
    region_lat: 55.4167,
    region_lng: 11.8333,
    value: "region-sjælland",
  },
  {
    id: 1862,
    label: "Region Syddanmark",
    region_lat: 55.3791,
    region_lng: 10.345,
    value: "region-syddanmark",
  },
];

function Rating({ rating, numReviews }) {
  return (
    <Box display="flex" alignItems="center">
      <Box
        as="span"
        mr="1"
        mt="0.5"
        color="gray.600"
        fontSize="md"
        fontWeight={"bold"}
      >
        {rating}
      </Box>
      <Box>
        {" "}
        <BsStarFill
          style={{ marginLeft: "1", position: "relative" }}
          color={"#ECC94B"}
        />
      </Box>

      <Box
        as="span"
        ml="2"
        mt="0.5"
        color="gray.600"
        fontSize="md"
        fontWeight={"600"}
      >
        ({numReviews})
      </Box>
    </Box>
  );
}

const CustomIcon = ({ rating, isHovered, showAnimation }) => {
  const leftPosition = Number.isInteger(rating) ? "10px" : "6px";
  const leftPosition2 = Number.isInteger(rating) ? "14px" : "9px";
  const jumpAnimationClass = showAnimation ? "" : "jump-animation";
  const fontSize = "10px";
  return L.divIcon({
    className: "custom-icon",
    html: `
      <div  style="position: relative; width=40px; height=40px;">
      
      ${
        rating
          ? !isHovered
            ? `<i class="fa-sharp fa-solid fa-location-pin fa-2xl" style="color: #001642; font-size:36px; ">
          ${`<span style="position:absolute; top:5px; left:${leftPosition};margin:auto; color: white;  font-weight: 600; font-size: ${fontSize};">${rating} </span>`}
          </i>
          `
            : `<i class="${jumpAnimationClass} fa-sharp fa-solid fa-location-pin fa-2xl" style=" color: #001642; font-size:45px; position:relative; left:-3px;top:-3px; ">
            ${`<span style="position:absolute;top:-8px; left:${leftPosition2};margin:auto; color: white;  font-weight: 600; font-size: ${fontSize};">${rating} </span>`}
            <i class="fa-solid fa-star fa-fade" style="position:relative;top:-8px; left:12px;color: #ffffff; font-size:10px;"></i>
            </i>`
          : !isHovered
          ? `<i class="fa-sharp fa-solid fa-location-dot fa-2xl" style="color: #001642; font-size:36px; ">
          
            </i>`
          : `<i class="${jumpAnimationClass} fa-sharp fa-solid fa-location-dot fa-2xl" style=" color: #001642; font-size:45px; position:relative; left:-3px;top:-3px; ">
             
              </i>`
      }
   
      </div>
    `,
  });
};

function MarketplaceSearchPage() {
  const secretKey = "bookinghero";

  const searchParams = new URLSearchParams(window.location.search);

  const navigate = useNavigate();

  let { search } = useParams();

  const [search_category, set_search_category] = useState(
    search === "søg"
      ? ""
      : decodeURIComponent(search)
          .toLowerCase()
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
  );

  const defualt_category_value =
    search_category !== ""
      ? { value: search_category, label: search_category }
      : null;

  const [search_query, set_search_query] = useState(
    searchParams.get("q") === null ? "" : searchParams.get("q")
  );

  const [search_region, set_search_region] = useState(
    searchParams.get("region") === null ? "" : searchParams.get("region")
  );

  const [select_location, set_selected_location] = useState(
    regions.find((region) => region.value === search_region)
  );

  const defualt_region_value = select_location
    ? { value: select_location.value, label: select_location.label }
    : null;

  const [default_region_lat_lng, set_default_region_lat_lng] = useState(
    select_location
      ? { lat: select_location.region_lat, lng: select_location.region_lng }
      : { lat: "", lng: "" }
  );

  const [search_type, set_search_type] = useState(
    searchParams.get("type") === null ? "" : searchParams.get("type")
  );

  const [default_type_value, set_default_type_value] = useState(() => {
    if (!search_type || search_type === "") {
      set_search_type("all");
      return ["external", "direct"];
    } else if (search_type === "all") {
      set_search_type("all");
      return ["external", "direct"];
    } else if (search_type === "direct") {
      set_search_type("direct");
      return ["direct"];
    } else if (search_type === "external") {
      set_search_type("external");
      return ["external"];
    }
  });

  const [search_sort, set_search_sort] = useState(() => {
    if (
      searchParams.get("sort") === "normal" ||
      searchParams.get("sort") === "popular"
    ) {
      return searchParams.get("sort");
    } else if (searchParams.get("sort") === "nearest") {
      if (!Cookies.get("accept_cookies") && !Cookies.get("blt")) {
        handle_location();
      }
      return "nearest";
    } else if (searchParams.get("sort") === null) {
      return "normal";
    } else {
      return "normal";
    }
  });

  const [lat, setLat] = useState(
    Cookies.get("accept_cookies")
      ? Cookies.get("blt")
        ? CryptoJS.AES.decrypt(Cookies.get("blt"), secretKey).toString(
            CryptoJS.enc.Utf8
          )
        : "55.6800758"
      : "55.6800758"
  );

  const [lng, setLng] = useState(
    Cookies.get("accept_cookies")
      ? Cookies.get("blg")
        ? CryptoJS.AES.decrypt(Cookies.get("blg"), secretKey).toString(
            CryptoJS.enc.Utf8
          )
        : "12.5182071"
      : "12.5182071"
  );

  const [search_page_number, set_search_page_number] = useState(
    searchParams.get("page") === null ? 1 : parseInt(searchParams.get("page"))
  );

  const [blur, set_blur] = useState(true);

  const toast = useToast();

  const [input_value, set_input_value] = useState(search_query);

  const [showdropdown, setShowdropdown] = useState(false);

  const [finalresults, setfinalresults] = useState([
    {
      title: "Populær",
      subitems: [
        {
          id: 1,
          label: "frisør",
          logo: "",
          type: "service",
        },
        {
          id: 3,
          label: "massage",
          logo: "",
          type: "service",
        },
        {
          id: 3,
          label: "hårstyling",
          logo: "",
          type: "service",
        },
      ],
    },
  ]);
  const [isHovered, setIsHovered] = useState();
  const handle_search_suggest = async (event) => {
    setShowdropdown(true);
    set_input_value(event.target.value);

    try {
      const res = await axios.get(
        `/search/web/suggest/?search_input=${event.target.value}`
      );
      if (res.status === 200) {
        let loop_array = [];
        if (
          event.target.value === "" ||
          res.data.search_suggestions.length === 0
        ) {
          setfinalresults([
            {
              title: "Populær",
              subitems: [
                {
                  id: 1,
                  label: "frisør",
                  logo: "",
                  type: "service",
                },
                {
                  id: 3,
                  label: "massage",
                  logo: "",
                  type: "service",
                },
                {
                  id: 3,
                  label: "hårstyling",
                  logo: "",
                  type: "service",
                },
              ],
            },
          ]);
        } else {
          if (res.data.search_suggestions.services.length !== 0) {
            // loop services
            let services = [
              {
                title: "Tjenester",
                subitems: [],
              },
            ];
            for (
              let index = 0;
              index < res.data.search_suggestions.services.length;
              index++
            ) {
              services[0].subitems.push({
                id: res.data.search_suggestions.services[index].service_id,
                label: res.data.search_suggestions.services[index].service_name,
                logo: "",
                type: "service",
              });
            }
            loop_array = [...loop_array, ...services];
          }
          if (res.data.search_suggestions.external_salons.length !== 0) {
            // loop external saloons
            let external_saloons = [
              {
                title: "Ekstern booking",
                subitems: [],
              },
            ];
            for (
              let index = 0;
              index < res.data.search_suggestions.external_salons.length;
              index++
            ) {
              external_saloons[0].subitems.push({
                id: res.data.search_suggestions.external_salons[index]
                  .saloon_id,
                label:
                  res.data.search_suggestions.external_salons[index]
                    .saloon_name,
                logo: res.data.search_suggestions.external_salons[index]
                  .saloon_logo,
                slug: res.data.search_suggestions.external_salons[index]
                  .saloon_slug,
                type: "external_saloon",
              });
            }
            loop_array = [...loop_array, ...external_saloons];
          }
          if (res.data.search_suggestions.direct_salons.length !== 0) {
            // loop direct saloons
            let direct_saloons = [
              {
                title: "Direkte booking",
                subitems: [],
              },
            ];
            for (
              let index = 0;
              index < res.data.search_suggestions.direct_salons.length;
              index++
            ) {
              direct_saloons[0].subitems.push({
                id: res.data.search_suggestions.direct_salons[index].saloon_id,
                label:
                  res.data.search_suggestions.direct_salons[index].saloon_name,
                logo:
                  "https://api-v3.bookinghero.io" +
                  res.data.search_suggestions.direct_salons[index].saloon_logo,
                slug: res.data.search_suggestions.direct_salons[index]
                  .saloon_slug,
                type: "direct_saloon",
              });
            }
            loop_array = [...loop_array, ...direct_saloons];
          }
          setfinalresults(loop_array);
        }
      }
    } catch (err) {
      toast({
        title: "Server Fejl",
        description: "Prøv venligst igen om et par minutter",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  const [categories, set_categories] = useState([]);

  const [salons, set_salons] = useState([]);

  const [data_loading, set_data_loading] = useState(true);

  const [total_pages, set_total_pages] = useState(0);

  const [count_salons, set_count_salons] = useState(0);

  const [hoveredMarker, setHoveredMarker] = useState(null);

  const handleMarkerHover = (markerId) => {
    setHoveredMarker(markerId);
  };
  const [showAnimation, setShowAnimation] = useState(true);

  useEffect(() => {
    setShowAnimation(false);
  }, []);
  const handleMarkerMouseLeave = () => {
    setHoveredMarker(null);
  };

  // const [center_map, set_center_map] = useState([56.2639, 10.5018]);

  const [center_map, set_center_map] = useState([55.6800758, 12.5182071]);
  const [map_zoom, set_map_zoom] = useState(13);

  const scroll_ref = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `search/web/load/?search_input=${search_query}&search_category=${search_category}&search_sort=${search_sort}&search_page=${search_page_number}&search_lat=${lat}&search_lng=${lng}&region_lat=${default_region_lat_lng.lat}&region_lng=${default_region_lat_lng.lng}&saloon_type=${search_type}`
        );

        if (res.status === 200) {
          if (search_category !== "") {
            search = search_category.toLowerCase().replace(/\s+/g, "-");
          }
          if (
            search_region !== "" &&
            default_region_lat_lng.lat !== "" &&
            default_region_lat_lng.lng !== ""
          ) {
            set_center_map([
              default_region_lat_lng.lat,
              default_region_lat_lng.lng,
            ]);
            set_map_zoom(8);

            searchParams.set("region", search_region);
          }
          if (search_type !== "") {
            searchParams.set("type", search_type);
          }
          if (search_sort !== "") {
            searchParams.set("sort", search_sort);
          }
          if (search_page_number !== "") {
            searchParams.set("page", search_page_number);
          }
          let refresh_link = `/${search.toString()}?${searchParams.toString()}`;
          navigate(`${refresh_link.toString()}`, { replace: true });
          set_categories(res.data.search_load_data.categories);
          set_salons(res.data.search_load_data.saloons_list);
          set_total_pages(res.data.search_load_data.total_pages);
          set_count_salons(res.data.search_load_data.count_saloons);
          if (scroll_ref.current) {
            scroll_ref.current.scrollTop = 0;
          }
          set_data_loading(false);
        }
      } catch (err) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et par minutter",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search_category,
    search_region,
    default_region_lat_lng,
    search_type,
    search_sort,
    search_page_number,
  ]);

  const handle_location = async () => {
    // Setting the cookie options
    const cookieOptions = {
      sameSite: "strict", // Set the SameSite attribute to 'Strict'
    };
    // Setting the users Ip adress

    if (Cookies.get("accept_cookies")) {
      fetch("https://api.ipify.org/?format=json")
        .then((response) => response.json())
        .then((data) => {
          const encryptedValue = CryptoJS.AES.encrypt(
            data.ip,
            secretKey
          ).toString();
          Cookies.set("bip", encryptedValue, cookieOptions);
        });
    }
    // Setting the users location information
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLat(position?.coords?.latitude.toString());
          setLng(position?.coords?.longitude.toString());

          if (Cookies.get("accept_cookies")) {
            const encryptedLat = CryptoJS.AES.encrypt(
              lat,
              secretKey
            ).toString();
            Cookies.set("blt", encryptedLat, cookieOptions);

            const encryptedLng = CryptoJS.AES.encrypt(
              lng,
              secretKey
            ).toString();
            Cookies.set("blg", encryptedLng, cookieOptions);
          }
          const geocoder = new window.google.maps.Geocoder();
          const location = new window.google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );
          if (Cookies.get("accept_cookies")) {
            geocoder.geocode({ location }, (results, status) => {
              if (status === "OK") {
                const city = results?.[0].address_components.find((component) =>
                  component.types.includes("locality")
                )?.long_name;

                const country = results?.[0].address_components.find(
                  (component) => component.types.includes("country")
                )?.long_name;
                const encryptedCity = CryptoJS.AES.encrypt(
                  city,
                  secretKey
                ).toString();
                const encryptedCountry = CryptoJS.AES.encrypt(
                  country,
                  secretKey
                ).toString();
                Cookies.set("bctr", encryptedCountry, cookieOptions);
                Cookies.set("bct", encryptedCity, cookieOptions);
              }
            });
          }
        },
        (error) => {
          console.error(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    }
  };

  const column2Visible = useBreakpointValue({ base: false, xl: true });
  return (
    <div style={{ height: "100vh" }}>
      <div className={showdropdown ? "doBlur" : "doHide"}></div>
      <Box position="sticky" top={0} zIndex={3}>
        <MarketplaceNavbar />
      </Box>
      <Flex zIndex={1} height={"91vh"}>
        <Box className="cardsSide">
          <Box className="cardsSidetop">
            <div className="Searchcontainer">
              <InputGroup size="md" width="36vw" className="Inputgroup">
                {blur ? (
                  <>
                    <Input
                      focusBorderColor="transparent"
                      pr="4.5rem"
                      type="text"
                      placeholder="Søg på service, salon eller firmanavn"
                      className="Input"
                      color={"black"}
                      _placeholder={{
                        color: "black", // Change this value to your desired color
                      }}
                      value={input_value}
                      onChange={handle_search_suggest}
                      onFocus={() => {
                        setShowdropdown(true);
                      }}
                      onBlur={() => {
                        setShowdropdown(false);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Input
                      focusBorderColor="transparent"
                      pr="4.5rem"
                      type="text"
                      color={"black"}
                      _placeholder={{
                        color: "black", // Change this value to your desired color
                      }}
                      placeholder="Søg på service, salon eller firmanavn"
                      className="Input"
                      value={input_value}
                      onChange={handle_search_suggest}
                      onFocus={() => {
                        setShowdropdown(true);
                      }}
                    />
                  </>
                )}

                <InputRightElement width="6rem" className="Inputright">
                  <Button
                    color={"white"}
                    aria-label="Search database"
                    h="3rem"
                    w="3rem"
                    className="Searchbtn"
                    as="a"
                    href={"/søg?q=" + input_value.toLocaleLowerCase()}
                  >
                    Søg
                  </Button>
                </InputRightElement>
              </InputGroup>
              {showdropdown ? (
                <>
                  <div
                    className="Dropdown"
                    onMouseDown={() => {
                      set_blur(false);
                    }}
                  >
                    {finalresults.length === 0 ? (
                      <></>
                    ) : (
                      <>
                        {finalresults.map((result) => (
                          <>
                            <div>
                              <Text
                                px={"10px"}
                                mb={"10px"}
                                mt={"10px"}
                                color={"gray.600"}
                                fontWeight={"600"}
                                fontSize={"1.2rem"}
                              >
                                {result.title}
                              </Text>
                            </div>
                            {result.subitems.map((subitem) => (
                              <>
                                {result.title === "Populær" ||
                                result.title === "Tjenester" ? (
                                  <>
                                    <Box
                                      as="a"
                                      href={
                                        "/søg?q=" +
                                        subitem.label.toLocaleLowerCase()
                                      }
                                      textDecoration="none"
                                      className="Dropdownrow"
                                    >
                                      <Search2Icon className="Dropdownicon" />
                                      <Text className="Dropdowntext">
                                        {subitem.label}
                                      </Text>
                                      <ExternalLinkIcon className="selectIcon" />
                                    </Box>
                                  </>
                                ) : (
                                  <>
                                    <Box
                                      as="a"
                                      href={
                                        subitem.type === "direct_saloon"
                                          ? `/d/${subitem.slug}`
                                          : `/e/${subitem.slug}`
                                      }
                                      textDecoration="none"
                                      className="Dropdownrow"
                                    >
                                      <Text className="Dropdowntext">
                                        <Avatar
                                          size="xs"
                                          name={subitem.label}
                                          src={subitem.logo}
                                          className="Avatar"
                                        />
                                        {subitem.label}
                                      </Text>
                                      <ExternalLinkIcon className="selectIcon" />
                                    </Box>
                                  </>
                                )}
                              </>
                            ))}
                          </>
                        ))}
                      </>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>

            {/* <Flex
              mt={"25px"}
              justifyContent={"center"}
              alignItems={"center"}
              className="search_page_input"
            >
              <InputGroup
                size="lg"
                width={{ lg: "40rem", sm: "31rem" }}
                className="Inputgroup "

              >
                {blur ? (
                  <>
                    <Input
                      focusBorderColor="#CBD5E0"
                      pr="4.5rem"
                      type="text"
                      placeholder="Søg på service, salon eller firmanavn"
                      className="Input"
                      value={input_value}
                      onChange={handle_search_suggest}
                      onFocus={() => {
                        setShowdropdown(true);
                      }}
                      onBlur={() => {
                        setShowdropdown(false);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Input
                      focusBorderColor="transparent"
                      pr="4.5rem"
                      type="text"
                      placeholder="Søg på service, salon eller firmanavn"
                      className="Input"
                      value={input_value}
                      onChange={handle_search_suggest}
                      onFocus={() => {
                        setShowdropdown(true);
                      }}
                    />
                  </>
                )}

                <InputRightElement width="4.5rem" className="Inputright">
                  <IconButton
                    aria-label="Search database"
                    icon={<SearchIcon />}
                    h="3rem"
                    w="3rem"
                    className="Searchbtn"
                    as="a"
                    href={"/søg?q=" + input_value.toLocaleLowerCase()}
                  />
                </InputRightElement>
              </InputGroup>
              {showdropdown ? (
                <>
                  <div
                    className="Dropdown"
                    onMouseDown={() => {
                      set_blur(false);
                    }}
                  >
                    {finalresults.length === 0 ? (
                      <></>
                    ) : (
                      <>
                        {finalresults.map((result) => (
                          <>
                            <div>
                              <Text
                                px={"10px"}
                                mb={"10px"}
                                mt={"10px"}
                                color={"gray.600"}
                                fontWeight={"600"}
                                fontSize={"1.2rem"}
                              >
                                {result.title}
                              </Text>
                            </div>
                            {result.subitems.map((subitem) => (
                              <>
                                {result.title === "Populær" ||
                                  result.title === "Tjenester" ? (
                                  <>
                                    <Box
                                      as="a"
                                      href={
                                        "/søg?q=" +
                                        subitem.label.toLocaleLowerCase()
                                      }
                                      textDecoration="none"
                                      className="Dropdownrow"
                                    >
                                      <Search2Icon className="Dropdownicon" />
                                      <Text className="Dropdowntext">
                                        {subitem.label}
                                      </Text>
                                      <ExternalLinkIcon className="selectIcon" />
                                    </Box>
                                  </>
                                ) : (
                                  <>
                                    <Box
                                      as="a"
                                      href={
                                        subitem.type === "direct_saloon"
                                          ? `/d/${subitem.slug}`
                                          : `/e/${subitem.slug}`
                                      }
                                      textDecoration="none"
                                      className="Dropdownrow"
                                    >
                                      <Text className="Dropdowntext">
                                        <Avatar
                                          size="xs"
                                          name={subitem.label}
                                          src={subitem.logo}
                                          className="Avatar"
                                        />
                                        {subitem.label}
                                      </Text>
                                      <ExternalLinkIcon className="selectIcon" />
                                    </Box>
                                  </>
                                )}
                              </>
                            ))}
                          </>
                        ))}
                      </>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </Flex> */}
            {/* <Flex
            mt={"15px"}
            justifyContent={"space-between"}
            alignItems={"center"}
            marginX={"auto"}
            width={{ lg: "40rem", sm: "31rem" }}
            className="filter_flex"
          >
            <Select
              name="categories"
              className="Select"
              focusBorderColor="transparent"
              variant="filled"
              placeholder="Kategorier"
              isLoading={data_loading}
              options={categories}
              defaultValue={defualt_category_value}
              onChange={(event) => {
                set_search_category(event.value);
              }}
            />
            <Select
              name="cities"
              className="Select"
              focusBorderColor="transparent"
              variant="filled"
              placeholder="Placeringer"
              isLoading={data_loading}
              options={regions}
              defaultValue={defualt_region_value}
              onChange={(event) => {
                set_search_region(event.value);
                set_default_region_lat_lng({
                  lat: event.region_lat,
                  lng: event.region_lng,
                });
              }}
            />
            <Menu isLazy closeOnSelect={false} zIndex={2}>
              <MenuButton as={Button} leftIcon={<Icon as={GoSettings} />}>
                Flere
              </MenuButton>
              <MenuList zIndex={2}>
                <MenuOptionGroup
                  type="checkbox"
                  defaultValue={default_type_value}
                  onChange={(event) => {
                    set_default_type_value(event);
                    if (event.length === 2) {
                      set_search_type("all");
                    } else {
                      set_search_type(event[0]);
                    }
                  }}
                >
                  <MenuItemOption value="external">
                    Ekstern booking
                  </MenuItemOption>
                  <MenuItemOption value="direct">
                    Direkte booking
                  </MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </Flex> */}
            <Flex
              mt={"15px"}
              justifyContent={"space-between"}
              alignItems={"center"}
              marginX={"auto"}
              width={{ lg: "85%", sm: "31rem", base: "20rem" }}
            >
              <Text fontSize={"md"} fontWeight={"600"}>
                {count_salons} resultater
                {/* <span style={{ color: "teal", textDecoration: "underline" }}>
                  {search_category === "" ? (
                    <>i Alle Kategorier</>
                  ) : (
                    <>{search_category}</>
                  )}
                </span> */}
              </Text>
              <SelectC
                variant="outline"
                borderRadius="12px"
                width={"170px"}
                defaultValue={search_sort}
                onChange={async (event) => {
                  if (!Cookies.get("accept_cookies") && !Cookies.get("blt")) {
                    await handle_location();
                  }
                  set_search_sort(event.target.value);
                }}
              >
                <option value="normal">Nyligt tilføjet</option>
                <option value="popular">Populær</option>
                <option value="nearest">Nærmeste</option>
              </SelectC>
            </Flex>
          </Box>
          <Flex
            // justifyContent={"space-between"}
            alignItems={"center"}
            marginX={"auto"}
            // direction={"column"}
            gap={"25px"}
            width={"100%"}
            className="cardsSection"
            ref={scroll_ref}
          >
            {data_loading ? (
              <>
                <Spinner size="xl" />
              </>
            ) : (
              <>
                {salons.map((salon, index) => (
                  <Box
                    key={index}
                    bg={"white.800"}
                    className="salon_card"
                    width={{
                      base: "90%",
                      md: "400px",
                      sm: "400px",
                    }} // Use responsive width values
                    maxH={{ base: "auto", sm: "400px", md: "350px" }} // Use responsive maxH values
                    borderWidth="1px"
                    rounded="lg"
                    shadow="lg"
                    position="relative"
                    mb={5} // Add margin bottom to create space between rows
                    as={"a"}
                    href={
                      salon.saloon_tag === "external"
                        ? `/e/${salon.saloon_slug}`
                        : `/d/${salon.saloon_slug}`
                    }
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    onMouseEnter={() => setIsHovered(index)}
                    onMouseLeave={() => setIsHovered(null)}
                    onMouseOver={() => {
                      handleMarkerHover(salon.saloon_id);
                    }}
                    onMouseOut={handleMarkerMouseLeave}
                    overflow={"hidden"}
                  >
                    <Box position="relative" overflow={"hidden"}>
                      <Image
                        src={
                          salon.saloon_tag === "direct"
                            ? "https://api-v3.bookinghero.io" +
                              salon.saloon_logo
                            : salon.saloon_logo
                        }
                        alt={salon.saloon_name}
                        roundedTop="lg"
                        objectFit={"cover"}
                        height={"200px"}
                        width={"100%"}
                        style={{
                          transform:
                            isHovered === index ? "scale(1.2)" : "none",
                          transition: "transform 0.3s ease",
                        }}
                      />
                    </Box>

                    <Box p="6">
                      <Box display="flex" alignItems="baseline"></Box>
                      <Flex
                        mt="1"
                        justifyContent="space-between"
                        alignContent="center"
                      >
                        <Box
                          fontSize="xl"
                          fontWeight="semibold"
                          as="h4"
                          lineHeight="tight"
                        >
                          <Text
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "280px",
                            }}
                          >
                            {salon.saloon_name}
                          </Text>
                        </Box>
                      </Flex>

                      <Flex
                        justifyContent="space-between"
                        alignContent="center"
                      >
                        <Box
                          fontSize="sm"
                          fontWeight="semibold"
                          as="h4"
                          lineHeight="tight"
                          mb={1}
                          mt={2}
                        >
                          <Text
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "300px",
                              color: "teal",
                            }}
                          >
                            {salon.saloon_adress}
                          </Text>
                        </Box>
                      </Flex>

                      <Flex
                        justifyContent="space-between"
                        alignContent="center"
                        fontSize={"lg"}
                      >
                        {salon.saloon_rating ? (
                          <Rating
                            rating={salon.saloon_rating}
                            numReviews={salon.saloon_number_reviews}
                          />
                        ) : (
                          <Box className="newSalon">New Salon</Box>
                        )}
                        <Box>{salon.saloon_distance} km</Box>
                      </Flex>
                    </Box>
                  </Box>
                ))}
                <div style={{ minWidth: "100%", textAlign: "center" }}>
                  <Pagination
                    currentPage={search_page_number}
                    totalSize={total_pages}
                    sizePerPage={1}
                    changeCurrentPage={(page) => {
                      set_search_page_number(page);
                    }}
                    theme="bootstrap"
                  />
                </div>
              </>
            )}
          </Flex>
        </Box>
        {column2Visible && (
          <Box
            flex="1"
            height={"100%"}
            zIndex={1}
            className={showdropdown ? "mapSideBlur" : "mapSide"}
          >
            <MapContainer
              center={center_map}
              zoom={map_zoom}
              style={{
                height: "100%",
                width: "100%",
                zIndex: 1,
              }}
              key={center_map} // Use the refreshCount as the key
            >
              <ReactLeafletGoogleLayer
                apiKey="AIzaSyAYXObwfBn4s7Fmgwy96ztYPSypI9l-Hmg"
                type={"roadmap"}
                style={{ zIndex: 1 }}
              />
              {!data_loading && (
                <>
                  {salons.map((salon) => (
                    <>
                      <Marker
                        key={salon.saloon_id}
                        position={[
                          salon.saloon_latitude,
                          salon.saloon_longtitude,
                        ]}
                        eventHandlers={{
                          mouseover: () => handleMarkerHover(salon.saloon_id),
                          mouseout: handleMarkerMouseLeave,
                        }}
                        zIndexOffset={
                          hoveredMarker === salon.saloon_id ? 1000 : 0
                        } // Higher zIndex for hovered marker
                        icon={CustomIcon({
                          rating: salon.saloon_rating,
                          isHovered: hoveredMarker === salon.saloon_id,
                          showAnimation,
                        })}
                      >
                        <Popup>
                          <Box
                            bg={"white.800"}
                            // className="salon_card"
                            width={{
                              base: "90%",
                              sm: "500px",
                            }} // Use responsive width values
                            minH={{ base: "auto", sm: "150px", md: "350px" }} // Use responsive maxH values
                            rounded="lg"
                            shadow="lg"
                            position="relative"
                            as={"a"}
                            href={
                              salon.saloon_tag === "external"
                                ? `/e/${salon.saloon_slug}`
                                : `/d/${salon.saloon_slug}`
                            }
                            target="_blank"
                            style={{ textDecoration: "none" }}
                            overflow={"hidden"}
                          >
                            <Box position="relative" overflow={"hidden"}>
                              <Image
                                src={
                                  salon.saloon_tag === "direct"
                                    ? "https://api-v3.bookinghero.io" +
                                      salon.saloon_logo
                                    : salon.saloon_logo
                                }
                                alt={salon.saloon_name}
                                borderRadius={"md"}
                                objectFit={"cover"}
                                height={"180px"}
                                width={"100%"}
                              />
                            </Box>

                            <Box>
                              <Box display="flex" alignItems="baseline"></Box>
                              <Flex
                                mt="1"
                                justifyContent="space-between"
                                alignContent="center"
                              >
                                <Box
                                  fontSize="lg"
                                  fontWeight="semibold"
                                  as="h5"
                                >
                                  <Text
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      width: "280px",
                                      margin: "0",
                                    }}
                                  >
                                    {salon.saloon_name}
                                  </Text>
                                </Box>
                              </Flex>

                              <Flex
                                justifyContent="space-between"
                                alignContent="center"
                              >
                                <Box
                                  fontSize="sm"
                                  fontWeight="semibold"
                                  as="h5"
                                  mb={1}
                                  paddingTop={0}
                                >
                                  <Text
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      width: "300px",
                                      color: "teal",
                                      margin: "0",
                                    }}
                                  >
                                    {salon.saloon_adress}
                                  </Text>
                                </Box>
                              </Flex>

                              <Flex
                                // width={"100%"}
                                // position={"sticky"}
                                justifyContent="space-between"
                                alignContent="center"
                                fontSize={"md"}
                              >
                                {salon.saloon_rating ? (
                                  <Rating
                                    rating={salon.saloon_rating}
                                    numReviews={salon.saloon_number_reviews}
                                  />
                                ) : (
                                  <Box className="newSalon">New Salon</Box>
                                )}
                                <Box>{salon.saloon_distance} km</Box>
                              </Flex>
                            </Box>
                          </Box>
                        </Popup>
                      </Marker>
                    </>
                  ))}
                </>
              )}
            </MapContainer>
          </Box>
        )}
      </Flex>
    </div>
  );
}

export default MarketplaceSearchPage;
